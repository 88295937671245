import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { Dispatch, useCallback, useEffect, useState } from 'react';
import { GenericApi } from '../../api/generic-api';
import { Usuario } from '../../models/usuario';
import { moduleName } from '../../views/edital/convite-participantes/convite-participantes.headcell';
import { Secure } from '../auth/secure';
import {
  CustomAutoComplete,
  CustomListAvatar,
  CustomListImg,
  CustomListItem,
  CustomSelectAvatar,
  CustomSelectImg,
  CustomStack,
} from './style/custom-multiple-select-user-chip';

type CustomMultipleSelectUserChipProps = {
  apiSearchInstance: GenericApi<Usuario>;
  apiListData: any[];
  onTextChange?: (searchText: string) => void;
  handleAdd: (e: any) => void;
  usuariosState: {
    usuarios: Usuario[];
    setUsuarios: Dispatch<React.SetStateAction<Usuario[]>>;
  };
  selectedUsuariosState: {
    selectedUsuarios: Usuario[];
    setSelectedUsuarios: Dispatch<React.SetStateAction<Usuario[]>>;
  };
  enabled?: boolean;
  hasSecure?: boolean;
};

const CustomMultipleSelectUserChip = (props: CustomMultipleSelectUserChipProps) => {
  const { usuarios, setUsuarios } = props.usuariosState;
  const { selectedUsuarios, setSelectedUsuarios } = props.selectedUsuariosState;
  const [searchText, setSearchText] = useState('');
  const handleNameAvatar = (name: string | undefined) => {
    if (!name) return '';

    return name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase())
      .slice(0, 2)
      .join('');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataFromApi = await props.apiSearchInstance.getAll({ nome: searchText, take: 10 });

        const dataFromApiFilteredByListData = dataFromApi.filter(
          (x: Usuario) =>
            !props.apiListData.some(
              (usuario) => usuario.usuarioId === x.id || usuario.usuarioPersistidoId === x.id,
            ),
        );

        setUsuarios(
          dataFromApiFilteredByListData.filter(
            (x: Usuario) => !selectedUsuarios.some((usuario) => usuario.id === x.id),
          ),
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [searchText, props.apiListData]);

  const handleChange = useCallback(
    debounce((event) => {
      setSearchText(event.target.value);
      props.onTextChange?.(event.target.value);
    }, 300),
    [],
  );

  return (
    <CustomStack direction='row' spacing={2}>
      <CustomAutoComplete
        multiple
        id='tags-outlined'
        limitTags={1}
        defaultValue={[]}
        options={usuarios}
        disabled={!props.enabled}
        filterSelectedOptions
        getOptionLabel={(option) => (option as Usuario)?.nome}
        value={selectedUsuarios}
        onChange={(e, value) => {
          setSelectedUsuarios(value as Usuario[]);
        }}
        renderOption={(props, option) => {
          const userOption: Usuario = option as Usuario;
          return (
            <CustomListItem {...props}>
              {userOption.fotoPerfil ? (
                <CustomListImg src={userOption.fotoPerfil} />
              ) : (
                <CustomListAvatar>{handleNameAvatar(userOption.nome)}</CustomListAvatar>
              )}
              {userOption.nome}
            </CustomListItem>
          );
        }}
        renderTags={(value, getTagProps) =>
          (value as Usuario[]).map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                variant='outlined'
                label={option?.nome}
                key={key}
                {...tagProps}
                avatar={
                  option.fotoPerfil ? (
                    <CustomSelectImg src={option?.fotoPerfil} />
                  ) : (
                    <CustomSelectAvatar>{handleNameAvatar(option.nome)}</CustomSelectAvatar>
                  )
                }
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            size='small'
            label='Nome do Pesquisador'
            placeholder='Digite o nome do pesquisador'
            onChange={(e) => {
              handleChange(e);
            }}
          />
        )}
      />
      {props.hasSecure ? (
        <Secure hasPermission={'CRIAR'} moduleName={moduleName}>
          <Tooltip title='Adicionar' arrow>
            <Button
              className={isEmpty(selectedUsuarios) ? 'btn-disabled' : 'btn-add'}
              disabled={isEmpty(selectedUsuarios) || !props.enabled}
              onClick={(e) => {
                props.handleAdd(e);
              }}
            >
              <AddIcon></AddIcon>
              {'Adicionar'}
            </Button>
          </Tooltip>
        </Secure>
      ) : (
        <Tooltip title='Adicionar' arrow>
          <Button
            className={isEmpty(selectedUsuarios) ? 'btn-disabled' : 'btn-add'}
            disabled={isEmpty(selectedUsuarios) || !props.enabled}
            onClick={(e) => {
              props.handleAdd(e);
            }}
          >
            <AddIcon></AddIcon>
            {'Adicionar'}
          </Button>
        </Tooltip>
      )}
    </CustomStack>
  );
};

export default CustomMultipleSelectUserChip;
