import { ConstNode } from './const-node';
import { IMathNode } from './i-math-node';
import { makeOperator } from './operator-node';
import { VariableNode } from './variable-node';

const lexical =
  /(?<num>\d+)|(?<var>[a-zA-Z][\w\.]*)|(?<op>[\+\-\*\/])|(?<open>\()|(?<close>\))|(?<trash>[\W_])/gis;

type states = 'num' | 'var' | 'op' | 'open' | 'close';

const parseGroup = (current: IteratorResult<RegExpMatchArray, undefined>) =>
  (Object.entries(current.value.groups) as [states, string][]).find(([, value]) => Boolean(value));

const parseOperand = (parser: IterableIterator<RegExpMatchArray>, allowSign = true): IMathNode => {
  const current = parser.next();
  if (current.done) throw new Error('Unexpected end of expression, operand expected');
  const [group, value] = parseGroup(current);

  switch (group) {
    case 'num':
      return new ConstNode(Number(value));
    case 'var':
      return new VariableNode(value);
    case 'open':
      return parseTree(parser, false);
    case 'op': {
      if (value !== '-' || !allowSign)
        throw new Error(`Unexpected operator ${value}, expected + or -`);
      return makeOperator('*', new ConstNode(-1), parseOperand(parser, false));
    }

    default:
      throw new Error(`Unexpected character ${value}`);
  }
};

const operatorByPriority = (
  parser: IterableIterator<RegExpMatchArray>,
  leftChild: IMathNode,
  operation: string,
  closed = true,
): IMathNode => {
  switch (operation) {
    case '+':
      return makeOperator(operation, leftChild, parseTree(parser, closed));
    case '-':
      const newOperator = makeOperator('*', new ConstNode(-1), parseOperand(parser));
      return makeOperator('+', leftChild, newOperator);
    case '*':
    case '/':
      return parseOperator(
        parser,
        makeOperator(operation, leftChild, parseOperand(parser)),
        closed,
      );
    default:
      throw new Error(`Unexpected character ${operation}`);
  }
};

const parseOperator = (
  parser: IterableIterator<RegExpMatchArray>,
  leftChild: IMathNode,
  closed = true,
): IMathNode => {
  const current = parser.next();
  if (current.done) {
    if (closed) return leftChild;
    throw new Error('Unexpected end of expression, expected )');
  }
  const [group, value] = (Object.entries(current.value.groups) as [states, string][]).find(
    ([, value]) => Boolean(value),
  );
  switch (group) {
    case 'op':
      return operatorByPriority(parser, leftChild, value, closed);
    case 'num':
    case 'var':
    case 'open':
      throw new Error(`Unexpected value ${value}, expected operator(+, -, *, /)`);
    case 'close':
      return leftChild;
    default:
      throw new Error(`Unexpected character ${value}`);
  }
};

const parseTree = (parser: IterableIterator<RegExpMatchArray>, closed = true): IMathNode => {
  const current = parser.next();
  const [group, value] = parseGroup(current);
  let node: IMathNode;
  switch (group) {
    case 'op':
      if (value !== '-') throw new Error(`Unexpected operator ${value}, expected + or -`);
      const nextValue = parseOperand(parser);
      node = parseOperator(parser, makeOperator(value, new ConstNode(0), nextValue), closed);
      break;
    case 'num':
      node = parseOperator(parser, new ConstNode(Number(value)), closed);
      break;
    case 'var':
      node = parseOperator(parser, new VariableNode(value), closed);
      break;
    case 'open':
      node = parseOperator(parser, parseTree(parser, false), closed);
      break;
    default:
      throw new Error(`Unexpected character ${value}`);
  }
  return node;
};

export const parseMathTree = (expression: string): IMathNode => {
  const parser = expression.matchAll(lexical);
  return parseTree(parser);
};
