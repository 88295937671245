import { IMathNode } from './i-math-node';
import { IVars } from './i-vars';

export class VariableNode implements IMathNode {
  readonly name: string;
  constructor(name: string) {
    this.name = name;
  }
  solve(vars: IVars): number {
    if (this.name in vars) return vars[this.name];
    throw new Error(`Variable ${this.name} not found`);
  }
  listVars(): string[] {
    return [this.name];
  }
}
