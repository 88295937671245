import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { Dispatch } from 'react';
import { PropostaConviteMembro } from '../../models/proposta';
import {
  CustomAvatar,
  CustomListImageBox,
  CustomListImg,
  CustomSelectAvatar,
  CustomSelectImg,
  CustomStack,
} from './style/custom-multiple-select-membro-chip';

type CustomMultipleSelectMembroChipProps = {
  propostaConviteMembro: PropostaConviteMembro[];
  selectedPropostaConviteMembroState: {
    selectedPropostaConviteMembro: PropostaConviteMembro[];
    setSelectedPropostaConviteMembro: Dispatch<React.SetStateAction<PropostaConviteMembro[]>>;
  };
  handleAdd: (value: PropostaConviteMembro[]) => void;
  enabled?: boolean;
  hideAddButton?: boolean;
};

const handleNameAvatar = (name: string | undefined) => {
  if (!name) return '';
  return name
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .slice(0, 2)
    .join('');
};

const CustomMultipleSelectMembroChip = (props: CustomMultipleSelectMembroChipProps) => {
  const { selectedPropostaConviteMembro, setSelectedPropostaConviteMembro } =
    props.selectedPropostaConviteMembroState;
  return (
    <CustomStack>
      <Autocomplete
        multiple
        id='tags-outlined'
        limitTags={2}
        defaultValue={[]}
        options={props.propostaConviteMembro}
        disabled={!props.enabled}
        filterSelectedOptions
        getOptionLabel={(option) => option?.usuarioPersistido?.nome}
        value={selectedPropostaConviteMembro}
        onChange={(e, value) => {
          props.handleAdd(value);
          setSelectedPropostaConviteMembro(value);
        }}
        renderOption={(props, option) => {
          return (
            <CustomListImageBox {...props}>
              {option.usuarioPersistido?.fotoPerfil ? (
                <CustomListImg src={option.usuarioPersistido?.fotoPerfil} />
              ) : (
                <CustomAvatar>{handleNameAvatar(option.usuarioPersistido?.nome)}</CustomAvatar>
              )}
              {option.usuarioPersistido?.nome}
            </CustomListImageBox>
          );
        }}
        renderTags={(value: PropostaConviteMembro[], getTagProps) =>
          value.map((option: PropostaConviteMembro, index: number) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                label={option?.usuarioPersistido.nome}
                key={key}
                {...tagProps}
                avatar={
                  option?.usuarioPersistido.fotoPerfil ? (
                    <CustomSelectImg src={option?.usuarioPersistido.fotoPerfil} />
                  ) : (
                    <CustomSelectAvatar>
                      {handleNameAvatar(option?.usuarioPersistido.nome)}
                    </CustomSelectAvatar>
                  )
                }
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            size='small'
            label='Membro(s) da atividade'
            placeholder='Digite o nome do Membro'
          />
        )}
      />
    </CustomStack>
  ) as JSX.Element;
};

export default CustomMultipleSelectMembroChip;
