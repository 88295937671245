import styled from '@emotion/styled';
import Box from '@mui/material/Box';

export const StyledContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
  marginBottom: '85px',
  width: '100%',
});

export const StyledItem = styled('div')<{
  isFullWidth?: boolean;
}>(({ isFullWidth }) => ({
  textAlign: 'center',
  flex: isFullWidth ? '1 1 100%' : '1 1 calc(50% - 8px)',
  boxSizing: 'border-box',
}));

export const BoxCustom100pxHeight = styled(Box)({
  height: '100px',
});
