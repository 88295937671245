import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import isEmpty from 'lodash/isEmpty';
import React, { Dispatch, Fragment, useState } from 'react';
import { PropostaConviteConsultorApi } from '../../../api/proposta-convite-consultor-api';
import { PropostaConviteConsultor } from '../../../models/proposta';
import Loading from '../../crud/loading';
import {
  AcaoLabel,
  AcceptListItemButton,
  AceitarLabel,
  BackgroundBoxActionButtons,
  BackgroundBoxButtons,
  BackgroundItem,
  BackgroundList,
  BackgroundListItem,
  ComponentBox,
  ConviteList,
  CoordenadorBox,
  CoordenadorListItem,
  InformacoesLabel,
  RecusarLabel,
  RefuseListItemButton,
  StyledDocumentFolder,
  TituloInformacoesLabel,
  TituloLabel,
} from './style/proposta-convite-membro';
import * as dayjs from 'dayjs';

type propostaConviteConsultorProps<T> = {
  apiResultData: PropostaConviteConsultor[];
  propostaConviteConsultorApi: PropostaConviteConsultorApi;
  loadState: {
    load: boolean;
    setLoad: Dispatch<React.SetStateAction<boolean>>;
  };
  loadConvites(): any;
  splitLength: number;
  onConviteAceito?: () => void;
};

export default function PropostaConviteConsultorComponente<T extends Record<string, any>>({
  apiResultData,
  propostaConviteConsultorApi,
  loadState,
  loadConvites,
  splitLength,
  onConviteAceito,
}: propostaConviteConsultorProps<T>) {
  const { load, setLoad } = loadState;
  const [confirmacao, setConfirmacao] = useState<{
    id: number | null;
    acao: 'aceitar' | 'recusar' | null;
  }>({ id: null, acao: null });

  const handleAceitar = (conviteId: any) => {
    propostaConviteConsultorApi
      .aceitar(conviteId, { dataAceite: Date.now() })
      .then(() => {
        setLoad(true);
        loadConvites();
        onConviteAceito && onConviteAceito();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleRecusar = (conviteId: any) => {
    propostaConviteConsultorApi
      .recusar(conviteId)
      .then(() => {
        setLoad(true);
        loadConvites();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleConfirmacao = (conviteId: any, acao: 'aceitar' | 'recusar') => {
    setConfirmacao({ id: conviteId, acao });
  };

  const handleCancelar = () => {
    setConfirmacao({ id: null, acao: null });
  };

  const handleConfirmarAcao = (conviteId: any) => {
    if (confirmacao.acao === 'aceitar') {
      handleAceitar(conviteId);
    } else if (confirmacao.acao === 'recusar') {
      handleRecusar(conviteId);
    }
    handleCancelar();
  };

  const formatData = (data: Date | string) => {
    return dayjs.utc(data).format('DD/MM/YYYY');
  };

  if (isEmpty(apiResultData)) {
    return null;
  }

  return (
    <ComponentBox>
      <BackgroundList>
        {load && <Loading isLoading={load} />}
        {apiResultData.map((item, index) => (
          <Fragment key={index}>
            <BackgroundItem>
              {confirmacao.id === item.id ? (
                <BackgroundBoxActionButtons>
                  <BackgroundBoxButtons>
                    {confirmacao.acao === 'aceitar' ? (
                      <ConviteList>
                        <AcaoLabel>Deseja realmente</AcaoLabel> <AceitarLabel>aceitar</AceitarLabel>{' '}
                        <AcaoLabel>o convite? A ação não poderá ser desfeita.</AcaoLabel>
                      </ConviteList>
                    ) : (
                      <ConviteList>
                        <AcaoLabel>Deseja realmente</AcaoLabel> <RecusarLabel>recusar</RecusarLabel>{' '}
                        <AcaoLabel>o convite? A ação não poderá ser desfeita.</AcaoLabel>
                      </ConviteList>
                    )}
                  </BackgroundBoxButtons>
                  <BackgroundBoxButtons>
                    <AcceptListItemButton onClick={() => handleConfirmarAcao(item.id)}>
                      <Box>Confirmar</Box>
                    </AcceptListItemButton>
                    <RefuseListItemButton onClick={handleCancelar}>
                      <Box>Cancelar</Box>
                    </RefuseListItemButton>
                  </BackgroundBoxButtons>
                </BackgroundBoxActionButtons>
              ) : (
                <BackgroundList>
                  <BackgroundListItem>
                    <TituloLabel>
                      {splitLength % 2 === 0 && item.proposta.tituloDoProjeto.length > 80
                        ? `${item.proposta.tituloDoProjeto.slice(0, 80)}...`
                        : item.proposta.tituloDoProjeto}
                    </TituloLabel>
                  </BackgroundListItem>
                  <BackgroundListItem>
                    <StyledDocumentFolder />
                    <TituloInformacoesLabel>Edital:</TituloInformacoesLabel>
                    <InformacoesLabel>
                      {splitLength % 2 === 0 && item.proposta.edital.nome.length > 80
                        ? `${item.proposta.edital.nome.slice(0, 80)}...`
                        : item.proposta.edital.nome}
                    </InformacoesLabel>
                  </BackgroundListItem>
                  <BackgroundListItem>
                    <StyledDocumentFolder />
                    <TituloInformacoesLabel>Prazo de aceite:</TituloInformacoesLabel>
                    <InformacoesLabel>
                      {formatData(item?.formularioConsultoria?.dataExpiracaoConvite)}
                    </InformacoesLabel>
                  </BackgroundListItem>
                  <BackgroundListItem>
                    <StyledDocumentFolder />
                    <TituloInformacoesLabel>Término:</TituloInformacoesLabel>
                    <InformacoesLabel>
                      {formatData(item?.formularioConsultoria?.dataTermino)}
                    </InformacoesLabel>
                  </BackgroundListItem>
                  <CoordenadorListItem>
                    <CoordenadorBox></CoordenadorBox>
                    <BackgroundBoxButtons>
                      <AcceptListItemButton onClick={() => handleConfirmacao(item.id, 'aceitar')}>
                        <Box>Aceitar</Box>
                      </AcceptListItemButton>
                      <RefuseListItemButton onClick={() => handleConfirmacao(item.id, 'recusar')}>
                        <Box>Recusar</Box>
                      </RefuseListItemButton>
                    </BackgroundBoxButtons>
                  </CoordenadorListItem>
                </BackgroundList>
              )}
            </BackgroundItem>
            {index < apiResultData.length - 1 && <Divider />}
          </Fragment>
        ))}
      </BackgroundList>
    </ComponentBox>
  );
}
