import { useEffect, useState } from 'react';
import { Crud } from '../../../components/crud/crud';
import { AvaliacaoConsultor } from '../../../models/avaliacao-consultor';
import AvaliacaoConsultorUiSchema from '../../avaliacao/avaliacao-consultor/avaliacao-consultor.uischema';
import {
  makeResultadoConsultoria,
  ResultadoConsultoriaApi,
} from './resultado-consultoria-view-api';
import { headCells } from './resultado-consultoria.headcell';
import { useCrudContext } from '../../../components/crud/crud-context';
import { useLocation } from 'react-router-dom';

function AvaliacaoConsultorView() {
  const location = useLocation();

  const { parameterFilters, updateCrudStates } = useCrudContext();
  const conviteId = Number(parameterFilters.propostaConviteConsultorId);

  const [api, setApi] = useState<ResultadoConsultoriaApi>(() =>
    makeResultadoConsultoria(conviteId),
  );

  useEffect(() => {
    if (location?.state?.conviteId) setApi(makeResultadoConsultoria(location?.state?.conviteId));
    updateCrudStates(location.state?.crudState);
  }, [location?.state?.conviteId]);

  return (
    <Crud<AvaliacaoConsultor>
      headCells={headCells}
      titleConfig={{ value: 'Resultado Consultoria', show: true }}
      apiUrl='/resultado-consultoria-view'
      apiClient={api}
      uischema={AvaliacaoConsultorUiSchema}
      hideDelete
      hideUpdate
      hideView
      hideCreate
    />
  );
}

export const rootViews = ['proposta'];

export default AvaliacaoConsultorView;
