import { JsonSchema, Layout, Rule, ValidationMode } from '@jsonforms/core';
import type { ErrorObject } from 'ajv';
import { NavigateFunction } from 'react-router-dom';
import { FormApi } from '../../api/generic-api';
import { CkEditorSize } from '../../components/layout/ckeditor';
import { AuthContextData } from '../../views/login/auth/auth-context';
import { Dispatch, SetStateAction } from 'react';
import { Severity } from '../../components/crud/protocols/severity';
import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';

export interface IControlElement {
  type: string;
  scope?: string;
  label?: string;
  options?: IOptions;
  elements?: IControlElement[];
  rule?: Rule;
}

export interface IFieldsConfig {
  paths: string[];
  instancePaths: string[];
  requiredScopes: string[];
}

export interface IOptions {
  /* Controla a obrigatoriedade do campo */
  required?: RequiredType;

  /* Máscaras do campo seguindo a bib @use-mask-input */
  mask?: string;

  /* Valor da Constante*/
  value?: any;

  /* Tamanho mínimo do campo */
  minLength?: number;

  /* Tamanho máximo do campo */
  maxLength?: number;

  /* Opção para customizar o select do number-render */
  numberStep?: number;

  /* Campo desabilitado */
  disabled?: boolean;

  /* Campo editável apenas na criação */
  onlyCreate?: boolean;

  /* Atributos a serem mostrados na label do item */
  displayProperties?: string[];

  addEmptyOption?: boolean;

  /* Código a ser executado sempre que o campo for alterado */
  handleChange?: IHandleChange;

  /* Ícone para lista, usado nos items do select */
  iconBuilder?: (option: any) => JSX.Element;

  /* Gerencia o step atual e faz verificações para permitir prosseguir ou não */
  stepHandler?: IStepHandler;

  /* Intância da API para ser usada pelo Select Filter */
  apiInstance?: FormApi;

  /* Rota alternativa para ser usada nos renderers */
  route?: string;

  /* Paths alternativos para ser usados nos renderers */
  customPaths?: {
    [key: string]: string;
  };

  /* Seleção múltipla no select */
  selectMultiple?: boolean;

  /* Filtro de items do select */
  filter?: ISelectFilter<any>;

  /* Campos a serem observados para atualização de valores */
  formFields?: (formData: any, path: string) => any[];

  /* Opção de custom itens para o select  */
  oneOf?: (
    formData: any,
    path: string,
  ) => {
    title: string;
    const: string | number;
  }[];

  /* Opção de dados custom para visualização em tabela */
  tableOf?: (formData: any) => {
    headers: HeaderModel[];
    columns: RowsModel[];
    subtitle?: SubtitleModel[];
  };

  /* Opção de dados custom para visualização em tabela multipla */
  tablesOf?: (formData: any) => {
    headers: HeaderModel[];
    columns: RowsModel[];
    title: string;
    subtitle?: SubtitleModel[];
  }[];

  /* Nested UiSchema para Layout de Array */
  detail?: IUiSchema;

  /* Format para o campo */ // TODO: tirar format e usar o type: 'date-time' no uischema
  format?: string;

  /* Formato de datas */
  dateFormat?: string;

  /* dateTimeFormat para o campo */
  dateTimeFormat?: string;

  /* dateTimeFormat para salvar no form */
  dateTimeSaveFormat?: string;

  /* Default para o date-render, 'now' ou a data desejada em string */
  dateDefault?: string;

  /* Config de AM PM no DateTime */
  ampm?: boolean;

  /* Função para comparação dos options dos renderers (Usado no loadSavedItem) */
  optionsComparator?: (option: any, item: any) => boolean;

  /* Indica qual campo das opções do select será atribuído*/
  targetFieldValue?: string;

  arrayProps?: {
    /* Identificador unico do objeto */
    id: string;
    /* Insere o valor dentro do index indicado */
    arrayIndex: number | ((data: any, index: number, array: any[]) => boolean);

    /* Insere o valor dentro do scope indicado */
    arrayScope: string;
  };

  /* Função para formatar o label do option */
  formatOptionLabel?: (option: any) => string;

  /* Configuração de props */
  componentProps?: any;

  /* Função para gerenciar visibilidade do campo */
  visibleHandler?: IVisibleHandler;

  /* Função para gerenciar ativação do campo */
  disableHandler?: IDisabledHandler;

  /* Tipo de Layout usado no Array Layout Render */
  itemLayout?: 'SimpleItemLayout' | 'ExpandableItemLayout';

  /* Variante de Layout para ser usado */
  variant?: string;

  /*Define campos adicionais para enviar na request do Table Layout*/
  additionalData?: (formData: unknown) => { [key in keyof typeof formData]: any };

  /* Configuração de cabeçalho do Table Layout */
  buildHeader?: {
    builder: (data: TableLayoutHeaderData[], field?: string) => JSX.Element;
    field?: string;
  };

  /* Configuração de colunas do Table Layout */
  headCells?: TableLayoutHeadCell[];

  /* Mostrar botões de navegação no Layout? */
  showNavButtons?: boolean;

  /* Mostrar botão de edicão no stepper? */
  showEditButton?: boolean;

  /* Configuração de ícones do Stepper */
  icons?: IconsConfig;

  /* Configuração de tamanho do campo do ckEditor */
  ckeditorSize?: CkEditorSize;

  /* Aceita apenas números positivos */
  isPositiveNumber?: boolean;

  /* Configuração da label do array*/
  elementLabelProp?: string;

  hideRequiredAsterisk?: boolean;

  /* Configuração de label do campo */
  label?: LabelType;

  externalLabel?: string | JSX.Element;

  defaultValue?: ((data: any) => any) | any;

  beforeCreate?: (data: any, path: string) => boolean;

  /* Erros customizados pelo uischema */
  customErrors?: ((
    field: string,
    message?: string,
  ) => {
    field: string;
    hasError: (value: any) => boolean;
    error: {
      keyword: string;
      message: string;
      propertyName: string;
      instancePath: string;
      dataPath?: string;
      params?: Object;
    };
  })[];

  additionalPaths?: string[];

  /* Configuração da barra de ferramentas do CkEditor */
  toolbar?: string[];

  /* Titulo do botão de avançar em um stepper */
  nextButtonTitle?: string;

  withoutFk?: boolean;

  categories?: string[];

  categoriesRoute?: string;

  fileApiOptions?: (formData: any) => { [key: string]: any };

  filterByCategories?: boolean;

  /* Desabilita o botão de avançar em um stepper para alguma regra específica */
  nextButtonDisabled?: (data: any) => boolean;

  /* Configuração de botão de finalização de um stepper */
  onFinish?: (
    navigate: NavigateFunction,
    ctx: CrudContextData,
    authContext?: AuthContextData,
  ) => void;

  /* Configuração da propriedade focus de um component */
  focus?: boolean;

  /* Exibição dos botões de ordenação */
  showSortButtons?: boolean;

  /* Exibição do barra de progresso */
  showProgress?: boolean;

  /* Definição do numero de casas decimais */
  decimals?: number;
}

export interface IUiSchema extends Layout {
  elements: IControlElement[];
  options?: IOptions;
}

export type TableLayoutHeader = (data: unknown) => JSX.Element;

export type TableLayoutHeadCell = {
  label: string;
  field: string | ((row: unknown) => string);
};

export type TableLayoutHeaderData = {
  moedaEstrangeira: { nome: string; simbolo: string };
  [key: string]: any;
};

export type RequiredType = boolean | ((ctx: CrudContextData) => boolean);

export type LabelType = string | ((ctx: CrudContextData) => string);

export type IStepCallback = (
  ctx: CrudContextData,
  fieldsConfig?: IFieldsConfig,
) => boolean | Promise<boolean> | void;

export interface IStepHandler {
  /* Realiza as verificações do step atual */
  handler: any;
  /* url para salvar o step atual, caso necessário */
  url?: string;
  /* Ações específicas a serem executadas após um step ser concluído */
  callback?: IStepCallback;
}

export type IHandleChange = (
  item: any,
  handler: (path: string, value: any) => void,
  formData: any,
  ctx?: CrudContextData,
  path?: string,
) => void;

export interface IFieldsConfig {
  paths: string[];
  instancePaths: string[];
  requiredScopes: string[];
}

export interface ISelectFilter<T extends FormApi> {
  /* Campos a serem observados dentro do form para realizar o filtro */
  formFields?: string[];

  /* Aceita valores não encontrados no select*/
  acceptUnmatched?: boolean;

  /* Previni a limpeza do campo */
  preventCleaning?: boolean;

  /* Chamar o handler mesmo sem API */
  selfContainedApi?: boolean;

  /* Função que retorna os dados filtrados */
  handler: (
    formData: any,
    listData: any[],
    api: T,
    queryFilterValues: any,
    path: string,
  ) => Promise<any>;
}

export type IconsConfig = (
  | {
      category: string;
      icon: any;
    }
  | {
      category: string;
      icon: () => JSX.Element;
    }
)[];

export type IVisibleHandler = (
  ctx: CrudContextData,
  apiListData: any[],
  queryFilterValues: any[],
) => boolean;

export type IDisabledHandler = (ctx: CrudContextData, apiListData: any[]) => boolean;

/* Função que constrói o custom error */
export type CustomErrorHandler = (field: string, message?: string) => CustomError;

export type CustomError = {
  field: string;
  hasError: (value: any, formData: any) => boolean;
  error: ErrorObject;
};

export interface CrudContextData {
  crudStates: CrudStates;
  setCrudStates: Dispatch<SetStateAction<CrudStates>>;
  updateCrudStates: (option: CrudStatesOptions) => void;
  errorsJsonForms: any[];
  setErrorsJsonForm: (errors: any[]) => void;
  errorsCustom: any[];
  setErrorsCustom: (errors: any[]) => void;
  additionalErrors: any[];
  setAdditionalErrors: (additionalErrors: any[]) => void;
  validationMode: ValidationMode;
  setValidationMode: (value: ValidationMode) => void;
  formData: any;
  setFormData: (value: any) => void;
  apiListData: any;
  setApiListData: (value: any[]) => void;
  schema: JsonSchema;
  setSchema: Dispatch<SetStateAction<JsonSchema>>;
  currentUiSchema: IUiSchema;
  setCurrentUiSchema: (value: IUiSchema) => void;
  clearForm: () => void;
  severityAlert: Severity;
  messageAlert: string;
  openAlert: boolean;
  setOpenAlert: Dispatch<SetStateAction<boolean>>;
  showSuccess: (message: string) => void;
  showError: (message: string) => void;
  load: boolean;
  setLoad: Dispatch<SetStateAction<boolean>>;
  parameterFilters: any;
  setParameterFilters: Dispatch<any>;
  disabledFields: string[];
  setDisabledFields: Dispatch<SetStateAction<string[]>>;
  currentTitle: string;
  setCurrentTitle: (value: string) => void;
  currentApiUrl: string;
  setCurrentApiUrl: (value: string) => void;
  id: number;
  setId: (id: number) => void;
  isRequiredField: (required: RequiredType, ctx: CrudContextData) => boolean;
}

export interface CrudStates {
  add?: boolean;
  view?: boolean;
  edit?: boolean;
  list?: boolean;
}

export enum CrudStatesOptions {
  ADD,
  VIEW,
  EDIT,
  LIST,
}

export type HeaderModel = {
  value: string;
  texto: string;
  colspan: number;
  customSx?: SxProps<Theme>;
};

export type SubtitleModel = {
  texto: string;
  colspan: number;
  customSx?: SxProps<Theme>;
};

export type CellModel = {
  header: string;
  textValue: string;
  customSx?: SxProps<Theme>;
};

export type RowsModel = {
  value: CellModel[];
  customSx?: SxProps<Theme>;
};
