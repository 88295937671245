import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ListaPropostas from '../../../components/proposta/lista-propostas';
import { Proposta } from '../../../models/proposta';
import {
  ComponentBoxProposta,
  FooterBox,
  FooterButton,
  Header,
  PropostaHeaderLabel,
  PropostaIcon,
  TodasPropostasAnchor,
} from './style/proposta-home';

type PropostaHomeProps = {
  apiResponse: {
    data: Proposta[];
    count: number;
  };
  splitLength?: number;
};

export const PropostaHome = ({ apiResponse, splitLength }: PropostaHomeProps) => {
  const [data, setData] = useState<Proposta[]>([]);
  const [count, setCount] = useState<number>(0);

  const navigate = useNavigate();

  useEffect(() => {
    const data = Array.isArray(apiResponse) ? apiResponse : apiResponse.data;
    const count = Array.isArray(apiResponse) ? apiResponse.length : apiResponse.count;
    setData(data);
    setCount(count);
  }, [apiResponse]);

  if (data.length === 0) {
    return null;
  }

  return (
    <ComponentBoxProposta>
      <Header>
        <PropostaIcon />
        <PropostaHeaderLabel>Propostas</PropostaHeaderLabel>
      </Header>
      <Divider />
      <ListaPropostas splitLength={splitLength} propostas={data} />
      <FooterBox>
        <FooterButton>
          <TodasPropostasAnchor onClick={() => navigate('/todas-propostas')}>
            {`Ver Mais (${count})`}
          </TodasPropostasAnchor>
        </FooterButton>
      </FooterBox>
    </ComponentBoxProposta>
  );
};
