import { pascalCase } from 'change-case';

export const onlyNumbers = (field: string, message?: string) => ({
  field: field,
  hasError: (value: any) => !String(value).match(/^[0-9]*$/),
  error: {
    keyword: 'onlyNumbers',
    message: message ?? 'Informe apenas números positivos',
    propertyName: field,
    instancePath: `/${field?.replace?.('.', '/')}`,
    dataPath: `/${field}`,
    params: {},
  },
});

export const customRequired = (field: string, message?: string) => ({
  field: field,
  hasError: (value: any) => !value,
  error: {
    keyword: 'customRequired',
    message: 'Campo obrigatório',
    propertyName: field,
    instancePath: `/${field?.replace?.('.', '/') || field}`,
    schemaPath: '#/required',
    params: {},
  },
});

export const customLength = (field: string, length: number, message?: string) => ({
  field: field,
  hasError: (value: any) => String(value).length !== length,
  error: {
    keyword: 'customLength',
    message: message ?? `O tamanho do campo deve ser de ${length} caracteres.`,
    propertyName: field,
    instancePath: `/${field?.replace?.('.', '/') || field}`,
    schemaPath: '#/minLength',
    params: { length },
  },
});

export const currency = (field: string, message?: string) => ({
  field: field,
  hasError: (value: any) => !String(value).match(/^[0-9]+(.\d{2})?$/),
  error: {
    keyword: 'currency',
    message: message ?? 'Informe um valor com duas casas decimais ex.: 10,00',
    propertyName: field,
    instancePath: `/${field?.replace?.('.', '/')}`,
    dataPath: `/${field}`,
    params: {},
  },
});

export const positiveNumber = (field: string, message?: string) => ({
  field: field,
  hasError: (value: any) => Number.isNaN(Number(value)) || Number(value) <= 0,
  error: {
    keyword: 'positiveNumber',
    message: message ?? 'O campo deve ser um número positivo.',
    propertyName: field,
    instancePath: `/${field?.replace?.('.', '/')}`,
    dataPath: `/${field}`,
    params: {},
  },
});

export const positiveNumberOrEmpty = (field: string, message?: string) => ({
  field: field,
  hasError: (value: any) => value && (Number.isNaN(Number(value)) || Number(value) <= 0),
  error: {
    keyword: 'positiveNumberOrEmpty',
    message: message ?? 'O campo deve ser vazio ou um número positivo.',
    propertyName: field,
    instancePath: `/${field?.replace?.('.', '/')}`,
    dataPath: `/${field}`,
    params: {},
  },
});

export const whiteSpaces = (field: string, message?: string) => ({
  field: field,
  hasError: (value: any) =>
    String(value).length > 0 && String(value).replace(/\s/g, '').length === 0,
  error: {
    keyword: 'whiteSpaces',
    message: message ?? 'Campo obrigatório',
    propertyName: field,
    instancePath: `/${field?.replace?.('.', '/')}`,
    dataPath: `/${field}`,
    params: {},
  },
});

export const customError = (field: string, messages: string[], data: any[]) => {
  const instancePath = `/${field?.replace?.('.', '/') || field}`;
  const fieldFormatted = field.replace(/\//g, '.');

  const currentValueData = fieldFormatted.split('.').reduce((acc, key) => acc?.[key], data);

  return {
    field: fieldFormatted,
    hasError: (value: any) => value === currentValueData,
    error: {
      keyword: `customError${pascalCase(field)}`,
      message: messages.join(', '),
      propertyName: fieldFormatted,
      instancePath: instancePath,
      schemaPath: '#/required',
      params: {},
    },
  };
};
