import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { Survey } from '../../../components/styled-icons';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { Proposta } from '../../../models/proposta';
import propostaUiSchema from '../../proposta/proposta.uischema';
import {
  AvaliacaoEnquadramentoApi,
  makeAvaliacaoEnquadramento,
} from './avaliacao-de-enquadramento-api';
import { headCells } from './avaliacao-de-enquadramento.headcell';

function AvaliacaoDeEnquadramento() {
  const { setParameterFilters, parameterFilters } = useCrudContext();

  const { formularioEnquadramentoId, gerenciarFormulariosId } = useParams();
  const formularioEnquadramentoIdNumber = Number(formularioEnquadramentoId);
  const gerenciarFormulariosIdNumber = Number(gerenciarFormulariosId);
  const navigate = useNavigate();

  const [api, setApi] = useState<AvaliacaoEnquadramentoApi>(() =>
    makeAvaliacaoEnquadramento(formularioEnquadramentoIdNumber),
  );

  useEffect(() => {
    if (formularioEnquadramentoIdNumber && !api)
      setApi(makeAvaliacaoEnquadramento(formularioEnquadramentoIdNumber));
  }, []);

  const customActions = [
    () => ({
      hasPermission: ['EXIBIR_NO_MENU', 'BUSCAR_TODOS', 'BUSCAR_POR_ID'],
      moduleName: moduleNameFor('proposta'),
      icon: <VisibilityIcon />,
      title: 'Visualizar Submissão da Proposta',
      handler: (row: Proposta) => {
        setParameterFilters({
          ...parameterFilters,
          proposta: row,
        });
        navigate(`/submissao-proposta-detalhes/${row?.id}`, {
          state: {
            editalId: row?.edital?.id,
          },
        });
      },
    }),
    () => ({
      hasPermission: ['EXIBIR_NO_MENU', 'BUSCAR_TODOS', 'BUSCAR_POR_ID'],
      moduleName: moduleNameFor('avaliacao-de-enquadramento'),
      icon: <Survey size='24' />,
      title: 'Avaliações',
      handler: (row: Proposta) => {
        navigate(
          `/formulario-enquadramento/${formularioEnquadramentoIdNumber}/gerenciar-formularios/${gerenciarFormulariosIdNumber}/avaliacao-de-enquadramento/${row.id}/avaliacao-proposta`,
        );
      },
    }),
  ];

  return (
    <Crud<Proposta>
      headCells={headCells}
      titleConfig={{ value: 'Avaliação de Enquadramento', show: true }}
      uischema={propostaUiSchema}
      apiClient={api}
      apiUrl={'/avaliacao-de-enquadramento'}
      customActions={customActions}
      hideView
      hideCreate
      hideDelete
      hideUpdate
    ></Crud>
  );
}

export const rootViews = ['formulario-enquadramento', 'gerenciar-formularios'];

export default AvaliacaoDeEnquadramento;
