import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { Contratacao } from '../../../models/contratacao';

export const moduleName = 'PROPOSTA.CONTRATACAO.CONTRATACAO';

export const displayProperties = ['inicioVigencia'];

export const moduleLabel = 'Contratação';

export const headCells: readonly HeadCell<Contratacao>[] = [
  {
    id: 'inicioVigencia',
    numeric: false,
    disablePadding: false,
    label: 'Início da Vigência',
  },
];
