import Checkbox from '@mui/material/Checkbox';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TextField from '@mui/material/TextField';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { makeApi } from '../../../api/generic-api';
import { makePropostaApi } from '../../../api/proposta-api';
import { useCrudContext } from '../../../components/crud/crud-context';
import { AbrangenciaMunicipio } from '../../../models/abrangencia-municipio';
import { AreaDeConhecimento } from '../../../models/area-de-conhecimento';
import { Estado, Estado as EstadoModel } from '../../../models/estado';
import { Instituicao, Instituicao as InstituicaoModel } from '../../../models/instituicao';
import {
  Abrangencia,
  EncargoProposta,
  HospedagemAlimentacaoProposta,
  MaterialConsumoProposta,
  MaterialPermanenteProposta,
  PassagemProposta,
  Proposta,
  Proposta as PropostaModel,
  ServicoTerceiroProposta,
} from '../../../models/proposta';
import { Unidade, Unidade as UnidadeModel } from '../../../models/unidade';
import {
  BasicListSpanIn,
  BasicListSpanOut,
  BasicListSpanOutFlex,
  DocumentosDocsDaPropostaDefaut,
  DotListElement,
  Frame26085664,
  Frame26085668,
  Frame26085700,
  Frame26085750,
  IconSpan,
  QuestionDescritivaDiv,
  QuestionDescritivaSpan,
  QuestionDiv,
  QuestionDivTitle,
  QuestionMultiplaEscolhaDiv,
  QuestionMultiplaSpanItem,
  QuestionSelecaoMultiplaDiv,
  QuestionSelecaoMultiplaDivItem,
  QuestionSpanTitle,
  SPDDivider,
  SPDHeaderDiv,
  SPDHeaderSpan,
  SPDStyledButtonContained,
  SPDTable,
  SPDTableBodyCell,
  SPDTableBodyRow,
  SPDTableHeaderCell,
  SPDTableRow,
  SPDTextareaDiv,
  SPDTitleDiv,
  SPDTitleSpan,
  SPDValueDiv,
  SPDValueSpan,
  Substepper,
} from './style/submissao-proposta-detalhes';

import {
  CounterText,
  MenuItem,
  StyledCheckCheckIcon,
  StyledCircleIcon,
  StyledHorizontalRuleIcon,
  StyledList,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
  StyledListSubItemButton,
  StyledListSubItemText,
} from '../../../jsonforms/renderers/styled/categorization-menu';

import { JsonSchema } from '@jsonforms/core';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { Download } from '@styled-icons/feather/Download';
import { ViewShow } from '@styled-icons/zondicons/ViewShow';
import dayjs from 'dayjs';
import { FileApi } from '../../../api/file-api';
import AlertCustom from '../../../components/crud/alert-custom';
import { Severity } from '../../../components/crud/protocols/severity';
import { Check2 } from '../../../components/styled-icons';
import { BolsaProposta } from '../../../models/bolsa-proposta';
import { DiariaProposta } from '../../../models/diaria-proposta';
import { DocumentoPessoal } from '../../../models/documento-pessoal';
import { Endereco } from '../../../models/endereco';
import { PessoalProposta } from '../../../models/pessoal-proposta';
import { Usuario, VinculoInstitucional } from '../../../models/usuario';

function SubmissaoPropostaRevisao() {
  const routeParams = useParams();
  const propostaId = Number(routeParams['submissaoPropostaRevisaoId'] ?? null) ?? null;
  const [proposta, setProposta] = useState<PropostaModel>(null);
  const [schema, setSchema] = useState<JsonSchema>(null);
  const [instituicao, setInstituicao] = useState<InstituicaoModel>(null);
  const [unidade, setUnidade] = useState<UnidadeModel>(null);
  const [estados, setEstados] = useState<EstadoModel[]>(null);
  const [documentosPessoais, setDocumentosPessoais] = useState<DocumentoPessoal[]>(null);
  const [documentosProposta, setDocumentosProposta] = useState<any>(null);
  const [visibleTextFields, setVisibleTextFields] = useState({});
  const [textFieldValues, setTextFieldValues] = useState({});
  const [checkedFields, setCheckedFields] = useState([]);
  const [reviewValues, setReviewValues] = useState<{ label: string; value: string; id: string }[]>(
    [],
  );
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [severityAlert, setSeverityAlert] = useState<Severity>(Severity.SUCCESS);
  const { setCurrentTitle } = useCrudContext();
  const location = useLocation();
  const fileApi = new FileApi();

  useEffect(() => {
    const propostaApi = makePropostaApi(location?.state?.editalId);
    propostaApi.getSchema().then((schema) => {
      setSchema(schema);
    });
    propostaApi.get(propostaId).then((propostaObject) => {
      setProposta(propostaObject);
    });

    makeApi<Estado>('/estado')
      .getAll()
      .then((EstadoObject) => {
        setEstados(EstadoObject);
      });

    makeApi<DocumentoPessoal>('/documento-pessoal')
      .getAll()
      .then((documentoPessoal) => {
        setDocumentosPessoais(documentoPessoal);
      });

    fileApi
      .getCategories(`proposta/${propostaId}/documento-proposta-anexo`)
      .then((documentoProposta) => setDocumentosProposta(documentoProposta))
      .catch((error) => console.error('Erro ao obter categorias de arquivos:', error));
  }, [location?.state?.editalId, propostaId]);

  useEffect(() => {
    if (proposta) {
      if (proposta?.instituicaoExecutoraId) {
        makeApi<Instituicao>('/instituicao')
          .get(proposta?.instituicaoExecutoraId)
          .then((InstituicaoObject) => {
            setInstituicao(InstituicaoObject);
          });
      }
      if (proposta?.unidadeExecutoraId) {
        makeApi<Unidade>('/unidade')
          .get(proposta?.unidadeExecutoraId)
          .then((UnidadeObject) => {
            setUnidade(UnidadeObject);
          });
      }
      if (proposta?.revisaoTecnica && JSON.parse(proposta?.revisaoTecnica).length) {
        const updatedVisibleTextFields: Record<string, boolean> = {};
        const updatedTextFieldValues: Record<string, string> = {};
        const updatedCheckedFields: Record<string, boolean> = {};
        const revisaoTecnica = JSON.parse(proposta?.revisaoTecnica);
        setReviewValues(revisaoTecnica);

        revisaoTecnica.forEach((item) => {
          const textFieldId = `textarea-${item.id}`;
          const checkboxFieldId = item.id;

          updatedVisibleTextFields[textFieldId] = true;
          updatedTextFieldValues[textFieldId] = item.value || '';
          updatedCheckedFields[checkboxFieldId] = true;
        });

        setVisibleTextFields((prev) => ({ ...prev, ...updatedVisibleTextFields }));
        setTextFieldValues((prev) => ({ ...prev, ...updatedTextFieldValues }));
        setCheckedFields((prev) => ({ ...prev, ...updatedCheckedFields }));
      }
    }
  }, [proposta]);

  const titlePage = 'Visualização da Proposta';
  setCurrentTitle(titlePage);

  const showSuccess = (message: string) => {
    setMessageAlert(message);
    setSeverityAlert(Severity.SUCCESS);
    setOpenAlert(true);
    setTimeout(() => setOpenAlert(false), 3000);
  };

  const showError = (message: string) => {
    setMessageAlert(message);
    setSeverityAlert(Severity.ERROR);
    setOpenAlert(true);
    setTimeout(() => setOpenAlert(false), 3000);
  };

  const makeQuestionByType = (question, response) => {
    switch (question?.format) {
      case 'MultiplaSelecaoGrande':
      case 'MultiplaSelecaoPequena':
        return (
          <QuestionDiv>
            <QuestionDivTitle>
              <QuestionSpanTitle>{question?.title}</QuestionSpanTitle>
            </QuestionDivTitle>
            <QuestionSelecaoMultiplaDiv>
              {question?.oneOf.map((item, index: number) => {
                if (item.const === response[index]) {
                  return (
                    <>
                      <QuestionSelecaoMultiplaDivItem>
                        <DotListElement></DotListElement>
                        <QuestionMultiplaSpanItem>
                          {item.const === response[index] ? item.title : ''}
                        </QuestionMultiplaSpanItem>
                      </QuestionSelecaoMultiplaDivItem>
                    </>
                  );
                }
                return <></>;
              })}
            </QuestionSelecaoMultiplaDiv>
          </QuestionDiv>
        );

      case 'MultiplaEscolha':
        return (
          <QuestionDiv>
            <QuestionDivTitle>
              <QuestionSpanTitle>{question?.title}</QuestionSpanTitle>
            </QuestionDivTitle>
            <QuestionMultiplaEscolhaDiv>
              <DotListElement></DotListElement>
              <QuestionMultiplaSpanItem>
                {question?.oneOf.find((item) => item.const === response).title}
              </QuestionMultiplaSpanItem>
            </QuestionMultiplaEscolhaDiv>
          </QuestionDiv>
        );

      case 'Descritiva':
      default:
        return (
          <QuestionDiv>
            <QuestionDivTitle>
              <QuestionSpanTitle>{question?.title}</QuestionSpanTitle>
            </QuestionDivTitle>
            <QuestionDescritivaDiv>
              <QuestionDescritivaSpan>{response}</QuestionDescritivaSpan>
            </QuestionDescritivaDiv>
          </QuestionDiv>
        );
    }
  };

  const makeFormularioQuestions = (obj) => {
    const questions = schema?.properties?.formularioPropostaDescritiva?.properties;
    return Object.entries(obj).map(([key, value]) => {
      return makeQuestionByType(questions[key], value);
    });
  };

  const makeGroupInline = (obj) => {
    return obj.map((item: { title: string; value }) => {
      return (
        <>
          <BasicListSpanOut>
            <BasicListSpanIn>{item.title}</BasicListSpanIn>: {item.value}
          </BasicListSpanOut>
        </>
      );
    });
  };

  const makeBasicList = (obj) => {
    return obj.map((item: { type?: string; title?: string; value }) => {
      if (item.type === 'inline') {
        return (
          <>
            {item.value}
            <br />
          </>
        );
      } else if (item.type === 'multiple-line') {
        return (
          <>
            <BasicListSpanOutFlex>
              <BasicListSpanIn>{item.title}</BasicListSpanIn>: <div>{item.value}</div>
            </BasicListSpanOutFlex>
            <br />
          </>
        );
      }

      return (
        <>
          <BasicListSpanOut>
            <BasicListSpanIn>{item.title}</BasicListSpanIn>: {item.value}
          </BasicListSpanOut>
          <br />
        </>
      );
    });
  };
  const makeNestedString = (obj: string[][]) => {
    return obj?.map((item: string[], index: number) => {
      return (
        <>
          {item.join(' > ')}
          <br />
        </>
      );
    });
  };
  const makeTable = (data: string[], titles: string[]) => {
    return (
      <div>
        <SPDTable>
          <TableHead>
            <SPDTableRow>
              {titles.map((item, index) => {
                const borderRadiusTL = index === 0 ? '2px' : '0px';
                const borderRadiusTR = index === titles.length - 1 ? '2px' : '0px';
                return (
                  <>
                    <SPDTableHeaderCell
                      borderTopLeftRadius={borderRadiusTL}
                      borderTopRightRadius={borderRadiusTR}
                    >
                      {item}
                    </SPDTableHeaderCell>
                  </>
                );
              })}
            </SPDTableRow>
          </TableHead>
          <TableBody>
            {data.map((item, i) => (
              <>
                <SPDTableBodyRow>
                  {titles.map((_, j) => (
                    <>
                      <SPDTableBodyCell>{data[i][j]}</SPDTableBodyCell>
                    </>
                  ))}
                </SPDTableBodyRow>
              </>
            ))}
          </TableBody>
        </SPDTable>
      </div>
    );
  };

  const buildAbrangenciaMunicipios = (abrangenciaMunicipios: AbrangenciaMunicipio[]) => {
    return abrangenciaMunicipios
      .map((item) => {
        return item.municipio;
      })
      .join(', ');
  };

  const buildAbrangencia = (abrangencia: Abrangencia[]) => {
    const abrangenciaObj = [];

    for (let i = 0; i < abrangencia?.length; i++) {
      abrangenciaObj.push([
        [getEstadoById(abrangencia[i].estadoId)],
        [buildAbrangenciaMunicipios(abrangencia[i].abrangenciaMunicipio)],
      ]);
    }

    return abrangenciaObj;
  };

  const buildInformacoesIniciais = (proposta: Proposta) => {
    return [
      {
        title: 'Título do Edital',
        value: proposta?.edital?.nome,
      },
      {
        title: 'Título do Projeto',
        value: proposta?.tituloDoProjeto,
      },
      {
        title: 'Coordenador',
        value: proposta?.criadoPor?.nome,
      },
      {
        title: 'E-mail',
        value: proposta?.criadoPor?.email,
      },
      {
        title: 'Duração do Projeto em Meses',
        value: proposta?.duracao,
      },
      ...(instituicao
        ? [
            {
              title: 'Instituição Executora',
              value: instituicao.nome,
            },
          ]
        : []),
      ...(unidade
        ? [
            {
              title: 'Unidade Executora',
              value: unidade.nome,
            },
          ]
        : []),
      ...(proposta?.areaDeConhecimento.length
        ? [
            {
              title: 'Áreas de Conhecimentos da Proposta',
              value: makeNestedString(buildAreaDeConhecimento(proposta?.areaDeConhecimento)),
              type: 'multiple-line',
            },
          ]
        : []),
    ];
  };

  const buildCoordenadorEndereco = (endereco: Endereco) => {
    const enderecoGroup = [
      ...(endereco?.logradouro
        ? [
            {
              title: 'Endereço',
              value: endereco.logradouro,
            },
          ]
        : []),
      ...(endereco?.bairro
        ? [
            {
              title: 'Bairro',
              value: endereco.bairro,
            },
          ]
        : []),
      ...(endereco?.cep
        ? [
            {
              title: 'CEP',
              value: endereco.cep,
            },
          ]
        : []),
    ];

    return [
      ...(enderecoGroup.length > 0
        ? [
            {
              type: 'inline',
              value: makeGroupInline(enderecoGroup),
            },
          ]
        : []),
    ];
  };

  const buildCoordenadorDadosAcademicos = (criadoPor: Usuario) => {
    const instituicaoUnidadeNivelAcademico = [
      ...(criadoPor?.instituicaoId
        ? [
            {
              title: 'Instituição',
              value: criadoPor.instituicao.nome,
            },
          ]
        : []),
      ...(criadoPor?.unidadeId
        ? [
            {
              title: 'Unidade',
              value: criadoPor.unidade.nome,
            },
          ]
        : []),
      ...(criadoPor?.nivelAcademicoId
        ? [
            {
              title: 'Nível Acadêmico',
              value: criadoPor.nivelAcademico.tipo,
            },
          ]
        : []),
    ];

    const lattesLinkedin = [
      ...(criadoPor?.lattes
        ? [
            {
              title: 'Currículo Lattes',
              value: criadoPor.lattes,
            },
          ]
        : []),
      ...(criadoPor?.linkedin
        ? [
            {
              title: 'LinkedIn',
              value: criadoPor.linkedin,
            },
          ]
        : []),
    ];

    return [
      ...(instituicaoUnidadeNivelAcademico.length > 0
        ? [
            {
              type: 'inline',
              value: makeGroupInline(instituicaoUnidadeNivelAcademico),
            },
          ]
        : []),
      ...(lattesLinkedin.length > 0
        ? [
            {
              type: 'inline',
              value: makeGroupInline(lattesLinkedin),
            },
          ]
        : []),
      {
        title: 'Áreas de Conhecimentos',
        type: 'multiple-line',
        value: makeNestedString(buildAreaDeConhecimento(criadoPor?.areaDeConhecimento)),
      },
    ];
  };

  const buildCoordenadorDadosProfissionais = (vinculoInstitucional: VinculoInstitucional) => {
    const vinculoInicioServicoRegimeTrabalho = [
      ...(vinculoInstitucional?.tipoVinculoInstitucionalId
        ? [
            {
              title: 'Tipo de Vínculo',
              value: vinculoInstitucional.tipoVinculoInstitucional.nome,
            },
          ]
        : []),
      ...(vinculoInstitucional?.inicioServico
        ? [
            {
              title: 'Início de Serviço',
              value: dayjs(vinculoInstitucional.inicioServico).format('DD/MM/YYYY'),
            },
          ]
        : []),
      ...(vinculoInstitucional?.regimeTrabalhoId
        ? [
            {
              title: 'Regime de Trabalho',
              value: vinculoInstitucional.regimeTrabalho.nome,
            },
          ]
        : []),
    ];

    const fCInicioFC = [
      ...(vinculoInstitucional?.funcao
        ? [
            {
              title: 'Função/Cargo',
              value: vinculoInstitucional.funcao,
            },
          ]
        : []),
      ...(vinculoInstitucional?.inicioFuncao
        ? [
            {
              title: 'Início da Função/Cargo',
              value: dayjs(vinculoInstitucional.inicioFuncao).format('DD/MM/YYYY'),
            },
          ]
        : []),
    ];

    return [
      ...(vinculoInicioServicoRegimeTrabalho.length > 0
        ? [
            {
              type: 'inline',
              value: makeGroupInline(vinculoInicioServicoRegimeTrabalho),
            },
          ]
        : []),
      ...(fCInicioFC.length > 0
        ? [
            {
              type: 'inline',
              value: makeGroupInline(fCInicioFC),
            },
          ]
        : []),
    ];
  };

  const buildCoordenadorDadosPessoais = (criadoPor: Usuario) => {
    const paisCpfEmail = [
      ...(criadoPor?.paisId
        ? [
            {
              title: 'País de Nacionalidade',
              value: criadoPor.pais.nomePt,
            },
          ]
        : []),
      ...(criadoPor?.documento
        ? [
            {
              title: 'CPF',
              value: criadoPor.documento,
            },
          ]
        : []),
      ...(criadoPor?.email
        ? [
            {
              title: 'E-mail',
              value: criadoPor.email,
            },
          ]
        : []),
    ];

    return [
      {
        title: 'Nome Completo',
        value: criadoPor?.nome,
      },
      ...(criadoPor?.nomeSocial
        ? [
            {
              title: 'Nome Social',
              value: criadoPor.nomeSocial,
            },
          ]
        : []),
      ...(paisCpfEmail.length > 0
        ? [
            {
              type: 'inline',
              value: makeGroupInline(paisCpfEmail),
            },
          ]
        : []),
    ];
  };

  const toMoney = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    })
      .format(value / 100)
      .replace('R$', '')
      .trim();
  };

  const buildRubricaDiaria = (diarias: DiariaProposta[]) => {
    const diariaObj = [];

    for (let i = 0; i < diarias?.length; i++) {
      diariaObj.push([
        [
          diarias[i].pais.codigo === 'BRA'
            ? `${diarias[i].pais.nomePt}-${diarias[i].estado.nome}-${diarias[i].municipio}`
            : `${diarias[i].pais.nomePt}-${diarias[i].estadoRegiao}`,
        ],
        [diarias[i].numeroDiaria / 100],
        [toMoney(diarias[i].custoUnitario)],
        [`${diarias[i].mesPrevisto}º`],
        [diarias[i].contrapartida ? 'Sim' : 'Não'],
        [diarias[i].moedaEstrangeiraId ? diarias[i].moedaEstrangeira.simbolo : 'R$'],
        [toMoney(diarias[i].valorTotal)],
      ]);
    }

    return diariaObj;
  };

  const buildRubricaMaterialConsumo = (materialConsumo: MaterialConsumoProposta[]) => {
    const materialConsumoObj = [];

    for (let i = 0; i < materialConsumo?.length; i++) {
      materialConsumoObj.push([
        [materialConsumo[i].especificacao],
        [materialConsumo[i].quantidade / 100],
        [toMoney(materialConsumo[i].custoUnitario)],
        [`${materialConsumo[i].mesPrevisto}º`],
        [materialConsumo[i].contrapartida ? 'Sim' : 'Não'],
        [
          materialConsumo[i].moedaEstrangeiraId
            ? materialConsumo[i].moedaEstrangeira.simbolo
            : 'R$',
        ],
        [toMoney(materialConsumo[i].valorTotal)],
      ]);
    }

    return materialConsumoObj;
  };

  const buildRubricaMaterialPermanente = (materialPermanente: MaterialPermanenteProposta[]) => {
    const materialPermanenteObj = [];

    for (let i = 0; i < materialPermanente?.length; i++) {
      materialPermanenteObj.push([
        [materialPermanente[i].especificacao],
        [materialPermanente[i].tipoOrigem],
        [materialPermanente[i].quantidade],
        [toMoney(materialPermanente[i].custoUnitario)],
        [`${materialPermanente[i].mesPrevisto}º`],
        [materialPermanente[i].contrapartida ? 'Sim' : 'Não'],
        [
          materialPermanente[i].moedaEstrangeiraId
            ? materialPermanente[i].moedaEstrangeira.simbolo
            : 'R$',
        ],
        [toMoney(materialPermanente[i].valorTotal)],
      ]);
    }

    return materialPermanenteObj;
  };

  const buildRubricaPassagem = (passagem: PassagemProposta[]) => {
    const passagemObj = [];

    for (let i = 0; i < passagem?.length; i++) {
      passagemObj.push([
        [passagem[i].trecho],
        [passagem[i].idaVolta ? 'Sim' : 'Não'],
        [
          passagem[i].paisOrigemId
            ? `${passagem[i].paisOrigem.nomePt}${passagem[i].estadoRegiaoOrigem ? `-${passagem[i].estadoRegiaoOrigem}` : ''}`
            : `Brasil${passagem[i].estadoOrigemId ? `-${passagem[i].estadoOrigem.nome}${passagem[i].municipioOrigem ? `-${passagem[i].municipioOrigem}` : ''}` : ''}`,
        ],
        [
          passagem[i].paisDestinoId
            ? `${passagem[i].paisDestino.nomePt}${passagem[i].estadoRegiaoDestino ? `-${passagem[i].estadoRegiaoDestino}` : ''}`
            : `Brasil${passagem[i].estadoDestinoId ? `-${passagem[i].estadoDestino.nome}${passagem[i].municipioDestino ? `-${passagem[i].municipioDestino}` : ''}` : ''}`,
        ],
        [passagem[i].tipo],
        [toMoney(passagem[i].custoUnitario)],
        [passagem[i].quantidade],
        [`${passagem[i].mesPrevisto}º`],
        [passagem[i].contrapartida ? 'Sim' : 'Não'],
        [passagem[i].moedaEstrangeiraId ? passagem[i].moedaEstrangeira.simbolo : 'R$'],
        [toMoney(passagem[i].valorTotal)],
      ]);
    }

    return passagemObj;
  };

  const buildHospedagemAlimentacao = (hospedagemAlimentacao: HospedagemAlimentacaoProposta[]) => {
    const hospedagemAlimentacaoObj = [];

    for (let i = 0; i < hospedagemAlimentacao?.length; i++) {
      hospedagemAlimentacaoObj.push([
        [
          hospedagemAlimentacao[i].pais.codigo === 'BRA'
            ? `${hospedagemAlimentacao[i].pais.nomePt}${hospedagemAlimentacao[i].estadoId ? `-${getEstadoById(hospedagemAlimentacao[i].estadoId)}${hospedagemAlimentacao[i].municipio ? `-${hospedagemAlimentacao[i].municipio}` : ''}` : ''}`
            : `${hospedagemAlimentacao[i].pais.nomePt}-${hospedagemAlimentacao[i].estadoRegiao}`,
        ],
        [hospedagemAlimentacao[i].especificacao],
        [toMoney(hospedagemAlimentacao[i].custoUnitario)],
        [hospedagemAlimentacao[i].quantidade],
        [`${hospedagemAlimentacao[i].mesPrevisto}º`],
        [hospedagemAlimentacao[i].contrapartida ? 'Sim' : 'Não'],
        [
          hospedagemAlimentacao[i].moedaEstrangeiraId
            ? hospedagemAlimentacao[i].moedaEstrangeira.simbolo
            : 'R$',
        ],
        [toMoney(hospedagemAlimentacao[i].valorTotal)],
      ]);
    }

    return hospedagemAlimentacaoObj;
  };

  const buildServicoTerceiro = (servicoTerceiro: ServicoTerceiroProposta[]) => {
    const servicoTerceiroObj = [];

    for (let i = 0; i < servicoTerceiro?.length; i++) {
      servicoTerceiroObj.push([
        [servicoTerceiro[i].especificacao],
        [servicoTerceiro[i].tipo === 'PESSOA_JURIDICA' ? 'Pessoa Jurídica' : ''],
        [`${servicoTerceiro[i].mesPrevisto}º`],
        [servicoTerceiro[i].contrapartida ? 'Sim' : 'Não'],
        [
          servicoTerceiro[i].moedaEstrangeiraId
            ? servicoTerceiro[i].moedaEstrangeira.simbolo
            : 'R$',
        ],
        [toMoney(servicoTerceiro[i].custoUnitario)],
      ]);
    }

    return servicoTerceiroObj;
  };

  const buildEncargo = (encargo: EncargoProposta[]) => {
    const encargoObj = [];

    for (let i = 0; i < encargo?.length; i++) {
      encargoObj.push([
        [encargo[i].especificacao],
        [`${encargo[i].mesPrevisto}º`],
        [encargo[i].contrapartida ? 'Sim' : 'Não'],
        [encargo[i].moedaEstrangeiraId ? encargo[i].moedaEstrangeira.simbolo : 'R$'],
        [toMoney(encargo[i].custoUnitario)],
      ]);
    }

    return encargoObj;
  };

  const buildPessoal = (pessoal: PessoalProposta[]) => {
    const pessoalObj = [];

    for (let i = 0; i < pessoal?.length; i++) {
      pessoalObj.push([
        [pessoal[i].funcao],
        [pessoal[i].formacaoProfissional],
        [pessoal[i].perfilDesejado],
        [`${pessoal[i].mesInicio}º`],
        [pessoal[i].contrapartida ? 'Sim' : 'Não'],
        [pessoal[i].moedaEstrangeiraId ? pessoal[i].moedaEstrangeira.simbolo : 'R$'],
        [toMoney(pessoal[i].valor)],
      ]);
    }

    return pessoalObj;
  };

  const buildBolsa = (bolsa: BolsaProposta[]) => {
    const bolsaObj = [];

    for (let i = 0; i < bolsa?.length; i++) {
      bolsaObj.push([
        [bolsa[i].nivelBolsa.nivel],
        [bolsa[i].quantidade],
        [bolsa[i].duracao],
        [toMoney(bolsa[i].nivelBolsa.valorAtualBolsa)],
        [bolsa[i].contrapartida ? 'Sim' : 'Não'],
        [toMoney(bolsa[i].valorTotal)],
      ]);
    }

    return bolsaObj;
  };

  const handleClickDonwload = (element) => {
    alert(`${element} download...`);
  };

  const handleClickView = (element) => {
    alert(`${element} view...`);
  };

  const buildDocumentosPessoais = (documentosPessoais: DocumentoPessoal[]) => {
    const documentoPessoalObj = [];
    const documentosPessoaisEdital = proposta?.edital?.documentoPessoalEdital;

    for (let i = 0; i < documentosPessoaisEdital?.length; i++) {
      const documento = documentosPessoais.filter(
        (item) => item.id === documentosPessoaisEdital[i].documentoPessoalId,
      );

      if (documento) {
        documentoPessoalObj.push([
          [documento[0].nome],
          [dayjs(documento[0].createdAt).format('DD/MM/YYYY')],
          [
            <>
              <IconSpan>
                <a onClick={() => handleClickDonwload(documento[0].nome)}>
                  <Download size={20} />
                </a>
              </IconSpan>
              <IconSpan>
                <a onClick={() => handleClickView(documento[0].nome)}>
                  <ViewShow size={20} />
                </a>
              </IconSpan>
            </>,
          ],
        ]);
      }
    }

    return documentoPessoalObj;
  };

  const buildDocumentosProposta = () => {
    const documentoPropostaObj = [];
    const documentosPropostasEdital = proposta?.edital?.documentoPropostaEdital;

    for (let i = 0; i < documentosProposta?.length; i++) {
      const documento = documentosPropostasEdital.filter(
        (item) => item.nome === documentosProposta[i].categoria,
      );
      if (documento) {
        documentoPropostaObj.push([
          [`${documento[0].nome} - ${documentosProposta[i].nome}`],
          [dayjs(documentosProposta[i].createdAt).format('DD/MM/YYYY')],
          [documento[0].descricao],
          [
            <>
              <IconSpan>
                <a onClick={() => handleClickDonwload(documento[0].nome)}>
                  <Download size={20} />
                </a>
              </IconSpan>
              <IconSpan>
                <a onClick={() => handleClickView(documento[0].nome)}>
                  <ViewShow size={20} />
                </a>
              </IconSpan>
            </>,
          ],
        ]);
      }
    }

    return documentoPropostaObj;
  };

  const buildRubricas = (proposta: Proposta) => {
    return [
      ...(proposta?.diariaProposta.length
        ? [
            {
              title: 'Diárias',
              value: [
                makeTable(buildRubricaDiaria(proposta?.diariaProposta), [
                  'Localidade',
                  'Número de Diárias',
                  'Custo Unitário',
                  'Mês Previsto',
                  'Contrapartida',
                  'Moeda',
                  'Valor Total',
                ]),
              ],
            },
          ]
        : []),

      ...(proposta?.materialConsumoProposta.length
        ? [
            {
              title: 'Material de Consumo',
              value: [
                makeTable(buildRubricaMaterialConsumo(proposta?.materialConsumoProposta), [
                  'Especificação',
                  'Quantidade',
                  'Custo Unitário',
                  'Mês Previsto',
                  'Contrapartida',
                  'Moeda',
                  'Valor Total',
                ]),
              ],
            },
          ]
        : []),

      ...(proposta?.materialPermanenteProposta.length
        ? [
            {
              title: 'Material Permanente',
              value: [
                makeTable(buildRubricaMaterialPermanente(proposta?.materialPermanenteProposta), [
                  'Especificação',
                  'Tipo',
                  'Quantidade',
                  'Custo Unitário',
                  'Mês Previsto',
                  'Contrapartida',
                  'Moeda',
                  'Valor Total',
                ]),
              ],
            },
          ]
        : []),

      ...(proposta?.passagemProposta.length
        ? [
            {
              title: 'Passagens',
              value: [
                makeTable(buildRubricaPassagem(proposta?.passagemProposta), [
                  'Trecho',
                  'Ida e Volta',
                  'Origem',
                  'Destino',
                  'Tipo',
                  'Custo Unitário',
                  'Quantidade',
                  'Mês Previsto',
                  'Contrapartida',
                  'Moeda',
                  'Valor Total',
                ]),
              ],
            },
          ]
        : []),

      ...(proposta?.hospedagemAlimentacaoProposta.length
        ? [
            {
              title: 'Hospedagem e Alimentação',
              value: [
                makeTable(buildHospedagemAlimentacao(proposta?.hospedagemAlimentacaoProposta), [
                  'Localidade',
                  'Especificação',
                  'Custo Unitário',
                  'Quantidade',
                  'Mês Previsto',
                  'Contrapartida',
                  'Moeda',
                  'Valor Total',
                ]),
              ],
            },
          ]
        : []),

      ...(proposta?.servicoTerceiroProposta.length
        ? [
            {
              title: 'Serviços de Terceiros',
              value: [
                makeTable(buildServicoTerceiro(proposta?.servicoTerceiroProposta), [
                  'Especificação',
                  'Tipo',
                  'Mês Previsto',
                  'Contrapartida',
                  'Moeda',
                  'Valor',
                ]),
              ],
            },
          ]
        : []),

      ...(proposta?.encargoProposta.length
        ? [
            {
              title: 'Encargos',
              value: [
                makeTable(buildEncargo(proposta?.encargoProposta), [
                  'Especificação',
                  'Mês Previsto',
                  'Contrapartida',
                  'Moeda',
                  'Valor',
                ]),
              ],
            },
          ]
        : []),

      ...(proposta?.pessoalProposta.length
        ? [
            {
              title: 'Pessoal',
              value: [
                makeTable(buildPessoal(proposta?.pessoalProposta), [
                  'Função',
                  'Formação',
                  'Perfil Desejado',
                  'Mês de Início',
                  'Contrapartida',
                  'Moeda',
                  'Valor',
                ]),
              ],
            },
          ]
        : []),

      ...(proposta?.bolsaProposta.length
        ? [
            {
              title: 'Bolsas',
              value: [
                makeTable(buildBolsa(proposta?.bolsaProposta), [
                  'Modalidade e Nível',
                  'Quantidade',
                  'Duração',
                  'Valor Bolsa',
                  'Contrapartida',
                  'Valor Total',
                ]),
              ],
            },
          ]
        : []),
    ];
  };

  const getDocumentosPessoais = () => {
    return [
      makeTable(buildDocumentosPessoais(documentosPessoais), ['Nome', 'Data de Envio', 'Anexo']),
    ];
  };

  const getDocumentosProposta = () => {
    return [makeTable(buildDocumentosProposta(), ['Nome', 'Data de Envio', 'Tipo', 'Anexo'])];
  };

  const getCoordenadorDadosPessoais = () => {
    return makeBasicList(buildCoordenadorDadosPessoais(proposta?.criadoPor));
  };

  const getCoordenadorEndereco = () => {
    return makeBasicList(buildCoordenadorEndereco(proposta?.criadoPor?.endereco));
  };

  const getCoordenadorDadosAcademicos = () => {
    return makeBasicList(buildCoordenadorDadosAcademicos(proposta?.criadoPor));
  };

  const getCoordenadorDadosProfissionais = () => {
    return makeBasicList(
      buildCoordenadorDadosProfissionais(proposta?.criadoPor?.vinculoInstitucional),
    );
  };

  const getDescricao = () => {
    return makeFormularioQuestions(proposta?.formularioPropostaDescritiva);
  };

  const getOrcamento = () => {
    return makeBasicList(buildRubricas(proposta));
  };

  const buildAreaDeConhecimento = (areaDeConhecimento: AreaDeConhecimento[]) => {
    const areDeConhecimentoObj = [];

    for (let i = 0; i < areaDeConhecimento?.length; i++) {
      const entry = [areaDeConhecimento[i].grandeAreaNome, areaDeConhecimento[i].areaNome];

      if (areaDeConhecimento[i].subAreaNome) {
        entry.push(areaDeConhecimento[i].subAreaNome);
      }

      if (areaDeConhecimento[i].especialidadeNome) {
        entry.push(areaDeConhecimento[i].especialidadeNome);
      }

      areDeConhecimentoObj.push(entry);
    }

    return areDeConhecimentoObj;
  };

  const getEstadoById = (estadoId: number): string => {
    const estado = estados.filter((estado) => estado.id === estadoId);
    return estado[0] ? estado[0].nome : '';
  };

  const getAbrangencia = (abrangencia: Abrangencia[]) => {
    return [makeTable(buildAbrangencia(abrangencia), ['Estado', 'Municípios'])];
  };

  const getInformacoesIniciais = () => {
    return makeBasicList(buildInformacoesIniciais(proposta));
  };

  const submissaoPropostaObj = [
    {
      title: 'Caracterização da Proposta',
      value: [
        {
          title: 'Informações Iniciais',
          value: getInformacoesIniciais(),
        },
        proposta?.abrangencia.length
          ? {
              title: 'Abrangências',
              value: getAbrangencia(proposta.abrangencia),
            }
          : null,
      ],
    },
    {
      title: 'Dados do Coordenador',
      value: [
        {
          title: 'Dados Pessoais',
          value: getCoordenadorDadosPessoais(),
        },
        ...(proposta?.criadoPor?.endereco
          ? [
              {
                title: 'Endereço',
                value: getCoordenadorEndereco(),
              },
            ]
          : []),
        ...(proposta?.criadoPor?.nivelAcademicoId
          ? [
              {
                title: 'Dados Acadêmicos',
                value: getCoordenadorDadosAcademicos(),
              },
            ]
          : []),
        ...(proposta?.criadoPor?.vinculoInstitucional
          ? [
              {
                title: 'Dados Profissionais',
                value: getCoordenadorDadosProfissionais(),
              },
            ]
          : []),
      ],
    },
    {
      title: 'Anexos da Proposta',
      value: [
        ...(documentosPessoais?.length
          ? [
              {
                title: 'Documentos Pessoais',
                value: getDocumentosPessoais(),
              },
            ]
          : []),

        ...(proposta?.edital?.documentoPropostaEdital?.length
          ? [
              {
                title: 'Documentos da Proposta',
                value: getDocumentosProposta(),
              },
            ]
          : []),
      ],
    },
    {
      title: 'Plano de Trabalho',
      value: [
        ...(proposta?.formularioPropostaDescritiva &&
        Object.keys(proposta?.formularioPropostaDescritiva).length
          ? [
              {
                title: 'Descrição',
                value: getDescricao(),
              },
            ]
          : []),
        ...(proposta?.bolsaProposta.length ||
        proposta?.diariaProposta.length ||
        proposta?.encargoProposta.length ||
        proposta?.hospedagemAlimentacaoProposta.length ||
        proposta?.materialConsumoProposta.length ||
        proposta?.materialPermanenteProposta.length ||
        proposta?.passagemProposta.length ||
        proposta?.pessoalProposta.length ||
        proposta?.servicoTerceiroProposta.length
          ? [
              {
                title: 'Orçamento',
                value: getOrcamento(),
              },
            ]
          : []),
      ],
    },
  ];

  const categories = submissaoPropostaObj;

  const categoryElements = useMemo(() => {
    const findFinalElements = (items) => {
      return items.flatMap((item) => {
        if (item?.title) {
          if (item?.value && Array.isArray(item?.value) && item?.value.some((x) => 'title' in x)) {
            return findFinalElements(item?.value);
          }
          return [item];
        }
        return [];
      });
    };

    const finalElements = findFinalElements(categories);
    return finalElements.filter((x) => x.value?.length > 0); // Re-enable the filter if needed
  }, [categories]);

  const [selectedCategory, setSelectedCategory] = useState<any>(categoryElements[0]);

  const countSubItems = (item: any, currentDepth: number, targetDepth: number): number => {
    if (!item?.value || !Array.isArray(item.value)) return 0;

    return item?.value?.reduce((count: number, subItem: any) => {
      if (subItem?.title && subItem?.value?.length > 0) {
        if (currentDepth + 1 === targetDepth) {
          return count + 1;
        } else {
          return count + countSubItems(subItem, currentDepth + 1, targetDepth);
        }
      }
      return count;
    }, 0);
  };

  const showProgress = true;

  const iconsByDepth = {
    0: <StyledCheckCheckIcon />,
    1: <StyledCircleIcon />,
    2: <StyledHorizontalRuleIcon />,
  };

  const [open, setOpen] = useState<Array<{ [key: string]: boolean }>>([]);

  const handleClick = (key: string, depth: number) => {
    setOpen((prevState) => {
      const newOpen = [...prevState];
      newOpen[depth] = {
        ...Object.keys(newOpen[depth] || {}).reduce((acc, k) => ({ ...acc, [k]: false }), {}),
      };
      newOpen[depth][key] = !prevState[depth]?.[key];
      return newOpen;
    });
  };

  const onClickElement = async (element: { title: string; value: [] }) => {
    setSelectedCategory(element);
  };

  const isAnyParentSelected = (item: any): boolean => {
    if (selectedCategory.title === item.title) return true;
    return item.value?.some(
      (child) =>
        selectedCategory.title === child.title || (child.title && isAnyParentSelected(child)),
    );
  };

  const renderMenuItems = (items: any[], depth: number = 0, indice: number = 0) => {
    let j: number = 0;
    return items
      .filter((x) => x?.title)
      .map((item, index) => {
        let subItemCount = 0;
        const icon = iconsByDepth[depth] || iconsByDepth[2];
        if (showProgress) subItemCount = countSubItems(item, depth, depth + 1);

        const isOpen = isAnyParentSelected(item);
        if (Array.isArray(item?.value) && item?.value.some((x) => x?.title)) {
          indice++;
          return (
            <MenuItem key={index} depth={depth}>
              <StyledListItemButton onClick={() => handleClick(item?.title, depth)}>
                <StyledListItemIcon>{icon}</StyledListItemIcon>
                <StyledListItemText primary={`${indice}. ${item?.title}`} />
                {showProgress && <CounterText>{`0/${subItemCount}`}</CounterText>}
                {isOpen || open[depth]?.[item?.title] ? <ExpandLess /> : <ExpandMore />}
              </StyledListItemButton>
              <Collapse in={isOpen || open[depth]?.[item?.title]} timeout='auto' unmountOnExit>
                <StyledList dense role='list'>
                  {renderMenuItems(item?.value, depth + 1, indice)}
                </StyledList>
              </Collapse>
            </MenuItem>
          );
        } else if (item?.value?.length > 0) {
          j++;
          return (
            <StyledListSubItemButton
              key={index}
              depth={depth}
              onClick={() => onClickElement(item)}
              selected={selectedCategory?.title === item?.title}
            >
              <StyledListItemIcon>{icon}</StyledListItemIcon>
              <StyledListSubItemText primary={`${indice}.${j}. ${item?.title}`} />
            </StyledListSubItemButton>
          );
        }
        return null;
      });
  };

  const handleClickJustify = (event, label: string) => {
    const checkboxFieldId = event.target.id;
    const textFieldId = `textarea-${event.target.id}`;

    setCheckedFields((prev) => {
      const isChecked = !prev[checkboxFieldId];

      if (!isChecked) {
        setTextFieldValues((prev) => ({
          ...prev,
          [textFieldId]: '',
        }));

        setReviewValues((prev: any) => {
          return prev.filter((item) => item.label !== label);
        });
      }

      return {
        ...prev,
        [checkboxFieldId]: isChecked,
      };
    });

    setVisibleTextFields((prev) => ({
      ...prev,
      [textFieldId]: !prev[textFieldId],
    }));
  };

  const handleChangeJustify = (event, label: string, key: string) => {
    const { id, value } = event.target;

    setTextFieldValues((prev) => ({
      ...prev,
      [id]: value,
    }));

    setReviewValues((prev: any) => {
      if (!value) {
        return prev.filter((item) => item.label !== label);
      } else {
        const existingIndex = prev.findIndex((item) => item.label === label);

        if (existingIndex !== -1) {
          const updatedValues = [...prev];
          updatedValues[existingIndex].value = value;
          updatedValues[existingIndex].id = key;
          return updatedValues;
        } else {
          return [...prev, { label, value }];
        }
      }
    });
  };

  const saveReview = () => {
    const propostaApi = makePropostaApi(location?.state?.editalId);

    proposta.revisaoTecnica = JSON.stringify(reviewValues);
    propostaApi
      .put(propostaId, proposta)
      .then(() => {
        showSuccess('Salvo com sucesso!');
      })
      .catch(() => {
        showError('Erro ao salvar!');
      });
  };

  return (
    <>
      <DocumentosDocsDaPropostaDefaut>
        <SPDHeaderDiv>
          <SPDHeaderSpan>{titlePage}</SPDHeaderSpan>
        </SPDHeaderDiv>
        <Substepper></Substepper>
        <Frame26085664>
          <Frame26085750>
            <Frame26085668>
              {submissaoPropostaObj.map((x, i) => {
                return (
                  <>
                    <SPDTitleDiv>
                      <SPDTitleSpan>
                        {i + 1}. {x.title.toUpperCase()}
                      </SPDTitleSpan>
                      <SPDDivider></SPDDivider>
                    </SPDTitleDiv>
                    {Array.isArray(x.value)
                      ? x.value.map((y, j) => {
                          if (y) {
                            return (
                              <>
                                <SPDValueDiv>
                                  {y.title === 'Orçamento' ? (
                                    <>
                                      <SPDValueSpan>
                                        {i + 1}.{j + 1}. {y.title}
                                      </SPDValueSpan>
                                    </>
                                  ) : (
                                    <>
                                      <SPDValueSpan>
                                        <Checkbox
                                          onClick={(event) => handleClickJustify(event, y.title)}
                                          id={`${i + 1}.${j + 1}`}
                                          checked={checkedFields[`${i + 1}.${j + 1}`] || false}
                                        />
                                        {i + 1}.{j + 1}. {y.title}
                                      </SPDValueSpan>
                                      <Hidden
                                        xsUp={!visibleTextFields[`textarea-${i + 1}.${j + 1}`]}
                                      >
                                        <SPDTextareaDiv className='custom-input-container'>
                                          <TextField
                                            id={`textarea-${i + 1}.${j + 1}`}
                                            size='small'
                                            multiline
                                            className={`textarea-${i + 1}.${j + 1}`}
                                            label={'Justificativa'}
                                            required={false}
                                            disabled={false}
                                            value={
                                              textFieldValues[`textarea-${i + 1}.${j + 1}`] || ''
                                            }
                                            onChange={(event) =>
                                              handleChangeJustify(
                                                event,
                                                y.title,
                                                `${i + 1}.${j + 1}`,
                                              )
                                            }
                                            minRows={4}
                                            inputProps={{
                                              disableUnderline: true,
                                              maxLength: 200,
                                            }}
                                            fullWidth
                                          />
                                        </SPDTextareaDiv>
                                      </Hidden>
                                    </>
                                  )}
                                  <div>{y.value}</div>
                                </SPDValueDiv>
                              </>
                            );
                          } else {
                            return '';
                          }
                        })
                      : ''}
                  </>
                );
              })}
            </Frame26085668>
          </Frame26085750>
          <Frame26085700>
            <List>{renderMenuItems(categories)}</List>
            <SPDStyledButtonContained
              fullWidth
              variant='contained'
              size='small'
              aria-label='baixar-pdf'
              onClick={() => {
                saveReview();
              }}
              sx={{ padding: '8px' }}
            >
              <Check2 size={20} style={{ marginRight: '10px' }} />
              Salvar Revisão
            </SPDStyledButtonContained>
            <SPDStyledButtonContained
              fullWidth
              variant='contained'
              size='small'
              aria-label='baixar-pdf'
              onClick={() => {
                alert('Em Breve');
              }}
              sx={{ padding: '8px' }}
            >
              Submeter Revisão
            </SPDStyledButtonContained>
          </Frame26085700>
        </Frame26085664>
      </DocumentosDocsDaPropostaDefaut>
      <AlertCustom open={openAlert} severity={severityAlert} message={messageAlert} />
    </>
  );
}
export default SubmissaoPropostaRevisao;
