import { rankWith, uiTypeIs } from '@jsonforms/core';
import { useEffect, useMemo } from 'react';
import { IControlElement } from '../uischema';
import { withJsonFormsCustomProps } from '../with-jsonforms-custom-props';
import { useCrudContext } from '../../components/crud/crud-context';

const colorPickerTester = rankWith(5, uiTypeIs('Constant'));

export const ConstantRender = {
  tester: colorPickerTester,
  renderer: withJsonFormsCustomProps(({ data, visible, path, handleChange, ...props }) => {
    const uischema = props.uischema as IControlElement;
    const ctx = useCrudContext();
    const { crudStates } = ctx;
    const visibleHandler = uischema?.options?.visibleHandler;

    const isVisible = useMemo(() => {
      const visibility = visibleHandler ? visibleHandler?.(ctx, null, null) : true;
      return visible && visibility;
    }, [visible, crudStates, visibleHandler]);

    useEffect(() => {
      if (isVisible) {
        const value = uischema?.options?.value;
        handleChange(path, value);
      }
    }, [uischema, isVisible]);

    return null;
  }),
};
