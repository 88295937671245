import { useEffect, useMemo, useState } from 'react';
import {
  StyledListItem,
  StyledList,
  PropostaTitle,
  Description,
  Status,
  Termino,
  AvisosIcon,
  TitleContainer,
  DateDisplay,
  ComponentBox,
  HeaderTodas,
  Search,
  WidthFull,
} from './style/avaliacoes-home';
import { useCrudContext } from '../../../components/crud/crud-context';
import { makePropostaConviteConsultorApi } from '../../../api/proposta-convite-consultor-api';
import { PropostaConviteConsultor, TipoSituacao } from '../../../models/proposta';
import dayjs from 'dayjs';
import { SearchInput } from '../../../components/search-input/search-input';
import { getStatusProps } from './utils/lista-avaliacoes.util';
import { CrudStatesOptions } from '../../../jsonforms/uischema';
import { useNavigate } from 'react-router-dom';

function TodosConvites() {
  const { setCurrentTitle, parameterFilters, setParameterFilters } = useCrudContext();
  const navigate = useNavigate();
  setCurrentTitle('Avaliacões');
  const [searchInput, setSearchInput] = useState<string>('');
  const api = useMemo(() => makePropostaConviteConsultorApi(), []);
  const [avaliacoes, setAvaliacoes] = useState<PropostaConviteConsultor[]>([]);

  useEffect(() => {
    const load = async () => {
      const result = await api.getAllReceived({ take: 1000 });
      const data = result.data.filter((item) => item.situacao !== TipoSituacao.pendente);
      setAvaliacoes(data);
    };

    load();
  }, []);

  const handleSearchChange = (event: any) => {
    setSearchInput(event.target.value);
  };

  const filteredData = avaliacoes.filter((item) =>
    item.proposta.tituloDoProjeto.toLowerCase().includes(searchInput.toLowerCase()),
  );

  const handleNavigate = (id: number, situacao: string) => {
    if (!id) return;

    if (situacao === TipoSituacao.aceito) {
      setParameterFilters({
        ...parameterFilters,
        propostaConviteConsultorId: id,
      });
      navigate(`/avaliacao-consultor`, {
        state: { crudState: CrudStatesOptions.ADD },
      });
    } else if (situacao === TipoSituacao.avaliado) {
      setParameterFilters({
        ...parameterFilters,
        propostaConviteConsultorId: id,
      });
      navigate(`/avaliacao-consultor/${id}`, {
        state: { crudState: CrudStatesOptions.VIEW },
      });
    }
  };

  return (
    <ComponentBox>
      <HeaderTodas>
        <h3>Avaliações</h3>
      </HeaderTodas>
      <Search>
        <SearchInput
          value={searchInput}
          onChange={handleSearchChange}
          placeholder='Pesquisar avaliações...'
        />
      </Search>
      <WidthFull>
        <StyledList dense={true}>
          {filteredData.map((item, index) => (
            <StyledListItem key={index} onClick={() => handleNavigate(item.id, item.situacao)}>
              <TitleContainer>
                <PropostaTitle>{item.proposta.tituloDoProjeto}</PropostaTitle>
              </TitleContainer>
              <Description>
                <Termino>
                  <AvisosIcon />
                  Data de Témino:{' '}
                  <DateDisplay>
                    {dayjs.utc(item.formularioConsultoria.dataTermino).format('DD/MM/YYYY')}
                  </DateDisplay>
                </Termino>
                <Status
                  {...getStatusProps(item.situacao, item.formularioConsultoria.dataTermino)}
                />
              </Description>
            </StyledListItem>
          ))}
        </StyledList>
      </WidthFull>
    </ComponentBox>
  );
}

export default TodosConvites;
