import { ControlProps, OwnPropsOfControl } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { isNil } from 'lodash';
import { ComponentType } from 'react';

export const withJsonFormsCustomProps = (
  Component: ComponentType<ControlProps>,
): ComponentType<OwnPropsOfControl> =>
  withJsonFormsControlProps((props) => {
    let {
      path,
      data,
      handleChange,
      uischema: { options },
    } = props;

    if (!isNil(options?.arrayProps)) {
      const { arrayIndex, arrayScope } = options.arrayProps;

      handleChange = (path: string, value: any) => {
        const updatedData = [...(props.data ?? [])];
        const indexConfig =
          (typeof arrayIndex === 'function' ? props.data?.findIndex(arrayIndex) : arrayIndex) ?? 0;
        const index = indexConfig < 0 ? updatedData.length : indexConfig;

        updatedData[index] = { ...updatedData[index], [arrayScope]: value };
        props.handleChange(path, updatedData);
      };

      const indexConfig =
        (typeof arrayIndex === 'function' ? data?.findIndex(arrayIndex) : arrayIndex) ?? 0;
      if (indexConfig >= 0) data = props.data?.[indexConfig]?.[arrayScope];
      else data = undefined;
    }

    return <Component {...props} path={path} data={data} handleChange={handleChange} />;
  });
