import { JsonSchema } from '@jsonforms/core';
import { GenericApi } from '../../../api/generic-api';
import client from '../../../config/axios-config';
import { AvaliacaoConsultor } from '../../../models/avaliacao-consultor';

export class ResultadoConsultoriaApi extends GenericApi<AvaliacaoConsultor> {
  constructor(public readonly propostaConviteConsultorId: number) {
    super('/avaliacao-consultor');
  }

  async getSchema(): Promise<JsonSchema | undefined> {
    try {
      const response = await client.get<JsonSchema>(
        `${this.url}/schema?propostaConviteConsultorId=${this.propostaConviteConsultorId}`,
      );
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('GetSchema error: ', { cause: error });
    }
  }
}

export const makeResultadoConsultoria = (propostaConviteConsultorId: number) => {
  return new ResultadoConsultoriaApi(propostaConviteConsultorId);
};
