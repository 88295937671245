import { FormularioConsultoriaCategorizado } from '../models/formulario-consultoria-categorizado';
import { FormularioEnquadramentoCategorizado } from '../models/formulario-enquadramento-categorizado';
import { PerguntaAvaliacao, TipoRespostaPerguntaAvaliacao } from '../models/pergunta-avaliacao';

export const buildSingleDefaultFormula = (perguntas: PerguntaAvaliacao[]) => {
  if (!perguntas || perguntas.length === 0) return;

  const defaultFormula = perguntas
    .map((pergunta, index) =>
      pergunta.tipoResposta === TipoRespostaPerguntaAvaliacao.nota ? `P${index + 1}` : null,
    )
    .filter((item) => item !== null);

  return `(${defaultFormula.join(' + ')}) / ${defaultFormula.length}`;
};

export const buildCategorizedDefaultFormula = (
  perguntas: FormularioEnquadramentoCategorizado[] | FormularioConsultoriaCategorizado[],
) => {
  if (!perguntas || perguntas.length === 0) return;

  const formulaParts: string[] = [];

  perguntas.forEach(
    (
      categoria: FormularioEnquadramentoCategorizado | FormularioConsultoriaCategorizado,
      categoriaIndex: number,
    ) => {
      const categoriaPerguntas =
        'formularioConsultoriaCategorizadoPerguntaAvaliacao' in categoria
          ? categoria.formularioConsultoriaCategorizadoPerguntaAvaliacao
          : categoria.formularioEnquadramentoCategorizadoPerguntaAvaliacao;

      if (!categoriaPerguntas) return;

      categoriaPerguntas.forEach((pergunta: PerguntaAvaliacao, perguntaIndex: number) => {
        if (pergunta.tipoResposta === TipoRespostaPerguntaAvaliacao.nota) {
          formulaParts.push(`P${categoriaIndex + 1}.${perguntaIndex + 1}`);
        }
      });
    },
  );

  if (formulaParts.length === 0) return;

  return `(${formulaParts.join(' + ')}) / ${formulaParts.length}`;
};
