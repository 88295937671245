import { useParams } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { AvaliacaoProposta as AvaliacaoPropostaModel } from '../../../models/avaliacao-proposta';
import { AvaliacaoEnquadramentoApi, makeAvaliacaoEnquadramento } from './avaliacao-proposta-api';
import { headCells } from './avaliacao-proposta.headcell';
import { useEffect, useState } from 'react';
import { Secure } from '../../../components/auth/secure';
import { Tooltip } from '@mui/material';
import { useCrudContext } from '../../../components/crud/crud-context';
import { AddButton } from '../../../components/crud/style/data-table.styled';
import { CrudStatesOptions } from '../../../jsonforms/uischema';
import AddIcon from '@mui/icons-material/Add';
import { moduleNameFor } from '../../../helpers/module-name-for';

function AvaliacaoProposta() {
  const { setFormData, updateCrudStates } = useCrudContext();
  const { gerenciarFormulariosId, avaliacaoDeEnquadramentoId } = useParams();
  const formularioEnquadramentoId = Number(gerenciarFormulariosId);
  const propostaId = Number(avaliacaoDeEnquadramentoId);

  const [api, setApi] = useState<AvaliacaoEnquadramentoApi>(() =>
    makeAvaliacaoEnquadramento(formularioEnquadramentoId, propostaId),
  );

  useEffect(() => {
    if (formularioEnquadramentoId && propostaId && !api)
      setApi(makeAvaliacaoEnquadramento(formularioEnquadramentoId, propostaId));
  }, []);

  const defaultForm = {
    propostaId: propostaId,
  };

  const customTableHeader = (
    <Secure hasPermission={'AVALIAR'} moduleName={moduleNameFor('avaliacao-proposta')}>
      <Tooltip title='Adicionar' arrow>
        <AddButton
          onClick={() => {
            updateCrudStates(CrudStatesOptions.ADD);
            setFormData(defaultForm);
          }}
        >
          <AddIcon></AddIcon>
          {'Adicionar'}
        </AddButton>
      </Tooltip>
    </Secure>
  );

  return (
    <Crud<AvaliacaoPropostaModel>
      headCells={headCells}
      titleConfig={{ value: 'Avaliação de Proposta', show: true }}
      apiUrl={'/avaliacao-proposta'}
      customTableHeader={customTableHeader}
      defaultForm={defaultForm}
      apiClient={api}
      hideDelete
      hideUpdate
    ></Crud>
  );
}

export const rootViews = [
  'formulario-enquadramento',
  'gerenciar-formularios',
  'avaliacao-de-enquadramento',
];

export default AvaliacaoProposta;
