import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { useCrudContext } from '../../components/crud/crud-context';
import { HeaderModel, RowsModel, SubtitleModel } from '../uischema';
import {
  StyledTableCell,
  StyledTableContainer,
  StyledTableData,
  StyledTableHeader,
  StyledTableSubTitle,
  StyledTableTitle,
} from './styled/custom-multi-table-view';

const tableRenderTester = rankWith(5, uiTypeIs('CustomMultiTableView'));

export const MultiTableRenderer = {
  tester: tableRenderTester,
  renderer: withJsonFormsControlProps(({ visible, uischema }) => {
    const theme = useTheme();
    const [tables, setTables] = useState<
      { title: string; headers: HeaderModel[]; rows: RowsModel[]; subtitle: SubtitleModel[] }[]
    >([]);
    const ctx = useCrudContext();
    const { formData } = ctx;
    const { tablesOf } = uischema.options || {};

    useEffect(() => {
      if (typeof tablesOf === 'function') {
        const tablesData = tablesOf(formData);
        setTables(tablesData);
      }
    }, [formData, tablesOf]);

    return (
      <div hidden={!visible}>
        {tables.map((table, index) => (
          <Paper key={index}>
            <StyledTableContainer>
              <Table>
                <StyledTableHeader>
                  {table.title && (
                    <TableRow>
                      <StyledTableTitle
                        colSpan={table.headers.reduce((sum, header) => sum + header.colspan, 0)}
                        key={table.title}
                        theme={theme}
                      >
                        {table.title}
                      </StyledTableTitle>
                    </TableRow>
                  )}

                  {table.subtitle.length > 0 && (
                    <TableRow>
                      {table.subtitle.map((subtitle, subIndex) => (
                        <StyledTableSubTitle
                          key={`subtitle-${subIndex}`}
                          colSpan={subtitle.colspan}
                          sx={subtitle?.customSx}
                          theme={theme}
                        >
                          {subtitle.texto}
                        </StyledTableSubTitle>
                      ))}
                    </TableRow>
                  )}

                  <TableRow>
                    {table.headers.map((header, headerIndex) => (
                      <StyledTableCell
                        key={headerIndex}
                        colSpan={header.colspan}
                        sx={header?.customSx}
                        theme={theme}
                      >
                        {header.texto}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </StyledTableHeader>
                <TableBody>
                  {table?.rows?.map((row, rowIndex) => (
                    <TableRow key={rowIndex} sx={row.customSx}>
                      {table.headers.map((header, cellIndex) => {
                        const cellData = row.value.find((cell) => cell.header === header.value);
                        return (
                          <StyledTableData
                            key={cellIndex}
                            sx={cellData?.customSx || {}}
                            theme={theme}
                          >
                            {cellData?.textValue || ''}
                          </StyledTableData>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Paper>
        ))}
      </div>
    );
  }),
};
