import { JsonSchema } from '@jsonforms/core';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormApi, makeApi } from '../../../api/generic-api';
import { useCrudContext } from '../../../components/crud/crud-context';
import JsonForm from '../../../components/crud/json-form';
import Loading from '../../../components/crud/loading';
import { TitleField } from '../../../components/crud/style/crud.styled';
import { CrudStatesOptions } from '../../../jsonforms/uischema';
import { Contratacao as ContratacaoModel } from '../../../models/contratacao';
import contratacaoUiSchema from './contratacao.uischema';
import AlertCustom from '../../../components/crud/alert-custom';

function Contratacao() {
  const {
    setFormData,
    formData,
    setCurrentTitle,
    updateCrudStates,
    setErrorsJsonForm,
    openAlert,
    severityAlert,
    messageAlert,
  } = useCrudContext();

  const { propostaId } = useParams();
  const propostaIdNumber = Number(propostaId);

  const [apiClient, setApiClient] = useState<FormApi<ContratacaoModel>>();
  const [schema, setSchema] = useState<JsonSchema>({});
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const api = makeApi('/contratacao');
    setApiClient(api);
    setCurrentTitle('Contratação');
  }, []);

  useEffect(() => {
    setLoad(true);
    apiClient
      ?.getSchema?.()
      .then((schema) => {
        schema && setSchema(schema);
        setTimeout(() => setLoad(false), 200);
      })
      .catch((err) => {
        console.error(err);
        setLoad(false);
      });
    fetchData();
  }, [apiClient]);

  const fetchData = useCallback(async () => {
    try {
      const result = await apiClient?.getAllForPagination({ propostaId: propostaIdNumber });
      if (result?.data?.length > 0) {
        setFormData(result?.data[0]);
        updateCrudStates(CrudStatesOptions.EDIT);
      } else {
        setFormData({ propostaId: propostaIdNumber });
        updateCrudStates(CrudStatesOptions.ADD);
      }
    } catch (err) {
      console.error(err);
    }
  }, [apiClient]);

  return (
    <>
      <TitleField>Contratação</TitleField>
      {load && <Loading isLoading={load} />}
      {!load && (
        <JsonForm
          data={formData}
          schema={schema}
          uischema={contratacaoUiSchema}
          onChange={({ errors, data }) => {
            setFormData(data);
            setErrorsJsonForm(errors);
          }}
        />
      )}
      {<AlertCustom open={openAlert} severity={severityAlert} message={messageAlert} />}
    </>
  );
}

export const rootViews = ['proposta'];

export default Contratacao;
