import { headCells } from './formulario-consultoria-edital.headcell';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Settings } from '../../../../components/styled-icons';
import { moduleNameFor } from '../../../../helpers/module-name-for';
import { Edital } from '../../../../models/edital';
import { GenericApi, makeApi } from '../../../../api/generic-api';
import { Crud } from '../../../../components/crud/crud';

function FormularioConsultoriaEditalView() {
  const navigate = useNavigate();
  const [api, setApi] = useState<GenericApi<Edital>>(() =>
    makeApi<Edital>('/edital', { customPaths: { getAll: '/private' } }),
  );

  const customActions = [
    (row: Edital) => ({
      hasPermission: ['EXIBIR_NO_MENU', 'BUSCAR_TODOS'],
      moduleName: moduleNameFor('formulario-consultoria-edital'),
      icon: <Settings size='24' color='#009900' />,
      title: 'Gerenciar Formulários',
      handler: (row: Edital) => {
        navigate(`${row?.id}/formulario-consultoria-gerenciar`);
      },
    }),
  ];

  useEffect(() => {
    if (!api) {
      setApi(
        makeApi<Edital>('/edital', {
          customPaths: { getAll: '/private' },
        }),
      );
    }
  }, [api]);

  return (
    <Crud<Edital>
      headCells={headCells}
      titleConfig={{ value: 'Fomulário de Consultoria', show: true }}
      customActions={customActions}
      queryFilters={{ orderBy: 'createdAt', orderDirection: 'DESC' }}
      apiUrl={'/formulario-consultoria-edital'}
      apiClient={api}
      hideView
      hideDelete
      hideCreate
      hideUpdate
    ></Crud>
  );
}

export default FormularioConsultoriaEditalView;
