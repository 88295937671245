import { Rule } from '@jsonforms/core';
import { IUiSchema } from '../../../jsonforms/uischema';

const AvaliacaoConsultorUiSchema: IUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/descricaoRespostaObj',
    },
    {
      type: 'Control',
      scope: '#/properties/conviteId',
      rule: {
        effect: 'HIDE',
        condition: {},
      } as Rule,
    },
  ],
};

export default AvaliacaoConsultorUiSchema;
