import { ControlProps, rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useCallback, useEffect, useState } from 'react';
import { GenericApi, makeApi } from '../../api/generic-api';
import { useCrudContext } from '../../components/crud/crud-context';
import { IControlElement } from '../uischema';
import './renderer.css';
import {
  CustomSelectProps,
  FilterProps,
  SelectDefault,
  SelectForeignEnum,
  SelectOneOf,
  disableHandler,
  displayPropertiesComputed,
  filterHandler,
  loadEnumDefault,
  loadSavedItem,
} from './select';

const selectTester = rankWith(6, uiTypeIs('Select'));

export const SelectManager = (props: ControlProps) => {
  const { visible = true, schema, path, enabled, data, errors } = props;

  const [api, setApi] = useState<GenericApi<any> | null>(null);
  const [apiListData, setApiListData] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [options, setOptions] = useState<any>([]);
  const [lastFilterValues, setLastFilterValues] = useState<any>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [oneOf, setOneOf] = useState<any>(null);
  const ctx = useCrudContext();
  const { formData, crudStates, disabledFields } = ctx;

  const uischema = props?.uischema as IControlElement;
  const {
    oneOf: customOneOf,
    apiInstance: customApiInstance,
    visibleHandler,
    formFields,
  } = uischema?.options ?? {};

  const apiUrl = (schema as any)?.foreignRoute ?? uischema?.options?.route;
  const foreignEnum = (schema as any)?.foreignEnum;
  const multiple = !!uischema?.options?.selectMultiple;

  const isDisabled = useCallback(
    () =>
      disabled ||
      !enabled ||
      crudStates.view ||
      uischema?.options?.disabled ||
      (crudStates.edit && uischema?.options?.onlyCreate) ||
      disabledFields.includes(path),
    [path, disabled, enabled, crudStates, uischema, disabledFields],
  );

  const isVisible = useCallback(() => {
    const visibility = visibleHandler ? visibleHandler?.(ctx, apiListData, lastFilterValues) : true;
    return visible && visibility;
  }, [visible, crudStates, visibleHandler, apiListData, lastFilterValues]);

  const customProps: () => CustomSelectProps = useCallback(
    () => ({
      ...props,
      oneOf,
      multiple,
      isVisible: isVisible(),
      isDisabled: isDisabled(),
      selectedItem,
      setSelectedItem,
      apiUrl,
      options,
    }),
    [path, data, selectedItem, options, isDisabled, isVisible, errors],
  );

  const filterProps: () => FilterProps = useCallback(
    () => ({
      uischema,
      customProps: customProps(),
      path,
      ctx,
      apiListData,
      setApiListData,
      lastFilterValues,
      setLastFilterValues,
      api,
      apiUrl,
      setSelectedItem,
    }),
    [path, lastFilterValues, apiListData, selectedItem, ctx, api, data],
  );

  useEffect(() => {
    setOneOf(customOneOf?.(formData, path) ?? (schema as any)?.oneOf);
  }, [schema, uischema, ...(formFields?.(formData, path) ?? [])]);

  useEffect(() => {
    if (customApiInstance) {
      setApi(customApiInstance);
    } else if (!api && apiUrl) {
      setApi(makeApi(apiUrl));
    }
  }, [api, apiUrl]);

  useEffect(() => {
    const filter = uischema?.options?.filter;
    const hasFormField = filter?.formFields ? true : false;
    const selfContainedApi = filter?.selfContainedApi ?? false;
    if (!filter) {
      api?.getAll?.().then((dataFromApi: any) => {
        setApiListData(dataFromApi);
        !multiple &&
          loadSavedItem(uischema, customProps(), dataFromApi, setSelectedItem, formData, apiUrl);
        setTimeout(() => loadEnumDefault(dataFromApi, customProps(), ctx, schema), 100);
      });
    } else if ((api || selfContainedApi) && !hasFormField) {
      const { handler } = filter;
      handler?.(formData, apiListData, api, undefined, path)?.then?.((dataFromApi: any) => {
        setApiListData(dataFromApi);
      });
      filterHandler(filterProps());
    } else if (api) {
      filterHandler(filterProps());
    }
  }, [api, path]);

  useEffect(() => {
    const targetFieldValue = uischema?.options?.targetFieldValue ?? 'id';

    let options = [];
    if (Array.isArray(oneOf)) {
      options = oneOf.map((option) => ({
        label: `${option?.title}`,
        value: `${option?.const}`,
      }));
    } else {
      options = apiListData?.map?.((item: any) => ({
        label: displayPropertiesComputed(uischema, item, apiUrl),
        value: item?.[targetFieldValue],
        codigo: item?.codigo ?? '',
        data: item,
      }));
    }
    disableHandler(uischema, ctx, apiListData, setDisabled);
    setOptions(options);
  }, [apiListData, oneOf]);

  // Carrega o item selecionado para o select default e foreignEnum
  useEffect(() => {
    const targetFieldValue = uischema?.options?.targetFieldValue ?? 'id';
    const { optionsComparator: comparator, filter: { acceptUnmatched = false } = {} } =
      uischema?.options ?? {};

    if (!multiple && props.data && apiListData?.length) {
      const item = apiListData?.find?.((item: any) => {
        const isMatch = comparator
          ? comparator?.(item, props?.data)
          : item?.id === Number(props?.data);

        return isMatch;
      });
      if (item) {
        const option = {
          label: displayPropertiesComputed(uischema, item, apiUrl),
          value: item?.[targetFieldValue],
          codigo: item.codigo,
          data: item,
        };
        setSelectedItem(option);
      } else if (acceptUnmatched) {
        setSelectedItem({ label: props.data, value: props.data });
      } else setSelectedItem(null);
    }

    if (!props.data && !!selectedItem) setSelectedItem(null);
  }, [props.data, apiListData]);

  useEffect(() => {
    if (selectedItem && typeof selectedItem === 'object') {
      let foreignEnum;
      if ('codigo' in selectedItem && (foreignEnum = (schema as any)?.foreignEnum)) {
        const [codeKey] =
          Object.entries(foreignEnum).find(([, value]) => value === selectedItem.codigo) ?? [];
        setTimeout(() => props.handleChange(`${path}-codigo`, codeKey), 200);
      }
    }
  }, [selectedItem]);

  useEffect(() => {
    const selfContainedApi = uischema?.options?.filter?.selfContainedApi ?? false;
    if (!(crudStates.edit || crudStates.view || crudStates.add) || !(api || selfContainedApi))
      return;
    filterHandler(filterProps());
  }, [api, data, crudStates, apiListData, selectedItem, lastFilterValues, formData]);
  if (oneOf) return <SelectOneOf {...customProps()} />;
  if (foreignEnum) return <SelectForeignEnum {...customProps()} />;
  return <SelectDefault {...customProps()} />;
};

export const selectRender = {
  tester: selectTester,
  renderer: withJsonFormsControlProps((props: ControlProps) => SelectManager(props)),
};
