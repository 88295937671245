import { useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import { withJsonFormsLabelProps } from '@jsonforms/react';
import { parseMathTree } from '../../helpers/math-tree/parser';
import { useCrudContext } from '../../components/crud/crud-context';
import { ControlProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core';
import { StyledBox, StyledError, StyledSubtitle, StyledTitle } from './styled/descritiva';
import { PerguntaAvaliacao, TipoRespostaPerguntaAvaliacao } from '../../models/pergunta-avaliacao';
import { FormularioEnquadramentoCategorizado } from '../../models/formulario-enquadramento-categorizado';
import {
  buildCategorizedDefaultFormula,
  buildSingleDefaultFormula,
} from '../../utils/formulario-render.util';
import { FormularioConsultoriaCategorizado } from '../../models/formulario-consultoria-categorizado';

interface MinMax {
  minimo: number;
  maximo: number;
}

interface Category {
  id?: number;
  tituloCategoria: string;
  perguntas: PerguntaAvaliacao[];
}

const FormularioNotaRenderComponent = (props: ControlProps) => {
  const decimals = 1;
  const error = undefined;
  const { formData, setFormData } = useCrudContext();

  const [values, setValues] = useState<{ [key: string]: number }>({});
  const [perguntas, setPerguntas] = useState<{ [key: string]: PerguntaAvaliacao }>({});
  const [categories, setCategories] = useState<Category[]>([]); // New state for categories

  const [localErrors, setLocalErrors] = useState<{ [key: string]: string }>({});

  const isCategorized = useMemo(
    () => !!formData.perguntasCategorizadas,
    [formData.perguntasCategorizadas],
  );

  useEffect(() => {
    if (!formData.perguntasCategorizadas) {
      setCategories([]); // Clear categories if not categorized
      const updatedPerguntas =
        formData.formularioEnquadramentoPergunta || formData.formularioConsultoriaPergunta;

      if (updatedPerguntas && updatedPerguntas.length > 0) {
        const perguntasMap: { [key: string]: PerguntaAvaliacao } = {};

        updatedPerguntas.forEach((pergunta: PerguntaAvaliacao, index: number) => {
          perguntasMap[`P${index + 1}`] = pergunta;
        });

        setPerguntas(perguntasMap);

        if (!formData.possuiFormulaPersonalizada) {
          const defaultFormula = buildSingleDefaultFormula(updatedPerguntas);

          if (formData.formula !== defaultFormula) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              formula: defaultFormula,
            }));
          }
        }
      } else {
        setPerguntas({});
        setFormData((prevFormData) => ({
          ...prevFormData,
          formula: '',
        }));
      }
    }
  }, [
    formData.perguntasCategorizadas,
    formData.formularioEnquadramentoPergunta,
    formData.formularioConsultoriaPergunta,
    formData.possuiFormulaPersonalizada,
    setFormData,
  ]);

  useEffect(() => {
    if (formData.perguntasCategorizadas) {
      const categoriesData =
        formData.formularioEnquadramentoCategorizado || formData.formularioConsultoriaCategorizado;

      if (categoriesData && categoriesData.length > 0) {
        const categorias: Category[] = categoriesData.map(
          (categoria: FormularioEnquadramentoCategorizado & FormularioConsultoriaCategorizado) => ({
            id: categoria.id,
            tituloCategoria: categoria.tituloCategoria,
            perguntas:
              categoria.formularioEnquadramentoCategorizadoPerguntaAvaliacao ||
              categoria.formularioConsultoriaCategorizadoPerguntaAvaliacao ||
              [],
          }),
        );

        setCategories(categorias);

        // Optionally, build a flat perguntas map if needed elsewhere
        const perguntasMap: { [key: string]: PerguntaAvaliacao } = {};
        categorias.forEach((categoria, catIndex) => {
          categoria.perguntas.forEach((pergunta, pergIndex) => {
            const key = `P${catIndex + 1}.${pergIndex + 1}`;
            perguntasMap[key] = pergunta;
          });
        });
        setPerguntas(perguntasMap);

        if (!formData.possuiFormulaPersonalizada) {
          const defaultFormula = buildCategorizedDefaultFormula(categoriesData);

          if (formData.formula !== defaultFormula) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              formula: defaultFormula,
            }));
          }
        }
      } else {
        setCategories([]);
        setPerguntas({});
        setFormData((prevFormData) => ({
          ...prevFormData,
          formula: '',
        }));
      }
    }
  }, [
    formData.perguntasCategorizadas,
    formData.formularioEnquadramentoCategorizado,
    formData.formularioConsultoriaCategorizado,
    formData.possuiFormulaPersonalizada,
    setFormData,
  ]);

  const updateTotalNota = (newValues?: { [key: string]: number }) => {
    let valuesAux = newValues || values;
    try {
      const formulaStr = formData.formula?.replace(/\s+/g, '') || '';
      const mathTree = parseMathTree(formulaStr);
      const newTotal = mathTree.solve(valuesAux);

      setFormData((prevFormData) => ({ ...prevFormData, totalFormula: newTotal }));
    } catch (e) {
      console.error(e);
      setFormData((prevFormData) => ({ ...prevFormData, totalFormula: 0 }));
    }
  };

  useEffect(() => {
    updateTotalNota();
  }, [formData.formula]);

  const lengthTitleLabel = (pergunta: PerguntaAvaliacao) => {
    const { notaMinima: minimo, notaMaxima: maximo } = pergunta.nota;

    if (minimo === 0 && maximo === 0) return '';
    else if (minimo >= 0 && maximo > 0) return `(Dê uma nota de ${minimo} a ${maximo})`;
    else if (minimo === 0 && maximo > 0) return `(Dê uma nota de até ${maximo})`;
    else if (minimo > 0 && maximo === 0) return `(Dê uma nota de ${minimo} ou mais)`;
    else return '';
  };

  const handleChangeNota = (value: string, pergunta: PerguntaAvaliacao, key: string) => {
    const numericValue = Number(value);
    const newValues = { ...values, [key]: numericValue };
    setValues(newValues);
    updateTotalNota(newValues);

    const length: MinMax = {
      minimo: pergunta.nota.notaMinima || 0,
      maximo: pergunta.nota.notaMaxima || 0,
    };

    let errorMessage = '';

    if (length.maximo && numericValue > length.maximo) {
      errorMessage = `Nota máxima permitida: ${length.maximo}`;
    } else if (length.minimo && numericValue < length.minimo) {
      errorMessage = `Nota mínima permitida: ${length.minimo}`;
    }

    const regex = new RegExp(`^\\d+(\\.\\d{1,${decimals}})?$`);
    if (!regex.test(value)) {
      errorMessage = `Insira um valor numérico com até ${decimals} casas decimais`;
    }

    setLocalErrors((prevErrors) => {
      if (errorMessage) {
        return { ...prevErrors, [key]: errorMessage };
      } else {
        const { [key]: removed, ...rest } = prevErrors;
        return rest;
      }
    });
  };

  const hasLength = (pergunta: PerguntaAvaliacao) => {
    return (pergunta.nota?.notaMinima || 0) > 0 || (pergunta.nota?.notaMaxima || 0) > 0;
  };

  return (
    <div>
      {isCategorized
        ? categories.map((categoria, catIndex) => (
            <div key={categoria.id || catIndex}>
              <StyledTitle error={{}} variant='h6'>
                {`${catIndex + 1}) ${categoria.tituloCategoria}`}
              </StyledTitle>
              {categoria.perguntas.map((pergunta, pergIndex) => {
                const key = `P${catIndex + 1}.${pergIndex + 1}`;
                if (pergunta.tipoResposta === TipoRespostaPerguntaAvaliacao.nota) {
                  return (
                    <StyledBox key={key}>
                      {pergunta.pergunta && (
                        <StyledTitle error={error}>
                          {`${key.replace('P', '')}) ${pergunta.pergunta}`}
                          {hasLength(pergunta) && (
                            <StyledSubtitle error={error} variant='caption'>
                              {' ' + lengthTitleLabel(pergunta)}
                            </StyledSubtitle>
                          )}
                        </StyledTitle>
                      )}

                      <TextField
                        {...props}
                        label=''
                        fullWidth
                        size='small'
                        type='number'
                        inputProps={{
                          step: Math.pow(10, -decimals),
                        }}
                        value={values[key] || ''}
                        onChange={(event) => handleChangeNota(event.target.value, pergunta, key)}
                      />

                      {localErrors?.[key] && (
                        <StyledError variant='caption'>{localErrors[key]}</StyledError>
                      )}
                    </StyledBox>
                  );
                } else {
                  return (
                    <StyledTitle
                      error={error}
                      key={key}
                    >{`${key.replace('P', '')}) ${pergunta.pergunta}`}</StyledTitle>
                  );
                }
              })}
            </div>
          ))
        : Object.entries(perguntas).map(([key, pergunta], index) => {
            if (pergunta.tipoResposta === TipoRespostaPerguntaAvaliacao.nota) {
              return (
                <StyledBox key={key}>
                  {pergunta.pergunta && (
                    <StyledTitle error={error}>
                      {`${key.replace('P', '')}) ${pergunta.pergunta}`}
                      {hasLength(pergunta) && (
                        <StyledSubtitle error={error} variant='caption'>
                          {' ' + lengthTitleLabel(pergunta)}
                        </StyledSubtitle>
                      )}
                    </StyledTitle>
                  )}

                  <TextField
                    {...props}
                    label=''
                    fullWidth
                    size='small'
                    type='number'
                    inputProps={{
                      step: Math.pow(10, -decimals),
                    }}
                    value={values[key] || ''}
                    onChange={(event) => handleChangeNota(event.target.value, pergunta, key)}
                  />

                  {localErrors?.[key] && (
                    <StyledError variant='caption'>{localErrors[key]}</StyledError>
                  )}
                </StyledBox>
              );
            } else {
              return (
                <StyledTitle
                  error={error}
                  key={key}
                >{`${key.replace('P', '')}) ${pergunta.pergunta}`}</StyledTitle>
              );
            }
          })}
    </div>
  );
};

const FormularioNotaRendererTester: RankedTester = rankWith(5, uiTypeIs('FormularioNota'));

export const FormularioNotaRender = {
  tester: FormularioNotaRendererTester,
  renderer: withJsonFormsLabelProps(FormularioNotaRenderComponent),
};
