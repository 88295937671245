import { CrudContextData, IFieldsConfig, IStepCallback } from '../../../jsonforms/uischema';

export const stepHandlerDescricao = async (
  fieldsConfig: IFieldsConfig,
  ctx: CrudContextData,
  data: any,
  url: string,
  callback?: IStepCallback,
): Promise<boolean | void> => {
  return true;
};
