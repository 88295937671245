import VisibilityIcon from '@mui/icons-material/Visibility';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeApi } from '../../../api/generic-api';
import { Crud } from '../../../components/crud/crud';
import { NoteEdit } from '../../../components/styled-icons';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { CrudStatesOptions } from '../../../jsonforms/uischema';
import { FormularioEnquadramento } from '../../../models/formulario-enquadramento';
import { headCells } from './gerenciar-formularios.headcell';
import formularioEnquadramentoUiSchema from './gerenciar-formularios.uischema';

function FormularioEnquadramentoView() {
  const navigate = useNavigate();
  const { formularioEnquadramentoId: editalId } = useParams();
  const editalIdNumber = Number(editalId);
  const defaultForm = {
    perguntasCategorizadas: false,
    editalId: editalIdNumber,
    possuiFormulaPersonalizada: false,
  };

  const api = useMemo(() => makeApi<FormularioEnquadramento>('/formulario-enquadramento'), []);

  const gerenciarFormularioDetalhes = {
    hasPermission: ['BUSCAR_POR_ID'],
    moduleName: moduleNameFor('formulario-enquadramento'),
    icon: <VisibilityIcon />,
    title: 'Visualizar',
    handler: (row: any) => {
      navigate(`/gerenciar-formulario-detalhes/${row?.id}`, {
        state: { crudState: CrudStatesOptions.EDIT },
      });
    },
  };

  const avaliacaoDeEnquadramento = {
    hasPermission: ['BUSCAR_POR_ID'],
    moduleName: moduleNameFor('avaliacao-de-enquadramento'),
    icon: <NoteEdit size='24' />,
    title: 'Avaliação de Enquadramento',
    handler: (row: any) => {
      navigate(
        `/formulario-enquadramento/${editalIdNumber}/gerenciar-formularios/${row.id}/avaliacao-de-enquadramento`,
      );
    },
  };

  return (
    <Crud<FormularioEnquadramento>
      headCells={headCells}
      titleConfig={{ value: 'Gerenciar Formulários', show: true }}
      queryFilters={{ withDeleted: true, editalId: Number(editalId) }}
      apiUrl={'/gerenciar-formularios'}
      uischema={formularioEnquadramentoUiSchema}
      apiClient={api}
      defaultForm={defaultForm}
      customSave={{ show: false }}
      hideView
      customActions={[() => gerenciarFormularioDetalhes, () => avaliacaoDeEnquadramento]}
    ></Crud>
  );
}

export default FormularioEnquadramentoView;

export const rootViews = ['formulario-enquadramento'];
