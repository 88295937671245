import { FormApi, makeApi } from '../../../api/generic-api';
import { CrudContextData } from '../../../jsonforms/uischema/uischema.type';
import { IUiSchema, showIfBrasil, hideIfBrasil } from '../../../jsonforms/uischema';
import { makeIbgeApi } from '../../../api/ibge.api';
import { Unidade } from '../../../models/unidade';
import { Condition, Rule, RuleEffect } from '@jsonforms/core';

const getCodigoPaisInstituicao = async (
  instituicao: any,
  updater: (path: string, value: any) => void,
) => {
  const instSchema = await makeApi('instituicao').getSchema();
  const paisIdSchema = instSchema?.properties?.paisId;
  const country = instituicao?.pais;
  let foreignEnum;
  if ('codigo' in country && (foreignEnum = (paisIdSchema as any)?.foreignEnum)) {
    const [codeKey] =
      Object.entries(foreignEnum).find(([, value]) => value === country.codigo) ?? [];
    setTimeout(() => updater('paisId-codigo', codeKey), 200);
  }
};

const apiInstanceIbge = makeIbgeApi();

const requiredIfBrasil = (ctx: CrudContextData) => {
  const { formData } = ctx;
  return formData['paisId-codigo'] === 'brasil';
};

const requiredIfNotBrasil = (ctx: CrudContextData) => {
  const { formData } = ctx;
  return formData['paisId-codigo'] !== 'brasil';
};

const requiredIfNotVinculo = (ctx: CrudContextData) => {
  const { formData } = ctx;
  return formData['vincularExistente'] ? !formData['vincularExistente'] : true;
};

const apiInstanceUnidade = makeApi('/unidade');

const showIfVincularExistente: Rule = {
  effect: RuleEffect.SHOW,
  condition: {
    scope: '#/properties/vincularExistente',
    schema: {
      const: true,
    },
  } as Condition,
};

const hideIfVincularExistente: Rule = {
  effect: RuleEffect.HIDE,
  condition: {
    scope: '#/properties/vincularExistente',
    schema: {
      const: true,
    },
  } as Condition,
};

const hideIfAcknowledgedAt: Rule = {
  effect: RuleEffect.HIDE,
  condition: {
    scope: '#/properties/acknowledgedAt',
    schema: {
      not: {
        const: null,
      },
    },
  } as Condition,
};

const unidadeUiSchema: IUiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'HorizontalLayout',
      rule: hideIfAcknowledgedAt,
      elements: [
        {
          type: 'Boolean',
          scope: '#/properties/vincularExistente',
          label: 'Vincular à uma unidade já reconhecida',
          options: {
            handleChange: (value: boolean, handler) => {
              if (!value) {
                handler('unidadeId', null);
                handler('endereco', null);
              } else {
                handler('endereco', {
                  cep: '00000-000',
                  estado: 'estado',
                  municipio: 'município',
                  logradouro: 'logradouro',
                });
              }
              handler('vincularExistente', value);
            },
          },
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Select',
          scope: '#/properties/unidadeId',
          label: 'Unidade para substituição',
          options: {
            apiInstance: apiInstanceUnidade,
            displayProperties: ['nome'],
            filter: {
              formFields: ['nome'],
              handler: (formData: Unidade, listData: any[], api: FormApi) => {
                return api.getAll({
                  withAcknowledgedAt: true,
                  instituicaoId: formData.instituicaoId,
                });
              },
            },
            required: !requiredIfNotVinculo,
          },
        },
      ],
      rule: showIfVincularExistente,
    },
    {
      type: 'VerticalLayout',
      rule: hideIfVincularExistente,
      elements: [
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Select',
              scope: '#/properties/instituicaoId',
              label: 'Instituição',
              options: {
                required: requiredIfNotVinculo,
                handleChange: getCodigoPaisInstituicao,
              },
            },
          ],
        },
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/nome',
                  label: 'Nome',
                  options: {
                    required: requiredIfNotVinculo,
                  },
                },
              ],
            },
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/sigla',
                  label: 'Sigla',
                  options: {
                    required: requiredIfNotVinculo,
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/cnpj',
              label: 'CNPJ',
              rule: showIfBrasil,
              options: {
                mask: '99.999.999/9999-99',
                required: requiredIfBrasil && requiredIfNotVinculo,
              },
            },
          ],
        },
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Cep',
                  scope: '#/properties/endereco/properties/cep',
                  label: 'CEP',
                  rule: showIfBrasil,
                  options: {
                    mask: '99999-999',
                    required: requiredIfBrasil && requiredIfNotVinculo,
                  },
                },
                {
                  type: 'Text',
                  scope: '#/properties/endereco/properties/cep',
                  label: 'ZipCode',
                  rule: hideIfBrasil,
                  options: {
                    maxLength: 9,
                    required: requiredIfNotBrasil && requiredIfNotVinculo,
                  },
                },
                {
                  type: 'Select',
                  scope: '#/properties/endereco/properties/municipio',
                  label: 'Município',
                  options: {
                    required: requiredIfNotVinculo,
                    targetFieldValue: 'nome',
                    optionsComparator: (option: any, item: string) => {
                      return option?.nome === item;
                    },
                    displayProperties: ['nome'],
                    filter: {
                      acceptUnmatched: true,
                      preventCleaning: true,
                      selfContainedApi: true,
                      formFields: ['estado', 'cep'],
                      handler: (
                        _formData: Unidade,
                        _listData: any[],
                        _api: FormApi,
                        queryFilterValues: any,
                      ) => {
                        if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                        return apiInstanceIbge.buscarMunicipioPorEstadoId(
                          queryFilterValues[0].value,
                        );
                      },
                    },
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/bairro',
                  label: 'Bairro',
                  rule: hideIfBrasil,
                  options: {
                    required: requiredIfNotBrasil && requiredIfNotVinculo,
                  },
                },
              ],
            },
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Select',
                  scope: '#/properties/endereco/properties/estado',
                  label: 'Estado/Região',
                  options: {
                    handleChange: (item, handler, formData, ctx, path) => {
                      handler(path, item);
                      handler(path.replace('estado', 'municipio'), undefined);
                    },
                    required: requiredIfNotVinculo,
                    targetFieldValue: 'sigla',
                    optionsComparator: (option: any, item: string) => {
                      return option?.sigla === item;
                    },
                    displayProperties: ['nome'],
                    filter: {
                      acceptUnmatched: true,
                      preventCleaning: true,
                      selfContainedApi: true,
                      formFields: ['pais', 'cep'],
                      handler: () => {
                        return apiInstanceIbge.buscarEstados();
                      },
                    },
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/logradouro',
                  options: {
                    required: requiredIfNotVinculo,
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/complemento',
                  rule: hideIfBrasil,
                },
              ],
            },
          ],
        },
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/endereco/properties/bairro',
                  label: 'Bairro',
                  rule: showIfBrasil,
                  options: {
                    required: requiredIfNotVinculo,
                  },
                },
              ],
            },
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'Control',
                          scope: '#/properties/endereco/properties/numero',
                          label: 'Número',
                          rule: showIfBrasil,
                          options: {
                            required: requiredIfBrasil && requiredIfNotVinculo,
                          },
                        },
                      ],
                    },
                    {
                      type: 'VerticalLayout',
                      elements: [
                        {
                          type: 'Control',
                          scope: '#/properties/endereco/properties/complemento',
                          rule: showIfBrasil,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default unidadeUiSchema;
