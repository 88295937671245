import React, { useMemo } from 'react';
import { rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useEffect, useState } from 'react';
import { Trash3 } from 'styled-icons/bootstrap';
import { MailArrowUp } from 'styled-icons/fluentui-system-filled';
import { makeApi } from '../../api/generic-api';
import { makePropostaConviteMembroApi } from '../../api/proposta-convite-membro-api';
import { useCrudContext } from '../../components/crud/crud-context';
import CustomMultipleUserSelect from '../../components/custom-multiple-user-select/custom-multiple-user-select';
import { moduleNameFor } from '../../helpers/module-name-for';
import {
  headCells,
  moduleLabel,
  moduleName,
} from '../../views/proposta/proposta-convite-membros/proposta-convite-membros.headcell';
import { Usuario } from '../../models/usuario';
import { PropostaConviteMembro, TipoSituacao } from '../../models/proposta';
import { TipoFuncaoParticipante } from '../../models/funcao-participante';
import Hidden from '@mui/material/Hidden';
import { Severity } from '../../components/crud/protocols/severity';

const CustomMultipleUserSelectTester = rankWith(5, uiTypeIs('CustomDataTableUserSelect'));

export const CustomMultipleUserSelectRenderer = {
  tester: CustomMultipleUserSelectTester,
  renderer: withJsonFormsControlProps(
    ({ schema, uischema, data, path, visible, enabled, ...props }) => {
      const { formData } = useCrudContext();

      const propostaId = formData.id;

      const apiSearchInstance = useMemo(() => makeApi<Usuario>('/usuario'), [makeApi]);
      const apiCreateInstance = makePropostaConviteMembroApi();

      const [apiListData, setApiListData] = useState<PropostaConviteMembro[]>();
      const [usuarios, setUsuarios] = useState<Usuario[]>([]);
      const [selectedUsuarios, setSelectedUsuarios] = useState<Usuario[]>([]);
      const [filters, setFilters] = useState<any>({ propostaId: propostaId });
      const [openAlert, setOpenAlert] = useState(false);
      const [severityAlert, setSeverityAlert] = useState<Severity>(Severity.SUCCESS);
      const [messageAlert, setMessageAlert] = useState<string>('Permissões salvas com sucesso!');
      const [page, setPage] = useState<number>(0);
      const [rowsPerPage, setRowsPerPage] = useState<number>(10);
      const [totalCount, setTotalCount] = useState<number>(10);

      const onTextChange = (searchText: string) => {
        setFilters({ propostaId: propostaId, nomeUsuario: searchText });
      };

      const onCreateParticipants = () => {
        setFilters({ propostaId: propostaId });
      };

      const onExecuteAction = async () => {
        try {
          const response = await apiCreateInstance
            ?.getAllForPagination?.({ ...filters, skip: page * rowsPerPage, take: rowsPerPage })
            .catch((e) => {
              showError(e?.cause?.response?.data?.message?.[0] || 'Ocorreu um erro.');
              return undefined;
            });

          if (response) {
            const data = Array.isArray(response) ? response : response.data;
            const count = Array.isArray(response) ? response.length : response.count;

            setApiListData(data as PropostaConviteMembro[]);
            setTotalCount(count);
          }
        } catch (error) {
          console.error(error);
        }
      };

      const showSuccess = (message: string) => {
        setMessageAlert(message);
        setSeverityAlert(Severity.SUCCESS);
        setOpenAlert(true);
        setTimeout(() => setOpenAlert(false), 3000);
      };

      const showError = (message: string) => {
        setMessageAlert(message);
        setSeverityAlert(Severity.ERROR);
        setOpenAlert(true);
        setTimeout(() => setOpenAlert(false), 3000);
      };

      const handleAdd = () => {
        const fetchData = async () => {
          const createData = selectedUsuarios.map((usuario: Usuario) => {
            return { propostaId: propostaId, usuarioId: usuario.id };
          });
          apiCreateInstance
            .post({ propostaConviteMembros: createData })
            .then(() => {
              showSuccess('Participante(s) adicionado(s) com sucesso!');
              onCreateParticipants();
              setSelectedUsuarios([]);
              setUsuarios([]);
            })
            .catch((e) => {
              showError(e?.cause?.response?.data?.message?.[0] || 'Ocorreu um erro.');
            });
        };
        fetchData();
      };

      useEffect(() => {
        onExecuteAction();
      }, [filters, page, rowsPerPage]);

      const removerMembro = {
        hasPermission: [],
        moduleName: moduleNameFor('convite-participantes'),
        icon: <Trash3 size={20} style={{ color: 'red' }} />,
        title: 'Remover Membro',
        handler: (row: PropostaConviteMembro) => {
          removerMembroFunc(row.id);
        },
      };

      const removerMembroFunc = async (id: number) => {
        apiCreateInstance
          .remover(id)
          .then(() => {
            showSuccess('Removido com Sucesso');
            onExecuteAction();
          })
          .catch((e) => {
            showError(e?.cause?.response?.data?.message?.[0] || 'Ocorreu um erro.');
          });
      };

      const showRemoverMembro = (row: PropostaConviteMembro) => {
        if (
          (row.situacao === TipoSituacao.pendente || row.situacao === TipoSituacao.aceito) &&
          enabled &&
          row?.funcaoParticipante?.codigo !== TipoFuncaoParticipante.coordenador
        )
          return removerMembro;
      };

      const reenviarConvite = {
        hasPermission: [],
        moduleName: moduleNameFor('convite-participantes'),
        icon: <MailArrowUp size={20} style={{ color: 'green' }} />,
        title: 'Reenviar Convite',
        handler: (row: PropostaConviteMembro) => {
          reenviarConviteFunc(row.id);
        },
      };

      const reenviarConviteFunc = async (id: number) => {
        apiCreateInstance
          .reenviar(id)
          .then(() => {
            showSuccess('Reenviado com Sucesso');
            onExecuteAction();
          })
          .catch((e) => {
            showError(e?.cause?.response?.data?.message?.[0] || 'Ocorreu um erro.');
          });
      };

      const showReenviarConvite = (row: PropostaConviteMembro) => {
        if (
          (row.situacao === TipoSituacao.recusado || row.situacao === TipoSituacao.removido) &&
          enabled &&
          row?.funcaoParticipante?.codigo !== TipoFuncaoParticipante.coordenador
        )
          return reenviarConvite;
      };

      return (
        <Hidden xsUp={!visible}>
          <CustomMultipleUserSelect
            enabled={enabled}
            hasSecure={false}
            handleAdd={handleAdd}
            headCells={headCells}
            moduleName={moduleName}
            totalCount={totalCount}
            moduleLabel={moduleLabel}
            apiListData={apiListData ?? []}
            pageState={{ page, setPage }}
            onTextChange={onTextChange}
            rowsPerPageOptions={[10, 20, 30]}
            apiSearchInstance={apiSearchInstance}
            usuariosState={{ usuarios, setUsuarios }}
            rowsPerPageState={{ rowsPerPage, setRowsPerPage }}
            alertCustom={{ openAlert, severityAlert, messageAlert }}
            customActions={[showRemoverMembro, showReenviarConvite]}
            selectedUsuariosState={{ selectedUsuarios, setSelectedUsuarios }}
            dialogProps={{
              openDialog: false,
              handleCloseDelete: () => {},
              destroy: async () => {},
            }}
          ></CustomMultipleUserSelect>
        </Hidden>
      );
    },
  ),
};
