import {
  CrudContextData,
  CrudStatesOptions,
  IFieldsConfig,
  IStepCallback,
} from '../jsonforms/uischema/uischema.type';

export const stepHandlerBack = async (
  fieldsConfig: IFieldsConfig | undefined,
  ctx: CrudContextData,
  data: any,
  url: string,
  callback?: IStepCallback,
): Promise<boolean | string> => {
  const { crudStates, updateCrudStates, showSuccess, clearForm } = ctx;

  clearForm();
  updateCrudStates(CrudStatesOptions.LIST);

  if (crudStates.add) {
    showSuccess('Adicionado com sucesso!');
  }
  if (crudStates.edit) {
    showSuccess('Editado com sucesso!');
  }
  return true;
};
