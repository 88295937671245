import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { AvaliacaoProposta } from '../../../models/avaliacao-proposta';

export const moduleName = 'AVALIACAO.FORMULARIO_ENQUADRAMENTO.FORMULARIO_ENQUADRAMENTO';

export const moduleLabel = 'Avaliações';

export const headCells: readonly HeadCell<AvaliacaoProposta>[] = [
  {
    id: 'criadoPorId',
    numeric: false,
    disablePadding: false,
    label: 'Nome do Avaliador',
    computedRowLabel: (row) => row?.criadoPor?.nome,
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Data da Avaliação',
    dateFormat: 'DD/MM/YYYY',
  },
];
