import { useMemo } from 'react';
import { makeApi } from '../../../../api/generic-api';
import { Crud } from '../../../../components/crud/crud';
import { Proposta } from '../../../../models/proposta';
import { headCells } from './formulario-consultoria-proposta.headcell';
import { useNavigate, useParams } from 'react-router-dom';
import { moduleNameFor } from '../../../../helpers/module-name-for';
import { MailOpenPerson } from '../../../../components/styled-icons';

function FormularioConsultoriaPropostaView() {
  const navigate = useNavigate();

  const { formularioConsultoriaEditalId: editalId } = useParams();

  const api = useMemo(() => makeApi<Proposta>('/proposta'), [makeApi]);

  const propostaConviteConsultor = {
    hasPermission: ['BUSCAR_POR_ID'],
    moduleName: moduleNameFor('formulario-consultoria-proposta'),
    icon: <MailOpenPerson size='24' />,
    title: 'Convidar Consultores',
    handler: (row: Proposta) => {
      navigate(`${row?.id}/formulario-consultoria-convite`);
    },
  };

  return (
    <Crud<Proposta>
      headCells={headCells}
      titleConfig={{ value: 'Propostas', show: false }}
      queryFilters={{ editalId: Number(editalId) }}
      apiUrl={'/formulario-consultoria-proposta'}
      apiClient={api}
      customSave={{ show: false }}
      hideView
      hideUpdate
      hideDelete
      hideCreate
      customActions={[() => propostaConviteConsultor]}
    ></Crud>
  );
}

export default FormularioConsultoriaPropostaView;

export const rootViews = ['formulario-consultoria-edital', 'formulario-consultoria-gerenciar'];
