import { Condition, Rule, RuleEffect } from '@jsonforms/core';
import { NavigateFunction } from 'react-router-dom';
import { FormApi } from '../../api/generic-api';
import { IbgeApi, makeIbgeApi } from '../../api/ibge.api';
import { circleFlagBuilder } from '../../components/circle-flag';
import { CrudContextData, IFieldsConfig, IUiSchema } from '../../jsonforms/uischema';
import { Instituicao } from '../../models/instituicao';
import { CodigoPais, Pais } from '../../models/pais';
import { Proposta } from '../../models/proposta';
import { createResource } from '../../utils/create-resource';
import { stepHandlerErrors } from '../../utils/step-handler-errors';
import { areaDeConhecimentoHandleChange } from '../editar-perfil/editar-perfil.uischema';
import {
  setLabelWhenChangeDateFuncao,
  setLabelWhenChangeDateServico,
} from '../editar-perfil/handlers/set-label-when-change-date';
import {
  AreaDeConhecimentoApi,
  makeAreaDeConhecimentoApi,
} from '../fundacao/area-de-conhecimento/area-de-conhecimento-api';
import { stepHandlerAbrangencia } from './handlers/step-handler-abrangencia';
import { stepHandlerDadosCoordenador } from './handlers/step-handler-dados-coordenador';
import { stepHandlerDescricao } from './handlers/step-handler-descricao';
import { stepHandlerIndicadores } from './handlers/step-handler-indicadores';
import { getOrcamentoPropostaUiSchema } from './orcamento-proposta.uischema';

const showIfPossuiVinculoInstitucional: Rule = {
  effect: RuleEffect.SHOW,
  condition: {
    scope: '#/properties/criadoPor/properties/possuiVinculoInstitucional',
    schema: {
      const: true,
    },
  } as Condition,
};

const showIfBrasilDadosCoordenador = {
  effect: 'SHOW',
  condition: {
    scope: '#/properties/criadoPor/properties/pais/properties/codigo',
    schema: {
      const: CodigoPais.brasil,
    },
  } as Condition,
} as Rule;

const hideIfBrasilDadosCoordenador = {
  effect: 'HIDE',
  condition: {
    scope: '#/properties/criadoPor/properties/pais/properties/codigo',
    schema: {
      const: CodigoPais.brasil,
    },
  } as Condition,
} as Rule;

const disableTituloDoEdital = {
  effect: 'DISABLE',
  condition: {},
} as Rule;

const visibleIfHasFilter = (ctx: CrudContextData, apiListData: any[], queryFilterValues: any[]) => {
  const filterValue = queryFilterValues?.[0]?.value;
  if (filterValue !== null && filterValue !== undefined) return true;
  return false;
};

const disableIfNoOptions = (ctx: CrudContextData, apiListData: any[]) => {
  if (apiListData.length === 0) return true;
  return false;
};

const apiInstanceAreaDeConhecimento = makeAreaDeConhecimentoApi();

const apiInstanceIbge = makeIbgeApi();

const showIfSugerirInstituicao: Rule = {
  effect: RuleEffect.SHOW,
  condition: {
    scope: '#/properties/criadoPor/properties/sugerirInstituicao',
    schema: {
      const: true,
    },
  } as Condition,
};

const hideIfSugerirInstituicao: Rule = {
  effect: RuleEffect.HIDE,
  condition: {
    scope: '#/properties/criadoPor/properties/sugerirInstituicao',
    schema: {
      const: true,
    },
  } as Condition,
};

const showIfSugerirUnidade: Rule = {
  effect: RuleEffect.SHOW,
  condition: {
    type: 'OR',
    conditions: [
      {
        scope: '#/properties/criadoPor/properties/sugerirInstituicao',
        schema: {
          const: true,
        },
      },
      {
        scope: '#/properties/criadoPor/properties/sugerirUnidade',
        schema: {
          const: true,
        },
      },
    ],
  } as Condition,
};

const hideIfSugerirUnidade: Rule = {
  effect: RuleEffect.HIDE,
  condition: {
    type: 'OR',
    conditions: [
      {
        scope: '#/properties/criadoPor/properties/sugerirInstituicao',
        schema: {
          const: true,
        },
      },
      {
        scope: '#/properties/criadoPor/properties/sugerirUnidade',
        schema: {
          const: true,
        },
      },
    ],
  } as Condition,
};

const getFuncaoLabelFromUsuario = (formData: Proposta): string => {
  return setLabelWhenChangeDateFuncao(formData.criadoPor);
};

const getServicoLabelFromUsuario = (formData: Proposta): string => {
  return setLabelWhenChangeDateServico(formData.criadoPor);
};

const propostaUiSchema: IUiSchema = {
  type: 'CategorizationMenu',
  elements: [
    {
      type: 'Category',
      label: 'Caracterização da Proposta',
      elements: [
        {
          type: 'Category',
          label: 'Informações Iniciais',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
              callback: (ctx: CrudContextData, fieldsConfig: IFieldsConfig) =>
                createResource(ctx, '/proposta', fieldsConfig),
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/editalId',
              rule: {
                effect: 'HIDE',
                condition: {},
              } as Rule,
            },
            {
              type: 'Control',
              scope: '#/properties/edital/properties/nome',
              label: 'Título do Edital',
              rule: disableTituloDoEdital,
            },
            {
              type: 'Control',
              scope: '#/properties/tituloDoProjeto',
              label: 'Título do Projeto',
              options: {
                required: true,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/duracao',
              label: 'Duração do Projeto em Meses',
              options: {
                required: true,
                isPositiveNumber: true,
              },
            },
            {
              type: 'Select',
              scope: '#/properties/instituicaoExecutoraId',
              label: 'Instituição Executora',
            },
            {
              type: 'Select',
              scope: '#/properties/unidadeExecutoraId',
              label: 'Unidade Executora',
              options: {
                filter: {
                  formFields: ['instituicaoExecutoraId'],
                  handler: (formData: Proposta, listData: any[], api: FormApi) => {
                    if (!formData.instituicaoExecutoraId) return Promise.resolve(listData);
                    return api.getAll({ instituicaoId: formData?.instituicaoExecutoraId });
                  },
                },
              },
            },
            {
              type: 'Control',
              scope: '#/properties/areaDeConhecimento',
              label: 'Áreas de Conhecimento da Proposta*',
              options: {
                label: 'Área de Conhecimento',
                detail: {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/grandeAreaId',
                      label: 'Grande Área',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        handleChange: areaDeConhecimentoHandleChange,
                        displayProperties: ['grandeAreaNome'],
                        filter: {
                          handler: (
                            formData: Proposta,
                            listData: any[],
                            api: AreaDeConhecimentoApi,
                          ) => {
                            return api.buscarGrandesAreas();
                          },
                        },
                        disableHandler: disableIfNoOptions,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/areaId',
                      label: 'Área',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        handleChange: areaDeConhecimentoHandleChange,
                        displayProperties: ['areaNome'],
                        filter: {
                          formFields: ['grandeAreaId'],
                          handler: (
                            formData: Proposta,
                            listData: any[],
                            api: AreaDeConhecimentoApi,
                            queryFilterValues: any,
                          ) => {
                            if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                            return api.buscarAreaPorGrandeAreaId(queryFilterValues?.[0].value);
                          },
                        },
                        disableHandler: disableIfNoOptions,
                        visibleHandler: visibleIfHasFilter,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/subAreaId',
                      label: 'Sub-área',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        displayProperties: ['subAreaNome'],
                        handleChange: areaDeConhecimentoHandleChange,
                        filter: {
                          formFields: ['areaId'],
                          handler: (
                            formData: Proposta,
                            listData: any[],
                            api: AreaDeConhecimentoApi,
                            queryFilterValues: any,
                          ) => {
                            if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                            return api.buscarSubAreaPorAreaId(queryFilterValues?.[0].value);
                          },
                        },
                        disableHandler: disableIfNoOptions,
                        visibleHandler: visibleIfHasFilter,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/especialidadeId',
                      label: 'Especialidade',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        displayProperties: ['especialidadeNome'],
                        handleChange: areaDeConhecimentoHandleChange,
                        filter: {
                          formFields: ['subAreaId'],
                          handler: (
                            formData: Proposta,
                            listData: any[],
                            api: AreaDeConhecimentoApi,
                            queryFilterValues: any,
                          ) => {
                            if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                            return api.buscarEspecialidadePorSubAreaId(
                              queryFilterValues?.[0].value,
                            );
                          },
                        },
                        disableHandler: disableIfNoOptions,
                        visibleHandler: visibleIfHasFilter,
                      },
                    },
                  ],
                },
              },
            },
          ],
        },
        {
          type: 'Category',
          label: 'Informações Complementares',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
              callback: (ctx: CrudContextData, fieldsConfig: IFieldsConfig) =>
                createResource(ctx, '/proposta', fieldsConfig),
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/formularioPropostaInformacaoComplementar',
              label: 'Formulário da Proposta',
            },
          ],
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              type: 'AND',
              conditions: [
                {
                  scope: '#/properties/edital/properties/informacaoComplementarEditalPergunta',
                  schema: {
                    type: 'array',
                    minItems: 1,
                  },
                },
                {},
              ],
            } as Condition,
          } as Rule,
        },
        {
          type: 'Category',
          label: 'Abrangência',
          options: {
            stepHandler: {
              handler: stepHandlerAbrangencia,
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/abrangencia',
              label: '',
              options: {
                itemLayout: 'SimpleItemLayout',
                detail: {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Select',
                          scope: '#/properties/estadoId',
                          label: 'Estado',
                          options: {
                            displayProperties: ['nome'],
                            filter: {
                              handler(formData, listData, api, queryFilterValues, path) {
                                const editalId = formData?.editalId;
                                if (!editalId) return Promise.resolve([]);

                                const index = Number(path?.split?.('.')?.at?.(-2));
                                if (Number.isNaN(index)) return Promise.resolve([]);

                                const { abrangencia } = formData;

                                return api.getAll({ editalId: editalId }).then((response: any) => {
                                  return response?.filter((x: any) => {
                                    if (!abrangencia) return true;
                                    return abrangencia
                                      ?.filter((_: any, i: number) => i !== index)
                                      ?.every((y: any) => y.estadoId !== x.id);
                                  });
                                });
                              },
                            },
                          },
                        },
                        {
                          type: 'Select',
                          scope: '#/properties/abrangenciaMunicipio',
                          options: {
                            selectMultiple: true,
                            displayProperties: ['nome'],
                            filter: {
                              selfContainedApi: true,
                              formFields: ['estadoId'],
                              handler: (
                                _formData: Proposta,
                                _listData: any[],
                                _api: IbgeApi,
                                queryFilterValues: any,
                              ) => {
                                if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                                return apiInstanceIbge.buscarMunicipioPorEstadoId(
                                  queryFilterValues[0].value,
                                );
                              },
                            },
                            handleChange: async (values: any, handler, formData, ctx, path) => {
                              const mappedValues = values?.map((value: any) => {
                                return {
                                  municipio: value?.data?.nome,
                                };
                              });
                              handler(`${path}`, mappedValues);
                            },
                            optionsComparator: (option: any, item: any) =>
                              option?.data?.nome === item?.municipio,
                            visibleHandler: visibleIfHasFilter,
                          },
                        },
                      ],
                    },
                  ],
                },
              },
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Dados do Coordenador',
      elements: [
        {
          type: 'Category',
          label: 'Dados Pessoais',
          options: {
            stepHandler: {
              handler: stepHandlerDadosCoordenador,
            },
          },
          elements: [
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/nome',
                      label: 'Nome',
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/nomeSocial',
                      label: 'Nome social',
                    },
                    {
                      type: 'Date',
                      scope: '#/properties/criadoPor/properties/dataNascimento',
                      label: 'Data de nascimento',
                      options: {
                        dateFormat: 'DD/MM/YYYY',
                      },
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/email',
                      label: 'E-mail',
                      options: {
                        mask: 'email',
                        disabled: true,
                      },
                    },
                  ],
                },
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/criadoPor/properties/racaCorId',
                      label: 'Raça/Cor',
                      options: {
                        route: 'raca-cor',
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/criadoPor/properties/paisId',
                      label: 'País',
                      options: {
                        route: 'pais',
                        iconBuilder: circleFlagBuilder,
                        handleChange: (
                          item: Pais,
                          handler: (path: string, value: any) => void,
                          formData: Proposta,
                          ctx?: CrudContextData,
                          path?: string,
                        ) => {
                          //Coloca objeto inteiro para ser usado na RULE
                          handler(`${path}`.replace('paisId', 'pais'), item);

                          //Limpa o documento se o país não for Brasil
                          if (item?.codigo !== CodigoPais.brasil) {
                            handler(`${path}`.replace('paisId', 'documento'), null);
                            handler(`${path}`.replace('paisId', 'endereco'), null);
                          }
                        },
                      },
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/documento',
                      label: 'CPF',
                      rule: showIfBrasilDadosCoordenador,
                      options: {
                        mask: '999.999.999-99',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'Category',
          label: 'Endereço',
          options: {
            additionalPaths: ['paisId'],
            stepHandler: {
              handler: stepHandlerDadosCoordenador,
            },
          },
          elements: [
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Cep',
                      scope: '#/properties/criadoPor/properties/endereco/properties/cep',
                      label: 'CEP',
                      rule: showIfBrasilDadosCoordenador,
                      options: {
                        mask: '99999-999',
                      },
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/endereco/properties/cep',
                      label: 'ZipCode',
                      rule: hideIfBrasilDadosCoordenador,
                      options: {
                        mask: '999999999',
                      },
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/endereco/properties/logradouro',
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/endereco/properties/numero',
                      label: 'Número',
                      rule: showIfBrasilDadosCoordenador,
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/endereco/properties/complemento',
                    },
                  ],
                },
                {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/endereco/properties/bairro',
                      label: 'Bairro',
                      rule: showIfBrasilDadosCoordenador,
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/endereco/properties/municipio',
                      label: 'Município',
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/endereco/properties/estado',
                      rule: showIfBrasilDadosCoordenador,
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/endereco/properties/estado',
                      label: 'Estado/Região',
                      rule: hideIfBrasilDadosCoordenador,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'Category',
          label: 'Dados Acadêmicos',
          options: {
            stepHandler: {
              handler: stepHandlerDadosCoordenador,
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/criadoPor/properties/instituicaoId',
                      label: 'Instituição',
                      options: {
                        route: 'instituicao',
                      },
                    },
                  ],
                  rule: hideIfSugerirInstituicao,
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Text',
                      scope: '#/properties/criadoPor/properties/instituicaoNome',
                      label: 'Instituição Nome',
                      options: {
                        format: 'string',
                        maxLength: 32,
                      },
                    },
                    {
                      type: 'Text',
                      scope: '#/properties/criadoPor/properties/instituicaoSigla',
                      label: 'Instituição Sigla',
                      options: {
                        format: 'string',
                        maxLength: 8,
                      },
                    },
                  ],
                  rule: showIfSugerirInstituicao,
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Boolean',
                      scope: '#/properties/criadoPor/properties/sugerirInstituicao',
                      label: 'Sugerir Instituição',
                      options: {
                        handleChange: (value: boolean, handler, formData, ctx, path) => {
                          handler('criadoPor.instituicaoId', null);
                          handler('criadoPor.instituicaoNome', null);
                          handler('criadoPor.instituicaoSigla', null);
                          handler('criadoPor.unidadeNome', null);
                          handler('criadoPor.unidadeSigla', null);
                          handler('criadoPor.sugerirInstituicao', value);
                        },
                      },
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/lattes',
                      label: 'Currículo Lattes',
                    },
                  ],
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/criadoPor/properties/unidadeId',
                      label: 'Unidade',
                      options: {
                        route: 'unidade',
                        filter: {
                          formFields: ['instituicaoId'],
                          handler: (
                            formData: Proposta,
                            listData: Instituicao[],
                            api: any,
                            queryFilterValues: any,
                            path: string,
                          ) => {
                            if (!queryFilterValues[0].value) return Promise.resolve([]);
                            return api.getAll({ instituicaoId: queryFilterValues[0].value });
                          },
                        },
                      },
                    },
                  ],
                  rule: hideIfSugerirUnidade,
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Text',
                      scope: '#/properties/criadoPor/properties/unidadeNome',
                      label: 'Unidade Nome',
                      options: {
                        format: 'string',
                        maxLength: 32,
                      },
                    },
                    {
                      type: 'Text',
                      scope: '#/properties/criadoPor/properties/unidadeSigla',
                      label: 'Unidade Sigla',
                      options: {
                        format: 'string',
                        maxLength: 8,
                      },
                    },
                  ],
                  rule: showIfSugerirUnidade,
                },
                {
                  type: 'HorizontalLayout',
                  rule: hideIfSugerirInstituicao,
                  elements: [
                    {
                      type: 'Boolean',
                      scope: '#/properties/criadoPor/properties/sugerirUnidade',
                      label: 'Sugerir Unidade',
                      options: {
                        handleChange: (value: boolean, handler) => {
                          handler('criadoPor.unidadeId', null);
                          handler('criadoPor.unidadeNome', null);
                          handler('criadoPor.unidadeSigla', null);
                          handler('criadoPor.sugerirUnidade', value);
                        },
                      },
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/linkedin',
                      label: 'Linkedin',
                    },
                  ],
                },
                {
                  type: 'HorizontalLayout',
                  rule: showIfSugerirInstituicao,
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/linkedin',
                      label: 'Linkedin',
                    },
                  ],
                },
                {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/criadoPor/properties/nivelAcademicoId',
                      label: 'Nível Acadêmico',
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/criadoPor/properties/interesseConsultor',
                      label: 'Interesse em ser consultor',
                    },
                  ],
                },
              ],
            },
            {
              type: 'Control',
              scope: '#/properties/criadoPor/properties/areaDeConhecimento',
              label: 'Suas Áreas de Conhecimento',
              options: {
                label: 'Área de Conhecimento',
                detail: {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/grandeAreaId',
                      label: 'Grande área',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        handleChange: areaDeConhecimentoHandleChange,
                        displayProperties: ['grandeAreaNome'],
                        filter: {
                          handler: (formData: any, listData: any[], api: AreaDeConhecimentoApi) => {
                            return api.buscarGrandesAreas();
                          },
                        },
                        disableHandler: disableIfNoOptions,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/areaId',
                      label: 'Área',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        handleChange: areaDeConhecimentoHandleChange,
                        displayProperties: ['areaNome'],
                        filter: {
                          formFields: ['grandeAreaId'],
                          handler: (
                            formData: any,
                            listData: any[],
                            api: AreaDeConhecimentoApi,
                            queryFilterValues: any,
                          ) => {
                            if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                            return api.buscarAreaPorGrandeAreaId(queryFilterValues?.[0].value);
                          },
                        },
                        disableHandler: disableIfNoOptions,
                        visibleHandler: visibleIfHasFilter,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/subAreaId',
                      label: 'Sub-área',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        displayProperties: ['subAreaNome'],
                        handleChange: areaDeConhecimentoHandleChange,
                        filter: {
                          formFields: ['areaId'],
                          handler: (
                            formData: any,
                            listData: any[],
                            api: AreaDeConhecimentoApi,
                            queryFilterValues: any,
                          ) => {
                            if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                            return api.buscarSubAreaPorAreaId(queryFilterValues?.[0].value);
                          },
                        },
                        disableHandler: disableIfNoOptions,
                        visibleHandler: visibleIfHasFilter,
                      },
                    },
                    {
                      type: 'Select',
                      scope: '#/properties/especialidadeId',
                      label: 'Especialidade',
                      options: {
                        apiInstance: apiInstanceAreaDeConhecimento,
                        displayProperties: ['especialidadeNome'],
                        handleChange: areaDeConhecimentoHandleChange,
                        filter: {
                          formFields: ['subAreaId'],
                          handler: (
                            formData: any,
                            listData: any[],
                            api: AreaDeConhecimentoApi,
                            queryFilterValues: any,
                          ) => {
                            if (!queryFilterValues?.[0].value) return Promise.resolve([]);
                            return api.buscarEspecialidadePorSubAreaId(
                              queryFilterValues?.[0].value,
                            );
                          },
                        },
                        disableHandler: disableIfNoOptions,
                        visibleHandler: visibleIfHasFilter,
                      },
                    },
                  ],
                },
              },
            },
          ],
        },
        {
          type: 'Category',
          label: 'Dados Profissionais',
          options: {
            stepHandler: {
              handler: stepHandlerDadosCoordenador,
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Boolean',
                  scope: '#/properties/criadoPor/properties/possuiVinculoInstitucional',
                  label: 'Possuo vínculo institucional',
                },
                {
                  type: 'VerticalLayout',
                  rule: showIfPossuiVinculoInstitucional,
                  elements: [
                    {
                      type: 'Select',
                      scope:
                        '#/properties/criadoPor/properties/vinculoInstitucional/properties/tipoVinculoInstitucionalId',
                      label: 'Tipo de vínculo',
                      options: {
                        route: 'tipo-vinculo-institucional',
                        required: (ctx: CrudContextData) => {
                          const { formData } = ctx;
                          return formData.criadoPor?.possuiVinculoInstitucional;
                        },
                      },
                    },
                    {
                      type: 'Boolean',
                      scope: '#/properties/criadoPor/properties/possuiVinculoEmpregaticio',
                      label: 'Possuo vínculo empregatício',
                    },
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Date',
                          scope:
                            '#/properties/criadoPor/properties/vinculoInstitucional/properties/inicioServico',
                          label: 'Início de Serviço',
                          options: {
                            dateFormat: 'DD/MM/YYYY',
                            required: (ctx: CrudContextData) => {
                              const { formData } = ctx;
                              return formData.criadoPor?.possuiVinculoEmpregaticio;
                            },
                          },
                        },
                        {
                          type: 'Label',
                          scope:
                            '#/properties/criadoPor/properties/vinculoInstitucional/properties/tempoServico',
                          label: 'Tempo de Serviço',
                          options: {
                            formatOptionLabel: getServicoLabelFromUsuario,
                            componentProps: {
                              variant: 'h5',
                              align: 'left',
                              sx: {
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                              },
                            },
                          },
                        },
                      ],
                    },
                    {
                      type: 'Select',
                      scope:
                        '#/properties/criadoPor/properties/vinculoInstitucional/properties/regimeTrabalhoId',
                      label: 'Regime de Trabalho',
                      options: {
                        route: 'regime-trabalho',
                        required: (ctx: CrudContextData) => {
                          const { formData } = ctx;
                          return formData.criadoPor?.possuiVinculoEmpregaticio;
                        },
                      },
                    },
                    {
                      type: 'Control',
                      scope:
                        '#/properties/criadoPor/properties/vinculoInstitucional/properties/funcao',
                      label: 'Função/Cargo',
                      options: {
                        required: (cxt: CrudContextData) => {
                          const { formData } = cxt;
                          return formData.criadoPor?.possuiVinculoEmpregaticio;
                        },
                      },
                    },
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Date',
                          scope:
                            '#/properties/criadoPor/properties/vinculoInstitucional/properties/inicioFuncao',
                          label: 'Início de Função/Cargo',
                          options: {
                            dateFormat: 'DD/MM/YYYY',
                            required: (cxt: CrudContextData) => {
                              const { formData } = cxt;
                              return formData.criadoPor?.possuiVinculoEmpregaticio;
                            },
                          },
                        },
                        {
                          type: 'Label',
                          scope:
                            '#/properties/criadoPor/properties/vinculoInstitucional/properties/tempoFuncao',
                          label: 'Tempo de Função/Cargo',
                          options: {
                            formatOptionLabel: getFuncaoLabelFromUsuario,
                            componentProps: {
                              variant: 'h5',
                              align: 'left',
                              sx: {
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                              },
                            },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Anexos',
      elements: [
        {
          type: 'Category',
          label: 'Documentos Pessoais',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  label: 'Documentos Pessoais',
                  type: 'File',
                  options: {
                    categoriesRoute: 'proposta/documento-pessoal-proposta-anexo/categories',
                    route: 'usuario/usuario-anexo',
                    fileApiOptions: (formData: Proposta) => {
                      return {
                        editalId: formData?.editalId,
                      };
                    },
                    withoutFk: true,
                    filterByCategories: true,
                  },
                },
              ],
            },
          ],
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: '#/properties/edital/properties/documentoPessoalEdital',
              schema: {
                type: 'array',
                minItems: 1,
              },
            } as Condition,
          } as Rule,
        },
        {
          type: 'Category',
          label: 'Documentos da Proposta',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  label: 'Documentos da Proposta',
                  type: 'File',
                  scope: '#/properties/documentoPropostaAnexo',
                  options: {
                    fileApiOptions: (formData: Proposta) => {
                      return {
                        editalId: formData?.editalId,
                      };
                    },
                  },
                },
              ],
            },
          ],
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: '#/properties/edital/properties/documentoPropostaEdital',
              schema: {
                type: 'array',
                minItems: 1,
              },
            } as Condition,
          } as Rule,
        },
      ],
    },
    {
      type: 'Category',
      label: 'Plano de Trabalho',
      elements: [
        {
          type: 'Category',
          label: 'Descrição',
          options: {
            stepHandler: {
              handler: stepHandlerDescricao,
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/formularioPropostaDescritiva',
              label: 'Formulário da Proposta',
            },
          ],
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              type: 'AND',
              conditions: [
                {
                  scope: '#/properties/edital/properties/editalPergunta',
                  schema: {
                    type: 'array',
                    minItems: 1,
                  },
                },
                {},
              ],
            } as Condition,
          } as Rule,
        },
        {
          type: 'Category',
          label: 'Indicadores de Produção',
          options: {
            stepHandler: {
              handler: stepHandlerIndicadores,
            },
          },
          elements: [
            {
              type: 'IndicadorProducaoProposta',
              scope: '#/properties/producaoProposta',
              label: 'Indicadores de Produção',
            },
          ],
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              type: 'AND',
              conditions: [
                {
                  scope: '#/properties/edital/properties/indicadorProducaoEdital',
                  schema: {
                    type: 'array',
                    minItems: 1,
                  },
                },
                {},
              ],
            } as Condition,
          } as Rule,
        },
        {
          type: 'Category',
          label: 'Membros',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
              callback: (ctx: CrudContextData, fieldsConfig: IFieldsConfig) =>
                createResource(ctx, '/proposta', fieldsConfig),
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'CustomDataTableUserSelect',
                  scope: '#/properties/propostaConviteMembro',
                },
              ],
            },
          ],
        },
        {
          type: 'Category',
          label: 'Atividades',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
              callback: (ctx: CrudContextData, fieldsConfig: IFieldsConfig) =>
                createResource(ctx, '/proposta', fieldsConfig),
            },
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/propostaAtividade',
              label: '',
              options: {
                itemLayout: 'SimpleItemLayout',
                detail: {
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/properties/titulo',
                      label: 'Título',
                    },
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Select',
                          scope: '#/properties/mesInicio',
                          label: 'Mês de Início',
                          options: {
                            oneOf: (formData: Proposta) => {
                              return Array.from({ length: formData?.duracao }, (_, i) => ({
                                title: `${i + 1}°`,
                                const: i + 1,
                              }));
                            },
                          },
                        },
                        {
                          type: 'Select',
                          scope: '#/properties/duracao',
                          label: 'Duração (Em meses)',
                          options: {
                            formFields: (formData, path) => {
                              return [
                                formData?.propostaAtividade?.at(path.split('.').at(-2))?.mesInicio,
                              ];
                            },
                            oneOf: (formData: Proposta, path: string) => {
                              const { duracao } = formData;
                              const mesInicio = formData?.propostaAtividade?.at(
                                parseInt(path.split('.').at(-2)),
                              )?.mesInicio;
                              if (!duracao || !mesInicio) return [];

                              const mesesRestantes = duracao - mesInicio + 1;

                              return Array.from({ length: mesesRestantes }, (_, i) => {
                                const quantidadeMeses = i + 1;
                                return {
                                  title: `${quantidadeMeses} ${
                                    quantidadeMeses > 1 ? 'meses' : 'mês'
                                  }`,
                                  const: quantidadeMeses,
                                };
                              });
                            },
                          },
                        },
                        {
                          type: 'Select',
                          scope: '#/properties/cargaHorariaSemanal',
                          label: 'Carga Horária Semanal',
                          options: {
                            oneOf: () => {
                              return Array.from({ length: 60 }, (_, i) => {
                                const horas = i + 1;
                                return {
                                  title: `${horas} ${horas > 1 ? 'horas' : 'hora'}`,
                                  const: horas,
                                };
                              });
                            },
                          },
                        },
                      ],
                    },
                    {
                      type: 'CustomSelectMembro',
                      scope: '#/properties/membroResponsavelId',
                      label: 'Responsável',
                    },
                    {
                      type: 'CustomMultipleSelectMembro',
                      scope: '#/properties/propostaAtividadeMembro',
                    },
                  ],
                },
              },
            },
          ],
        },
        getOrcamentoPropostaUiSchema(),
      ],
    },
    {
      type: 'Category',
      label: 'Termos',
      elements: [
        {
          type: 'Category',
          label: 'Termo de Aceite',
          options: {
            stepHandler: {
              handler: stepHandlerErrors,
              callback: (ctx: CrudContextData, fieldsConfig: IFieldsConfig) =>
                createResource(ctx, '/proposta', fieldsConfig),
            },
            nextButtonTitle: 'Submeter Proposta',
            nextButtonDisabled: (data: any) => {
              return !data?.termoDeAceiteAceito;
            },
            onFinish: (navigate: NavigateFunction, ctx: CrudContextData) => {
              navigate('/');
            },
          },
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'CkEditor',
                  scope: '#/properties/edital/properties/termoDeAceite',
                  options: {
                    ckeditorSize: { height: 400 },
                    toolbar: [],
                    disabled: true,
                  },
                },
              ],
            },
            {
              type: 'Control',
              scope: '#/properties/termoDeAceiteAceito',
              label: 'Li e estou de acordo com o Termo de Aceite',
            },
          ],
        },
      ],
    },
  ],
  options: {
    variant: 'stepper',
    showNavButtons: true,
    showProgress: true,
    route: '/proposta',
  },
};

export default propostaUiSchema;
