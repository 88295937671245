import { Api } from './api';
import client from '../config/axios-config';

export class IbgeApi extends Api {
  async buscarMunicipioPorEstadoId(id: number): Promise<any> {
    const response = await client.get<any>(`${this.url}estados/${id}/municipios`);
    return response.data;
  }

  async buscarEstados(): Promise<any> {
    const response = await client.get<any>(`${this.url}estados?orderBy=nome`);
    return response.data;
  }
}

export const makeIbgeApi = () => {
  return new IbgeApi(`${process.env.REACT_APP_IBGE_API_BASE_ADDR}`);
};
