import { HeadCell } from '../../../../components/crud/protocols/head-cell';
import { tipoSituacaoI18N } from '../../../../models/proposta';

export interface Data extends Record<string, any> {
  id: number;
  nome: string;
  situacao: string;
}

export const displayProperties = ['usuario.nome'];

export const moduleLabel = 'Convite de Consultores';

export const moduleName = 'AVALIACAO.FORMULARIO_CONSULTORIA.FORMULARIO_CONSULTORIA';

export const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'usuario.nome',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'situacao',
    numeric: false,
    disablePadding: false,
    label: 'Situação',
    computedRowLabel: (row) => {
      return tipoSituacaoI18N[row.situacao];
    },
  },
];
