import {
  and,
  Categorization,
  categorizationHasCategory,
  Category,
  isVisible,
  optionIs,
  RankedTester,
  rankWith,
  StatePropsOfLayout,
  uiTypeIs,
} from '@jsonforms/core';
import {
  AjvProps,
  MaterialLayoutRenderer,
  MaterialLayoutRendererProps,
  withAjvProps,
} from '@jsonforms/material-renderers';
import { TranslateProps, withJsonFormsLayoutProps, withTranslateProps } from '@jsonforms/react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import findKey from 'lodash/findKey';
import merge from 'lodash/merge';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IPropostaApi, makePropostaApi } from '../../api/proposta-api';
import { useCrudContext } from '../../components/crud/crud-context';
import { makeErrorsManager } from '../../components/crud/errors-manager';
import { CustomEditIcon, EditButton } from '../../components/crud/style/crud.styled';
import { customError } from '../../helpers/custom-errors';
import { getUischemaConfigs } from '../../helpers/get-uischema-configs';
import { createResource } from '../../utils/create-resource';
import { stepHandlerBack } from '../../utils/step-handler-back';
import { useAuthContext } from '../../views/login/auth/auth-context';
import {
  CrudStatesOptions,
  CustomError,
  IControlElement,
  IStepHandler,
  IUiSchema,
} from '../uischema';
import { ButtonWrapperStyled, NextButtonStyled, PrevButtonStyled } from './stepper-layout/styles';
import {
  ButtonContainer,
  Container,
  CounterText,
  ErrorIcon,
  Header,
  LeftContent,
  Main,
  MenuItem,
  RightMenu,
  RoundedErrorIcon,
  StepsCompletedContainer,
  StepsCompletedText,
  StyledButtonContained,
  StyledButtonOutlined,
  StyledCheckCheckIcon,
  StyledCircleIcon,
  StyledDoneIcon,
  StyledHorizontalRuleIcon,
  StyledLinearProgress,
  StyledList,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
  StyledListSubItemButton,
  StyledListSubItemText,
  TitleCategory,
} from './styled/categorization-menu';
import { ErrorObject } from 'ajv';

const materialCategorizationMenuTester: RankedTester = rankWith(
  3,
  and(uiTypeIs('CategorizationMenu'), categorizationHasCategory, optionIs('variant', 'stepper')),
);

interface MaterialCategorizationMenuLayoutRendererProps
  extends StatePropsOfLayout,
    AjvProps,
    TranslateProps {
  data: any;
}

interface IEvaluateError {
  propertyName: string;
  messages: string[];
}

interface ICategoryError {
  parentLabel: string;
  hasError: boolean;
}

const CategorizationMenuLayoutRenderer = (props: MaterialCategorizationMenuLayoutRendererProps) => {
  const { data, path, renderers, schema, visible, cells, config, ajv } = props;
  const ctx = useCrudContext();
  const navigate = useNavigate();
  const authContext = useAuthContext();
  const uischema = props?.uischema as IUiSchema;
  const categorization = uischema as Categorization;
  const showProgress = uischema.options?.showProgress;
  const appliedUiSchemaOptions = merge({}, config, uischema.options);
  const {
    crudStates,
    updateCrudStates,
    clearForm,
    showSuccess,
    showError,
    setErrorsCustom,
    setValidationMode,
    errorsCustom,
    additionalErrors,
  } = ctx;
  const url = uischema.options?.route;
  const customPaths = uischema.options?.customPaths;
  const propostaApi: IPropostaApi = makePropostaApi();
  const errorsManager = makeErrorsManager(ctx);
  const { addCustomErrors, removeCustomErrors } = errorsManager;

  const categories = useMemo(() => {
    const filterCategories = (elements: IControlElement[]) =>
      elements
        .filter(
          (category: Category | Categorization) =>
            category.type !== 'Category' || isVisible(category, data, '', ajv),
        )
        .map((category: Category | Categorization) => ({
          ...category,
          elements: category.elements ? filterCategories(category.elements) : [],
        }));

    return filterCategories(categorization?.elements || []);
  }, [categorization, ajv, data]);

  const categoryElements = useMemo(() => {
    const finalElements: IControlElement[] = [];
    const findFinalElements = (items: IControlElement[]) => {
      items.forEach((item) => {
        if (item.type === 'Category') {
          if (item.elements && item.elements.some((x: IControlElement) => x.type === 'Category')) {
            findFinalElements(item.elements);
          } else {
            finalElements.push(item);
          }
        }
      });
    };
    findFinalElements(categories);
    return finalElements.filter((x) => x.elements.length > 0);
  }, [categories]);

  const [activeCategory, setActiveCategory] = useState<number>(0);
  const [open, setOpen] = useState<Array<{ [key: string]: boolean }>>([]);
  const [selectedCategory, setSelectedCategory] = useState<IControlElement | null>(
    categoryElements[0],
  );
  const [stepsWithErrors, setStepsWithErrors] = useState<ICategoryError[]>([]);

  const childProps: MaterialLayoutRendererProps = {
    elements: selectedCategory?.elements,
    schema,
    path,
    direction: 'column',
    visible,
    renderers,
    cells,
  };

  const getErrorsWithParents = (errors: ErrorObject[]): ICategoryError[] => {
    const errorPropertyNames = errors.map((error: ErrorObject) =>
      error.propertyName.replace('.', '/'),
    );

    const processCategories = (categories: IControlElement[]): ICategoryError[] => {
      return categories.map((category: Category | Categorization) => {
        const hasError = category.elements.some((element: IControlElement) => {
          if (element.scope) {
            const scopePath = element.scope.replace(/^#\/properties\/|\/properties(?=\/)/g, '');
            return errorPropertyNames.includes(scopePath);
          }
          return false;
        });

        const subcategories = (category as Categorization).elements;
        const childrenErrors = subcategories ? processCategories(subcategories) : [];

        return {
          parentLabel: category.label,
          hasError: hasError || childrenErrors.some((child) => child.hasError),
        };
      });
    };

    return processCategories(categoryElements);
  };

  const handleClick = (key: string, depth: number) => {
    setOpen((prevState) => {
      const newOpen = [...prevState];
      newOpen[depth] = {
        ...Object.keys(newOpen[depth] || {}).reduce((acc, k) => ({ ...acc, [k]: false }), {}),
      };
      newOpen[depth][key] = !prevState[depth]?.[key];
      return newOpen;
    });
  };

  const getRootSchema = () => {
    return categoryElements?.[activeCategory];
  };

  const getNextButtonTitle = (): string => {
    const rootSchema = getRootSchema();
    return rootSchema?.options?.nextButtonTitle || 'Finalizar';
  };

  const nextButtonDisabled = (): boolean => {
    const rootSchema = getRootSchema();
    const nextButtonDisabled = rootSchema?.options?.nextButtonDisabled;
    if (nextButtonDisabled && typeof nextButtonDisabled === 'function') {
      return nextButtonDisabled(data);
    }
    return false;
  };

  const isDisabled = crudStates.view || nextButtonDisabled();

  const getStepHandler = (): IStepHandler => {
    const rootStepHandler = uischema?.options?.stepHandler;
    const rootSchema = getRootSchema();
    const categoryStepHandler = rootSchema?.options?.stepHandler;
    return (categoryStepHandler ?? rootStepHandler) as IStepHandler;
  };

  const handleStep = (step: number) => {
    setActiveCategory(step);
    setSelectedCategory(categoryElements[step]);

    if (showProgress && stepsWithErrors.length) {
      removeCustomErrors();
      const errorsWithParents = getErrorsWithParents(additionalErrors);
      setStepsWithErrors(errorsWithParents);
      setValidationMode('ValidateAndShow');
    }
  };

  const prevHandler = () => {
    if (activeCategory <= 0) return;
    handleStep(activeCategory - 1);
  };

  const executeStepHandler = async () => {
    const stepHandler: IStepHandler = getStepHandler();
    if (!stepHandler) return false;
    const { handler, url, callback } = stepHandler;
    const rootSchema = getRootSchema();
    const uiSchemaConfigs = getUischemaConfigs(rootSchema, ctx);
    const result = await handler?.(uiSchemaConfigs, ctx, data, url, callback);
    return result;
  };

  const nextHandler = async () => {
    setOpen([]);
    const nextStep = activeCategory + 1;

    if (nextStep > categoryElements.length) return;

    if (crudStates.view) {
      handleStep(nextStep);
      return;
    }

    await executeStepHandler();

    if (nextStep < categoryElements.length) {
      handleStep(nextStep);
    } else {
      const rootSchema = getRootSchema();
      if (rootSchema?.options?.onFinish) {
        rootSchema?.options?.onFinish(navigate, ctx, authContext);
      } else {
        navigate(url);
      }
    }
  };

  const onClickFinish = async () => {
    if (crudStates.view) {
      await stepHandlerBack(undefined, ctx, data, '', undefined);
    } else {
      if (!showProgress) {
        createResource(ctx, url).then((result) => {
          if (result) {
            backToLastPage();
          }
        });

        return;
      }

      if (!data.id) {
        await createResource(ctx, url, null, customPaths).then((newId) => {
          if (!newId) {
            showError('Erro ao salvar!');
            return;
          }

          evaluateProposta(newId);
        });
      } else {
        evaluateProposta(data.id);
      }
    }
  };

  const evaluateProposta = async (id: number) => {
    try {
      const res = await propostaApi.evaluate(id);
      const errors = res?.errors;

      if (errors?.length) {
        const evaluationErrors = errors.map((error: IEvaluateError) =>
          customError(error.propertyName, error.messages, data),
        ) as CustomError[];

        const newErrorsCustom = merge(errorsCustom, evaluationErrors);

        setErrorsCustom(newErrorsCustom);

        const additionalErrors = addCustomErrors();
        if (additionalErrors.length) {
          const errorsWithParents = getErrorsWithParents(additionalErrors);
          setStepsWithErrors(errorsWithParents);
          showError('Existem erros na proposta');
          setValidationMode('ValidateAndShow');
          return;
        }
      }

      await createResource(ctx, url, null, customPaths);
      backToLastPage();
    } catch (error) {
      console.error('Erro ao avaliar a proposta:', error);
      showError('Ocorreu um erro ao processar a avaliação da proposta.');
    }
  };

  const onClickSave = async () => {
    await createResource(ctx, url, null, customPaths).then((result) => {
      if (result) {
        showSuccess('Salvo com sucesso!');
      } else {
        showError('Erro ao salvar!');
      }
    });
  };

  const backToLastPage = () => {
    clearForm();
    updateCrudStates(CrudStatesOptions.LIST);
    navigate(url);
  };

  const iconsByDepth = {
    0: <StyledCheckCheckIcon />,
    1: <StyledCircleIcon />,
    2: <StyledHorizontalRuleIcon />,
  };

  const countSubItems = (
    item: IControlElement,
    currentDepth: number,
    targetDepth: number,
  ): number => {
    if (!item.elements) return 0;

    return item.elements.reduce((count: number, subItem: IControlElement) => {
      if (subItem.type === 'Category' && subItem.elements.length > 0) {
        if (currentDepth + 1 === targetDepth) {
          return count + 1;
        } else {
          return count + countSubItems(subItem, currentDepth + 1, targetDepth);
        }
      }
      return count;
    }, 0);
  };

  const isAnyParentSelected = (item: IControlElement): boolean => {
    if (selectedCategory?.label === item?.label) return true;
    return item.elements?.some(
      (child: IControlElement) =>
        selectedCategory.label === child.label ||
        (child.type === 'Category' && isAnyParentSelected(child)),
    );
  };

  const getIcon = (depth: number, hasError: boolean): React.ReactNode => {
    if (hasError) {
      return depth === 0 ? <RoundedErrorIcon /> : <ErrorIcon />;
    }

    if (depth === 0) {
      return <StyledCheckCheckIcon evaluate={Boolean(stepsWithErrors.length)} />;
    }

    if (Boolean(stepsWithErrors.length) && (depth === 1 || depth === 2)) {
      return <StyledDoneIcon />;
    }

    return iconsByDepth[depth];
  };

  const hasErrorItem = (item: IControlElement, depth: number): boolean => {
    const checkChildErrors = (currentItem: IControlElement): boolean => {
      const hasErrorInChildren = currentItem.elements?.some((el) =>
        stepsWithErrors.some((err: ICategoryError) => err.parentLabel === el.label && err.hasError),
      );
      return hasErrorInChildren || currentItem.elements?.some((el) => checkChildErrors(el));
    };

    const itemHasError =
      stepsWithErrors.some(
        (err: ICategoryError) => err.parentLabel === item.label && err.hasError,
      ) || checkChildErrors(item);

    return itemHasError;
  };

  const renderMenuItems = (items: IControlElement[], depth: number = 0): React.ReactNode => {
    return items
      .filter((item) => item.type === 'Category')
      .map((item, index) => {
        const hasError = hasErrorItem(item, depth);
        const isOpen = isAnyParentSelected(item) || open[depth]?.[item.label];
        const hasSubCategories = item.elements.some((el) => el.type === 'Category');

        let subItemCount = 0;
        let countFinished = 0;

        if (showProgress && hasSubCategories) {
          subItemCount = countSubItems(item, depth, depth + 1);

          if (Boolean(stepsWithErrors.length)) {
            countFinished =
              subItemCount -
              item.elements.filter((el) =>
                stepsWithErrors.some((err) => err.parentLabel === el.label && err.hasError),
              ).length;
          }
        }

        const renderListItem = () => (
          <StyledListItemButton onClick={() => handleClick(item.label, depth)}>
            <StyledListItemIcon>{getIcon(depth, hasError)}</StyledListItemIcon>
            <StyledListItemText primary={item.label} hasError={hasError} />
            {showProgress && <CounterText>{`${countFinished}/${subItemCount}`}</CounterText>}
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </StyledListItemButton>
        );

        const renderSubItems = () => (
          <Collapse in={isOpen} timeout='auto' unmountOnExit>
            <StyledList dense role='list'>
              {renderMenuItems(item.elements, depth + 1)}
            </StyledList>
          </Collapse>
        );

        if (hasSubCategories) {
          return (
            <MenuItem key={index} depth={depth} hasError={hasError}>
              {renderListItem()}
              {renderSubItems()}
            </MenuItem>
          );
        } else if (item.elements.length > 0) {
          return (
            <StyledListSubItemButton
              key={index}
              depth={depth}
              onClick={() => handleStep(parseInt(findKey(categoryElements, item)))}
              selected={selectedCategory.label === item.label}
            >
              <StyledListItemIcon>{getIcon(depth, hasError)}</StyledListItemIcon>
              <StyledListSubItemText primary={item.label} hasError={hasError} />
            </StyledListSubItemButton>
          );
        }

        return null;
      });
  };

  const labelButtonFinish = crudStates.view
    ? 'Fechar'
    : showProgress
      ? 'Submeter Proposta'
      : 'Finalizar';

  return (
    <Hidden xsUp={!visible}>
      <Main>
        <Container>
          <LeftContent>
            <Header>
              <TitleCategory>{getRootSchema()?.label}</TitleCategory>
              <MaterialLayoutRenderer key={getRootSchema()?.label} {...childProps} />
            </Header>
            {appliedUiSchemaOptions.showNavButtons && (
              <ButtonWrapperStyled>
                {appliedUiSchemaOptions.showEditButton && !crudStates.edit && (
                  <EditButton onClick={() => updateCrudStates(CrudStatesOptions.EDIT)}>
                    <CustomEditIcon />
                    Editar
                  </EditButton>
                )}
                {activeCategory < categoryElements.length - 1 ? (
                  <NextButtonStyled
                    disabled={activeCategory >= categoryElements.length - 1}
                    onClick={() => nextHandler()}
                  >
                    Próximo
                  </NextButtonStyled>
                ) : (
                  <NextButtonStyled disabled={isDisabled} onClick={() => nextHandler()}>
                    {getNextButtonTitle()}
                  </NextButtonStyled>
                )}
                <PrevButtonStyled disabled={activeCategory <= 0} onClick={() => prevHandler()}>
                  Anterior
                </PrevButtonStyled>
              </ButtonWrapperStyled>
            )}
          </LeftContent>

          <RightMenu>
            {showProgress && (
              <StepsCompletedContainer>
                <StepsCompletedText>Etapas Concluídas</StepsCompletedText>
                <StyledLinearProgress
                  hasError={stepsWithErrors.filter((step) => step.hasError).length > 0}
                  variant='determinate'
                  value={!crudStates.view ? (activeCategory / categoryElements.length) * 100 : 100}
                />
                <Divider />
              </StepsCompletedContainer>
            )}

            <ButtonContainer>
              <List>{renderMenuItems(categories)}</List>
              {!crudStates.view && (
                <StyledButtonOutlined
                  fullWidth
                  marginBottom='20px'
                  variant='outlined'
                  size='small'
                  aria-label='Salvar'
                  onClick={() => onClickSave()}
                >
                  Salvar
                </StyledButtonOutlined>
              )}
              <StyledButtonContained
                fullWidth
                variant='contained'
                size='small'
                aria-label={labelButtonFinish}
                onClick={() => onClickFinish()}
              >
                {labelButtonFinish}
              </StyledButtonContained>
            </ButtonContainer>
          </RightMenu>
        </Container>
      </Main>
    </Hidden>
  );
};

export const categorizationMenuLayoutRenderer = {
  tester: materialCategorizationMenuTester,
  renderer: withAjvProps(
    withTranslateProps(withJsonFormsLayoutProps(CategorizationMenuLayoutRenderer)),
  ),
};
