import { ConstNode } from './const-node';
import { IMathNode } from './i-math-node';
import { IVars } from './i-vars';

export const makeOperator = (op: string, leftChild: IMathNode, rightChild: IMathNode) => {
  if (leftChild instanceof ConstNode && rightChild instanceof ConstNode) {
    return new ConstNode(new OperatorNode(op, leftChild, rightChild).solve({}));
  }
  if (op === '/' && rightChild.listVars().length > 0)
    throw new Error('Division by variable is not supported');
  return new OperatorNode(op, leftChild, rightChild);
};
export class OperatorNode implements IMathNode {
  readonly op: string;
  readonly leftChild: IMathNode;
  readonly rightChild: IMathNode;

  constructor(op: string, leftChild: IMathNode, rightChild: IMathNode) {
    this.op = op;
    this.leftChild = leftChild;
    this.rightChild = rightChild;
  }

  listVars(): string[] {
    const left = this.leftChild.listVars();
    const right = this.rightChild.listVars();
    return [...new Set([...left, ...right])];
  }

  solve(vars: IVars): number {
    const left = this.leftChild.solve(vars);
    const right = this.rightChild.solve(vars);
    switch (this.op) {
      case '+':
        return left + right;
      case '-':
        return left - right;
      case '*':
        return left * right;
      case '/':
        return left / right;
    }
  }
}
