import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makePropostaConviteConsultorApi } from '../../api/proposta-convite-consultor-api';
import { makePropostaConviteMembroApi } from '../../api/proposta-convite-membro-api';
import PropostaConviteConsultorComponente from '../../components/proposta/proposta-convite-membros/proposta-convite-consultor';
import PropostaConviteMembros from '../../components/proposta/proposta-convite-membros/proposta-convite-membros';
import {
  PropostaConviteConsultor,
  PropostaConviteMembro,
  TipoSituacao,
} from '../../models/proposta';
import {
  AccordionContainer,
  ConvitesBox,
  ConvitesHeader,
  ConvitesHeaderLabel,
  FooterBox,
  FooterButton,
  QuantidadeConvitesLabel,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledDiversity3,
  StyledExpandMoreIcon,
  StyledMarkEmailUnread,
  TodosConvitesAnchor,
} from './style/convites-home';

type ConvitesHomeProps = {
  conviteConsultorResult: {
    count: number;
    data: PropostaConviteConsultor[];
  };
  conviteMembroResult: {
    count: number;
    data: PropostaConviteMembro[];
  };
  splitLength?: number;
  onConviteAceito?: () => void;
};

export const ConvitesHome = ({
  conviteConsultorResult,
  conviteMembroResult,
  splitLength,
  onConviteAceito,
}: ConvitesHomeProps) => {
  const navigate = useNavigate();
  const [apiPropostaResult, setApiPropostaResult] = useState<{
    count: number;
    data: PropostaConviteMembro[];
  }>({ count: 0, data: [] });
  const [apiPropostaConsultorResult, setApiPropostaConsultorResult] = useState<{
    count: number;
    data: PropostaConviteConsultor[];
  }>({ count: 0, data: [] });
  const [load, setLoad] = useState<boolean>(true);
  const [loadConsultor, setLoadConsultor] = useState<boolean>(true);
  const propostaConviteMembroApi = makePropostaConviteMembroApi();
  const propostaConviteConsultorApi = makePropostaConviteConsultorApi();

  const loadInitConvites = useCallback(() => {
    setApiPropostaConsultorResult((prev) => {
      if (prev !== conviteConsultorResult) {
        return conviteConsultorResult;
      }
      return prev;
    });
    setLoadConsultor(false);

    setApiPropostaResult((prev) => {
      if (prev !== conviteMembroResult) {
        return conviteMembroResult;
      }
      return prev;
    });
    setLoad(false);
  }, [conviteConsultorResult, conviteMembroResult]);

  useEffect(() => {
    loadInitConvites();
  }, [loadInitConvites]);

  async function loadConvites() {
    await propostaConviteConsultorApi
      .getAllReceived({ situacao: TipoSituacao.pendente, take: 2 })
      .then((resultData) => {
        setApiPropostaConsultorResult(resultData);
        setLoadConsultor(false);
      });

    await propostaConviteMembroApi
      .getAllReceived({
        situacao: TipoSituacao.pendente,
        take: 2,
      })
      .then((resultData) => {
        setApiPropostaResult(resultData);
        setLoad(false);
      });
  }

  if (apiPropostaResult.count === 0 && apiPropostaConsultorResult.count === 0) {
    return null;
  }

  return (
    <ConvitesBox>
      <ConvitesHeader>
        <StyledMarkEmailUnread />
        <ConvitesHeaderLabel>Convites</ConvitesHeaderLabel>
      </ConvitesHeader>
      {apiPropostaResult?.data?.length > 0 && (
        <StyledAccordion defaultExpanded>
          <AccordionContainer>
            <StyledAccordionSummary
              expandIcon={<StyledExpandMoreIcon />}
              aria-controls='convitesAccordion'
              id='convitesAccordion-header'
            >
              <StyledDiversity3 />
              Participação de Projetos
              <QuantidadeConvitesLabel align='center'>
                {apiPropostaResult ? `•  ${apiPropostaResult.count} Pendentes` : ``}
              </QuantidadeConvitesLabel>
            </StyledAccordionSummary>
          </AccordionContainer>
          <StyledAccordionDetails>
            <PropostaConviteMembros
              apiResultData={apiPropostaResult.data}
              propostaConviteMembroApi={propostaConviteMembroApi}
              loadState={{ load, setLoad }}
              loadConvites={loadConvites}
              splitLength={splitLength}
            />
          </StyledAccordionDetails>
        </StyledAccordion>
      )}
      {apiPropostaConsultorResult?.data?.length > 0 && (
        <StyledAccordion defaultExpanded>
          <AccordionContainer>
            <StyledAccordionSummary
              expandIcon={<StyledExpandMoreIcon />}
              aria-controls='convitesAccordion'
              id='convitesAccordion-header'
            >
              <StyledDiversity3 />
              Consultoria de Propostas
              <QuantidadeConvitesLabel align='center'>
                {apiPropostaConsultorResult
                  ? `•  ${apiPropostaConsultorResult.count} Pendentes`
                  : ``}
              </QuantidadeConvitesLabel>
            </StyledAccordionSummary>
          </AccordionContainer>
          <StyledAccordionDetails>
            <PropostaConviteConsultorComponente
              apiResultData={apiPropostaConsultorResult.data}
              propostaConviteConsultorApi={propostaConviteConsultorApi}
              loadState={{ load: loadConsultor, setLoad: setLoadConsultor }}
              loadConvites={loadConvites}
              splitLength={splitLength}
              onConviteAceito={onConviteAceito}
            />
          </StyledAccordionDetails>
        </StyledAccordion>
      )}
      <FooterBox>
        <FooterButton>
          <TodosConvitesAnchor onClick={() => navigate('/todos-convites')}>
            Ver Mais ({apiPropostaResult.count + apiPropostaConsultorResult.count})
          </TodosConvitesAnchor>
        </FooterButton>
      </FooterBox>
    </ConvitesBox>
  );
};
