import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { AuthData } from '../views/login/auth/auth-context';

const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_ADDR}${process.env.REACT_APP_API_PATH_PREFIX ?? ''}/`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const REFRESH_REQUEST_EXPIRATION_TIME = parseInt(
  process.env.REACT_APP_REFRESH_REQUEST_EXPIRATION_TIME,
);

let semaforo: Promise<AxiosResponse<AuthData>> | null = null;

client.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    let token = localStorage.getItem('token');
    const expiresIn = localStorage.getItem('expiresIn');

    if (expiresIn) {
      const expiresInDate = new Date(parseInt(expiresIn));

      if (expiresInDate < new Date()) {
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
          if (!semaforo) {
            semaforo = axios.post<AuthData>(
              '/login',
              { refreshToken },
              { baseURL: client.defaults.baseURL },
            );
          }
          setTimeout(() => {
            semaforo = null;
          }, REFRESH_REQUEST_EXPIRATION_TIME);
          try {
            const { data: authData } = await semaforo;
            const currentDate = new Date();
            localStorage.setItem('token', authData.token);
            localStorage.setItem('permissions', JSON.stringify(authData.permissions));
            localStorage.setItem('refreshToken', authData.refreshToken);
            localStorage.setItem(
              'expiresIn',
              currentDate.setSeconds(currentDate.getSeconds() + authData.expiresIn).toString(),
            );
            token = authData.token;
          } catch (error) {
            console.error('Refresh Token Incorreto', error);
          }
        }
      }
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default client;
