import { Calendar, Profile } from '../../../components/styled-icons';
import { CrudContextData, IUiSchema } from '../../../jsonforms/uischema';
import { createResource } from '../../../utils/create-resource';
import { stepHandlerContratacao, stepHandlerPrestacaoDeConta } from './step-handler-contratacao';

const iconConfig = [
  {
    category: 'Informações da Contratação',
    icon: Profile,
  },
  {
    category: 'Prestação de Contas',
    icon: Calendar,
  },
];

const contratacaoUiSchema: IUiSchema = {
  type: 'Categorization',
  options: {
    variant: 'stepper',
    showNavButtons: true,
    icons: iconConfig,
  },
  elements: [
    {
      type: 'Category',
      label: 'Informações da Contratação',
      options: {
        stepHandler: { handler: stepHandlerContratacao },
      },
      elements: [
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/numeroFundacao',
              label: 'Nº Fundação',
              options: {
                required: true,
              },
            },
            {
              type: 'Control',
              scope: '#/properties/numeroSiafem',
              label: 'Nº SIAFEM',
              options: {
                required: true,
              },
            },
            {
              type: 'Date',
              scope: '#/properties/dataPublicacaoDo',
              label: 'Publicação D.O',
              options: {
                required: true,
              },
            },
          ],
        },
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'Date',
                  scope: '#/properties/inicioVigencia',
                  label: 'Início da Vigência',
                  options: {
                    required: true,
                  },
                },
                {
                  type: 'Date',
                  scope: '#/properties/terminoVigencia',
                  label: 'Término da Vigência',
                  options: {
                    required: true,
                  },
                },
              ],
            },
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'Date',
                  scope: '#/properties/inicioExecucao',
                  label: 'Início da Execução',
                  options: {
                    required: true,
                  },
                },
                {
                  type: 'Date',
                  scope: '#/properties/terminoExecucao',
                  label: 'Término da Execução',
                  options: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Prestação de Contas',
      options: {
        stepHandler: {
          handler: stepHandlerPrestacaoDeConta,
          callback: (ctx: CrudContextData) => {
            const { showSuccess } = ctx;
            const result = createResource(ctx, '/contratacao');
            if (result) showSuccess('Contratação salva com sucesso!');
            return true;
          },
        },
      },
      elements: [
        {
          type: 'VerticalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/prestacaoDeConta',
              label: 'Prestação de contas',
              options: {
                itemLayout: 'SimpleItemLayout',
                detail: {
                  type: 'HorizontalLayout',
                  elements: [
                    {
                      type: 'Select',
                      scope: '#/properties/tipoPrestacaoDeConta',
                      label: 'Tipo',
                      options: {
                        required: true,
                      },
                    },
                    {
                      type: 'Date',
                      scope: '#/properties/data',
                      label: 'Data',
                      options: {
                        required: true,
                      },
                    },
                  ],
                },
              },
            },
          ],
        },
      ],
    },
  ],
};

export default contratacaoUiSchema;
