import styled from '@emotion/styled';
import { Button, Divider, Table, TableCell, TableRow } from '@mui/material';

export const BasicListSpanOut = styled('span')({
  color: '#333333',
  lineHeight: '14.09px',
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: 'Raleway',
  height: '14px',
  paddingLeft: '12px',
});

export const BasicListSpanOutFlex = styled('span')({
  color: '#333333',
  lineHeight: '14.09px',
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: 'Raleway',
  height: '14px',
  paddingLeft: '12px',
  display: 'flex',
});

export const BasicListSpanIn = styled('span')({
  color: '#333333',
  lineHeight: '14.09px',
  fontSize: '12px',
  fontWeight: 600,
  fontFamily: 'Raleway',
});

export const SPDTable = styled(Table)({
  width: '804px',
  borderRadius: '2px 2px 0px 0px',
});

export const SPDTableRow = styled(TableRow)({
  width: '804px',
  height: '22px',
  borderRadius: '2px 2px 0px 0px',
  border: '1px',
  display: 'table-row',
  gap: '1px',
  backgroundColor: '#1351B4',
  borderColor: '#C5C6D0',
  borderStyle: 'solid',
});

export const SPDTableHeaderCell = styled(TableCell)<{
  borderTopLeftRadius: string;
  borderTopRightRadius: string;
}>(({ borderTopLeftRadius, borderTopRightRadius }) => ({
  height: '14px',
  fontFamily: 'Raleway',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14.09px',
  color: '#FAF8FF',
  padding: 0,
  paddingTop: '4px',
  paddingRight: '16px',
  paddingBottom: '4px',
  paddingLeft: '16px',
  borderTopLeftRadius: borderTopLeftRadius,
  borderTopRightRadius: borderTopRightRadius,
}));

export const SPDTableBodyCell = styled(TableCell)({
  paddingTop: '8px',
  paddingRight: '16px',
  paddingBottom: '8px',
  paddingLeft: '16px',
  display: 'table-cell',
  gap: '8px',
  borderColor: '#C5C6D0',
  fontFamily: 'Raleway',
  fontWeight: 500,
  fontSize: '10px',
  lineHeight: '11.74px',
  color: '#1A1B20',
  borderWidth: '0px 0.5px 0.5px 0.5px',
  borderStyle: 'solid',
});

export const SPDTableBodyRow = styled(TableRow)({
  height: '28px',
});

export const Frame26085700 = styled('div')({
  width: '284px',
  height: '216px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const SPDValueSpan = styled('span')({
  height: '14px',
  width: '136px',
  fontSize: '12px',
  fontWeight: 600,
  fontFamily: 'Raleway',
  lineHeight: '14.09px',
  color: '#333333',
});

export const SPDValueDiv = styled('div')({
  width: '804px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const SPDDivider = styled(Divider)({
  height: '4px',
  width: '804px',
  backgroundColor: '#1351B4',
  borderColor: '#1351B4',
});

export const SPDTitleSpan = styled('span')({
  color: '#333333',
  lineHeight: '14.09px',
  fontSize: '12px',
  fontWeight: 800,
  fontFamily: 'Raleway',
  width: '208px',
  height: '14px',
});

export const SPDTitleDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const Frame26085668 = styled('div')({
  width: '804',
  paddingTop: '8px',
  paddingBottom: '8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

export const Frame26085750 = styled('div')({
  width: '817px',
  height: '725px',
  display: 'flex',
  gap: '8px',
  overflowY: 'scroll',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '3px',
    margin: '2px',
    height: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
});

export const Frame26085664 = styled('div')({
  width: '1120px',
  paddingTop: '16px',
  paddingBottom: '20px',
  paddingLeft: '16px',
  display: 'flex',
  gap: '8px',
});

export const Substepper = styled('div')({
  width: '193px',
  height: '30px',
  paddingTop: '16px',
  paddingRight: '16px',
  paddingLeft: '16px',
  display: 'flex',
  gap: '16px',
});

export const SPDHeaderSpan = styled('span')({
  color: '#333333',
  lineHeight: '18.78px',
  fontSize: '16px',
  fontWeight: 700,
  fontFamily: 'Raleway',
  width: '192px',
  height: '19px',
});

export const SPDHeaderDiv = styled('div')({
  paddingTop: '16px',
  paddingRight: '16px',
  paddingLeft: '16px',
  height: '35px',
  width: '224px',
  display: 'flex',
  gap: '16px',
});

export const DocumentosDocsDaPropostaDefaut = styled('div')({
  width: '1120px',
});

export const IconSpan = styled('span')({
  marginLeft: '3px',
  marginRight: '3px',
});

export const SPDStyledButtonContained = styled(Button)<{
  fullWidth?: boolean;
  marginBottom?: string;
  marginRight?: string;
}>(({ fullWidth }) => ({
  float: 'right',
  backgroundColor: 'var(--color-button)',
  '&:hover': {
    backgroundColor: 'var(--color-button)',
    filter: 'brightness(0.9)',
  },
  width: fullWidth ? '100%' : 'auto',
  padding: '8px',
  borderRadius: '2px',
}));
