import { HeadCell } from '../../../../components/crud/protocols/head-cell';
import { FormularioConsultoria } from '../../../../models/formulario-consultoria';

export const moduleName = 'AVALIACAO.FORMULARIO_CONSULTORIA.FORMULARIO_CONSULTORIA';

export const displayProperties = ['titulo'];

export const moduleLabel = 'Formulário de Consultoria';

export const headCells: readonly HeadCell<FormularioConsultoria>[] = [
  {
    id: 'titulo',
    numeric: false,
    disablePadding: false,
    label: 'Título',
  },
];
