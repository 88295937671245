import { useEffect, useState } from 'react';
import { makePropostaConviteMembroApi } from '../../api/proposta-convite-membro-api';
import { useCrudContext } from '../../components/crud/crud-context';
import PropostaConviteMembros from '../../components/proposta/proposta-convite-membros/proposta-convite-membros';
import {
  PropostaConviteConsultor,
  PropostaConviteMembro,
  TipoSituacao,
} from '../../models/proposta';
import {
  ComponentBox,
  ConvitesBox,
  Header,
  QuantidadeConvitesLabel,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledDiversity3,
  StyledExpandMoreIcon,
  WidthFull,
} from './style/todos-convites';
import { makePropostaConviteConsultorApi } from '../../api/proposta-convite-consultor-api';
import PropostaConviteConsultorComponente from '../../components/proposta/proposta-convite-membros/proposta-convite-consultor';

function TodosConvites() {
  const { setCurrentTitle } = useCrudContext();
  setCurrentTitle('Convites');

  const [apiPropostaResult, setApiPropostaResult] = useState<{
    count: number;
    data: PropostaConviteMembro[];
  }>();
  const [apiPropostaConsultorResult, setApiPropostaConsultorResult] = useState<{
    count: number;
    data: PropostaConviteConsultor[];
  }>({ count: 0, data: [] });
  const [load, setLoad] = useState<boolean>(true);
  const [loadConsultor, setLoadConsultor] = useState<boolean>(true);
  const propostaConviteConsultorApi = makePropostaConviteConsultorApi();
  const propostaConviteMembroApi = makePropostaConviteMembroApi();

  useEffect(() => {
    loadConvites();
  }, []);

  async function loadConvites() {
    await propostaConviteConsultorApi
      .getAllReceived({ situacao: TipoSituacao.pendente })
      .then((resultData) => {
        setApiPropostaConsultorResult(resultData);
        setLoadConsultor(false);
      });

    await propostaConviteMembroApi
      .getAllReceived({
        situacao: TipoSituacao.pendente,
      })
      .then((resultData) => {
        setApiPropostaResult(resultData);
        setLoad(false);
      });
  }

  return (
    <ComponentBox>
      <Header>
        <h3>Convites</h3>
      </Header>
      <WidthFull>
        <ConvitesBox>
          {apiPropostaResult?.data?.length > 0 && (
            <StyledAccordion defaultExpanded>
              <StyledAccordionSummary
                expandIcon={<StyledExpandMoreIcon />}
                aria-controls='convitesAccordion'
                id='convitesAccordion-header'
              >
                <StyledDiversity3 />
                Participação de Projetos
                <QuantidadeConvitesLabel align='center'>
                  {apiPropostaResult ? `•  ${apiPropostaResult.count} Pendentes` : ``}
                </QuantidadeConvitesLabel>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <PropostaConviteMembros
                  apiResultData={apiPropostaResult?.data}
                  propostaConviteMembroApi={propostaConviteMembroApi}
                  loadState={{ load, setLoad }}
                  loadConvites={loadConvites}
                  splitLength={1}
                />
              </StyledAccordionDetails>
            </StyledAccordion>
          )}
          {apiPropostaConsultorResult?.data?.length > 0 && (
            <StyledAccordion defaultExpanded>
              <StyledAccordionSummary
                expandIcon={<StyledExpandMoreIcon />}
                aria-controls='convitesConsultorAccordion'
                id='convitesConsultorAccordion-header'
              >
                <StyledDiversity3 />
                Consultoria de Propostas
                <QuantidadeConvitesLabel align='center'>
                  {apiPropostaConsultorResult
                    ? `•  ${apiPropostaConsultorResult.count} Pendentes`
                    : ``}
                </QuantidadeConvitesLabel>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <PropostaConviteConsultorComponente
                  apiResultData={apiPropostaConsultorResult.data}
                  propostaConviteConsultorApi={propostaConviteConsultorApi}
                  loadState={{ load: loadConsultor, setLoad: setLoadConsultor }}
                  loadConvites={loadConvites}
                  splitLength={1}
                />
              </StyledAccordionDetails>
            </StyledAccordion>
          )}
        </ConvitesBox>
      </WidthFull>
    </ComponentBox>
  );
}

export default TodosConvites;
