import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { Chip, List, ListItem } from '@mui/material';
import { CalendarDate } from 'styled-icons/bootstrap';
import { DocumentEdit } from 'styled-icons/fluentui-system-filled';

export const ConvitesBox = styled(Box)({
  width: '100%',
  borderRadius: '4px',
  fontFamily: 'Raleway',
  margin: '0px 6px 12px 6px',
  border: 'solid 1px #C5C6D0',
});

export const ConvitesHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '8px',
  paddingTop: '8px',
});

export const ConvitesHeaderLabel = styled(Typography)({
  fontSize: '16px',
  fontWeight: 700,
  marginLeft: '8px',
  fontFamily: 'Raleway',
  color: '#333333',
});

export const FooterBox = styled(Box)({
  minHeight: '36px',
  display: 'flex',
  flexDirection: 'row-reverse',
  paddingTop: '10px',
  paddingBottom: '10px',
  paddingRight: '16px',
});

export const FooterButton = styled(Typography)({
  fontSize: '12px',
  color: '#003D92 !important',
  fontWeight: 600,
  fontFamily: 'Raleway',
  lineHeight: '14.09px',
  letterSpacing: '0.15px',
});

export const StyledList = styled(List)({
  width: '100%',
  cursor: 'pointer',
});

export const StyledListItem = styled(ListItem)({
  display: 'block',
  padding: '4px 16px',
  borderStyle: 'solid',
  borderWidth: '1px 0px 1px 0px',
  borderColor: '#C5C6D0',
});

export const PropostaTitle = styled(Typography)({
  fontSize: '14px',
  fontWeight: 700,
  fontFamily: 'Raleway',
  color: '#333333',
});

export const Description = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '4px 0px',
});

export const Termino = styled(Typography)({
  fontSize: '14px',
  fontFamily: 'Raleway',
  color: '#333333',
});

export const Status = styled(Chip)<{ status: 'success' | 'danger' }>(({ status }) => ({
  backgroundColor: 'transparent',
  color: status === 'success' ? '#00A000' : '#FF0000',
  border: `1px solid ${status === 'success' ? '#00A000' : '#FF0000'}`,
  height: '20px',
  fontSize: '0.75rem',
}));

export const TodosConvitesAnchor = styled('a')({
  cursor: 'pointer',
});

export const AvisosIcon = styled(CalendarDate)({
  width: '16px',
  height: '16px',
  color: '#003D92',
  marginLeft: '0px',
  marginRight: '8px',
});

export const TitleContainer = styled('div')({});

export const DateDisplay = styled('span')({
  color: '#003D92',
});

export const DocumentIcon = styled(DocumentEdit)({
  width: '16px',
  height: '16px',
  color: '#003D92',
  marginLeft: '16px',
});

export const ComponentBox = styled(Box)({
  width: '100%',
  fontFamily: 'Raleway',
});

export const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  paddingTop: '8px',
  paddingBottom: '8px',
});

export const HeaderTodas = styled('div')({
  marginLeft: '20px',
});

export const Search = styled('div')({
  padding: '20px',
  paddingTop: 0,
  marginBottom: '20px',
});

export const WidthFull = styled(List)({
  width: '100%',
});
