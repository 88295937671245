import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ConvitesBox,
  ConvitesHeader,
  ConvitesHeaderLabel,
  FooterBox,
  FooterButton,
  TodosConvitesAnchor,
  StyledListItem,
  StyledList,
  PropostaTitle,
  Description,
  Status,
  Termino,
  AvisosIcon,
  TitleContainer,
  DateDisplay,
  DocumentIcon,
} from './style/avaliacoes-home';
import { PropostaConviteConsultor, TipoSituacao } from '../../../models/proposta';
import { makePropostaConviteConsultorApi } from '../../../api/proposta-convite-consultor-api';
import dayjs from 'dayjs';
import { getStatusProps } from './utils/lista-avaliacoes.util';
import { CrudStatesOptions } from '../../../jsonforms/uischema';
import { useCrudContext } from '../../../components/crud/crud-context';

type AvaliacoesHomeProps = {
  refreshAvaliacoes: number;
};

export const AvaliacoesHome = ({ refreshAvaliacoes }: AvaliacoesHomeProps) => {
  const navigate = useNavigate();
  const [count, setCount] = useState<number>(0);
  const { parameterFilters, setParameterFilters } = useCrudContext();
  const api = useMemo(() => makePropostaConviteConsultorApi(), []);
  const [avaliacoes, setAvaliacoes] = useState<PropostaConviteConsultor[]>([]);

  useEffect(() => {
    const load = async () => {
      const result = await api.getAllReceived({
        take: 1000,
        orderBy: 'createdAt',
        orderDirection: 'desc',
      });
      const data = result.data
        .filter((item) => item.situacao !== TipoSituacao.pendente)
        .slice(0, 2);
      setAvaliacoes(data);
      setCount(data.length);
    };

    load();
  }, [refreshAvaliacoes]);

  if (avaliacoes.length === 0) {
    return null;
  }

  const handleNavigate = (id: number, situacao: string) => {
    if (!id) return;

    if (situacao === TipoSituacao.aceito) {
      setParameterFilters({
        ...parameterFilters,
        propostaConviteConsultorId: id,
      });
      navigate(`/avaliacao-consultor`, {
        state: { crudState: CrudStatesOptions.ADD },
      });
    } else if (situacao === TipoSituacao.avaliado) {
      setParameterFilters({
        ...parameterFilters,
        propostaConviteConsultorId: id,
      });
      navigate(`/avaliacao-consultor/${id}`, {
        state: { crudState: CrudStatesOptions.VIEW },
      });
    }
  };

  return (
    <ConvitesBox>
      <ConvitesHeader>
        <DocumentIcon />
        <ConvitesHeaderLabel>Minhas Avaliações</ConvitesHeaderLabel>
      </ConvitesHeader>
      <StyledList dense={true}>
        {avaliacoes.map((item, index) => (
          <StyledListItem key={index} onClick={() => handleNavigate(item.id, item.situacao)}>
            <TitleContainer>
              <PropostaTitle>{item.proposta.tituloDoProjeto}</PropostaTitle>
            </TitleContainer>
            <Description>
              <Termino>
                <AvisosIcon />
                Data de Témino:{' '}
                <DateDisplay>
                  {dayjs.utc(item.formularioConsultoria.dataTermino).format('DD/MM/YYYY')}
                </DateDisplay>
              </Termino>
              <Status {...getStatusProps(item.situacao, item.formularioConsultoria.dataTermino)} />
            </Description>
          </StyledListItem>
        ))}
      </StyledList>

      <FooterBox>
        <FooterButton>
          <TodosConvitesAnchor onClick={() => navigate('/todas-avaliacoes')}>
            Ver Mais ({count})
          </TodosConvitesAnchor>
        </FooterButton>
      </FooterBox>
    </ConvitesBox>
  );
};
