import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Crud } from '../../../components/crud/crud';
import { useCrudContext } from '../../../components/crud/crud-context';
import { AvaliacaoConsultor } from '../../../models/avaliacao-consultor';
import { AvaliacaoConsultorApi, makeAvaliacaoConsultor } from './avaliacao-consultor-api';
import { headCells } from './avaliacao-consultor.headcell';
import AvaliacaoConsultorUiSchema from './avaliacao-consultor.uischema';

function AvaliacaoConsultorView() {
  const location = useLocation();
  const navigate = useNavigate();
  const { updateCrudStates, parameterFilters, formData, setFormData } = useCrudContext();
  const conviteId = Number(parameterFilters.propostaConviteConsultorId);

  const [api, setApi] = useState<AvaliacaoConsultorApi>(() => makeAvaliacaoConsultor(conviteId));

  useEffect(() => {
    if (conviteId && !api) setApi(makeAvaliacaoConsultor(conviteId));
    if (conviteId) setFormData({ ...formData, conviteId });
    updateCrudStates(location.state?.crudState);
  }, [conviteId]);

  const customSave = async (
    id: number,
    formData: AvaliacaoConsultor,
    defaultSave: () => Promise<void>,
  ) => {
    await defaultSave();
    navigate('/home');
  };

  return (
    <Crud<AvaliacaoConsultor>
      headCells={headCells}
      titleConfig={{ value: 'Avaliação de Consultoria', show: true }}
      apiUrl={'/avaliacao-consultor'}
      apiClient={api}
      hideDelete
      uischema={AvaliacaoConsultorUiSchema}
      hideUpdate
      customSave={{ handler: customSave }}
    ></Crud>
  );
}

export default AvaliacaoConsultorView;
