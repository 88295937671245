import { Rule } from '@jsonforms/core';
import { GenericApi } from '../../../../api/generic-api';
import { DocumentAttach, Info, PersonXFill } from '../../../../components/styled-icons';
import { IUiSchema } from '../../../../jsonforms/uischema';
import { FormularioConsultoriaCategorizado } from '../../../../models/formulario-consultoria-categorizado';
import {
  PerguntaAvaliacao,
  TipoRespostaPerguntaAvaliacao,
} from '../../../../models/pergunta-avaliacao';
import { stepHandlerErrors } from '../../../../utils/step-handler-errors';
import perguntaAvaliacaoUiSchema from '../../pergunta-avaliacao/pergunta-avaliacao.uischema';
import { handlerFormularioConsultoria } from './handler/handler-formulario-consultoria';
import { handlerFormularioConsultoriaFormula } from './handler/handler-formulario-consultoria-formula';
import { DocumentoPropostaEdital } from '../../../../models/edital';

const iconsConfig = [
  {
    category: 'Dados do Formulário',
    icon: Info,
  },
  {
    category: 'Perguntas do Formulário',
    icon: DocumentAttach,
  },
  {
    category: 'Restrições',
    icon: PersonXFill,
  },
];

export const formatLabel = (item: any) => {
  return item.label;
};

const showRuleForPerguntasNotas = (): Rule => {
  return {
    effect: 'SHOW',
    condition: {
      type: 'OR',
      conditions: [
        {
          scope: '#/properties/formularioConsultoriaPergunta',
          schema: {
            type: 'array',
            contains: {
              properties: {
                tipoResposta: {
                  const: TipoRespostaPerguntaAvaliacao.nota,
                },
              },
            },
          },
        },
        {
          scope: '#/properties/formularioConsultoriaCategorizado',
          schema: {
            type: 'array',
            contains: {
              properties: {
                formularioConsultoriaCategorizadoPerguntaAvaliacao: {
                  type: 'array',
                  contains: {
                    properties: {
                      tipoResposta: {
                        const: TipoRespostaPerguntaAvaliacao.nota,
                      },
                    },
                    required: ['tipoResposta'],
                  },
                },
              },
              required: ['formularioConsultoriaCategorizadoPerguntaAvaliacao'],
            },
          },
        },
      ],
    },
  } as Rule;
};

const hideIfNotCategorizada = {
  effect: 'HIDE',
  condition: {
    scope: '#/properties/perguntasCategorizadas',
    schema: {
      const: false,
    },
  },
} as Rule;

const hideIfCategorizada = {
  effect: 'HIDE',
  condition: {
    scope: '#/properties/perguntasCategorizadas',
    schema: {
      const: true,
    },
  },
} as Rule;

const limparPergunta = (pergunta: PerguntaAvaliacao) => {
  if (!pergunta) return null;

  let multiplaEscolha = undefined;
  let descritiva = undefined;
  let nota = undefined;

  if (pergunta.tipoResposta === TipoRespostaPerguntaAvaliacao.multiplaEscolha) {
    multiplaEscolha = pergunta.multiplaEscolha?.map((item) => ({
      alternativa: item.alternativa,
    }));
  } else if (pergunta.tipoResposta === TipoRespostaPerguntaAvaliacao.descritiva) {
    descritiva = { minimo: pergunta.descritiva?.minimo, maximo: pergunta.descritiva?.maximo };
  } else if (pergunta.tipoResposta === TipoRespostaPerguntaAvaliacao.nota) {
    nota = { notaMinima: pergunta.nota?.notaMinima, notaMaxima: pergunta?.nota?.notaMaxima };
  }

  return {
    ...pergunta,
    perguntaBase: false,
    id: undefined,
    multiplaEscolha,
    descritiva,
    nota,
  };
};

const gerenciarFormularioConsultoriaUiSchema: IUiSchema = {
  type: 'Categorization',
  elements: [
    {
      type: 'Category',
      label: 'Dados do Formulário',
      options: {
        stepHandler: {
          handler: () => stepHandlerErrors,
        },
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/titulo',
          label: 'Título',
          options: {
            required: true,
          },
        },
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Date',
              scope: '#/properties/dataInicio',
              label: 'Início',
              options: {
                dateFormat: 'DD/MM/YYYY',
                required: true,
              },
            },
            {
              type: 'Date',
              scope: '#/properties/dataTermino',
              label: 'Término',
              options: {
                dateFormat: 'DD/MM/YYYY',
                required: true,
              },
            },
            {
              type: 'Date',
              scope: '#/properties/dataExpiracaoConvite',
              label: 'Expiração do Convite',
              options: {
                dateFormat: 'DD/MM/YYYY',
                required: true,
              },
            },
            {
              type: 'Number',
              scope: '#/properties/notaMinimaAprovacao',
              label: 'Nota Mínima de Aprovação',
              options: {
                isPositiveNumber: true,
              },
            },
          ],
        },
        {
          type: 'Control',
          scope: '#/properties/perguntasCategorizadas',
          label: 'Será um formulário com perguntas categorizadas',
          options: {
            onlyCreate: true,
          },
        },
      ],
    },
    {
      type: 'Category',
      label: 'Restrições',
      options: {
        stepHandler: {
          handler: () => stepHandlerErrors,
        },
      },
      elements: [
        {
          type: 'Select',
          scope: '#/properties/documentoPropostaEdital',
          label: 'Documentos Restritos',
          options: {
            formatOptionLabel: formatLabel,

            selectMultiple: true,
            displayProperties: ['nome'],
            filter: {
              selfContainedApi: true,
              handler: (
                formData: DocumentoPropostaEdital,
                _listData: any[],
                _api: any,
                _queryFilterValues: any,
              ) => {
                if (!formData?.edital?.documentoPropostaEdital?.length) {
                  return Promise.resolve([]);
                }
                return Promise.resolve(
                  formData?.edital?.documentoPropostaEdital
                    .filter((x) => !Boolean(x.deletedAt))
                    .map((doc: any) => ({
                      label: doc.nome,
                      id: doc.id,
                      data: doc,
                    })),
                );
              },
            },

            handleChange: async (
              values: any,
              handler,
              formData: DocumentoPropostaEdital,
              ctx,
              path,
            ) => {
              const mappedValues = values?.map((value: any) => {
                return value?.data.data;
              });
              handler(`${path}`, mappedValues);
            },
          },
        },
      ],
    },
    {
      type: 'Category',
      label: 'Perguntas do Formulário',
      options: {
        stepHandler: {
          handler: handlerFormularioConsultoria,
        },
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/formularioConsultoriaCategorizado',
          label: 'Perguntas Categorizadas',
          options: {
            elementLabelProp: 'tituloCategoria',
            detail: {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/tituloCategoria',
                  label: 'Título da Categoria',
                },
                {
                  type: 'Select',
                  scope: '#/properties/perguntaAvaliacaoId',
                  label: 'Pergunta de Avaliação',
                  options: {
                    addEmptyOption: true,
                    handleChange: (values: PerguntaAvaliacao, handler) => {
                      handler('perguntaInfo', limparPergunta(values));
                    },
                    filter: {
                      handler: (
                        data: PerguntaAvaliacao,
                        listData: any[],
                        api: GenericApi<PerguntaAvaliacao>,
                        queryFilterValues: any,
                        path: string,
                      ): Promise<PerguntaAvaliacao[]> => {
                        return api.getAll({ perguntaBase: true });
                      },
                    },
                  },
                },
                {
                  type: 'Control',
                  scope: '#/properties/formularioConsultoriaCategorizadoPerguntaAvaliacao',
                  label: 'Perguntas',
                  options: {
                    detail: perguntaAvaliacaoUiSchema(),
                    elementLabelProp: 'pergunta',
                    defaultValue: (formData: { perguntaInfo: PerguntaAvaliacao }) =>
                      formData.perguntaInfo ?? {
                        perguntaBase: false,
                        descritiva: {
                          minimo: 0,
                          maximo: 0,
                        },
                        nota: {
                          notaMinima: 0,
                          notaMaxima: 0,
                        },
                      },
                    showSortButtons: true,
                  },
                },
              ],
            },
            showSortButtons: true,
          },
          rule: hideIfNotCategorizada,
        },
        {
          type: 'Select',
          scope: '#/properties/perguntaAvaliacaoId',
          label: 'Pergunta de Avaliação',
          options: {
            addEmptyOption: true,
            handleChange: (values: PerguntaAvaliacao, handler) => {
              handler('perguntaInfo', limparPergunta(values));
            },
            filter: {
              handler: (
                data: PerguntaAvaliacao,
                listData: any[],
                api: GenericApi<PerguntaAvaliacao>,
                queryFilterValues: any,
                path: string,
              ): Promise<PerguntaAvaliacao[]> => {
                return api.getAll({ perguntaBase: true });
              },
            },
          },
          rule: hideIfCategorizada,
        },
        {
          type: 'Control',
          scope: '#/properties/formularioConsultoriaPergunta',
          label: 'Perguntas',
          options: {
            detail: perguntaAvaliacaoUiSchema(),
            elementLabelProp: 'pergunta',
            defaultValue: (formData: { perguntaInfo: PerguntaAvaliacao }) =>
              formData.perguntaInfo ?? {
                perguntaBase: false,
                descritiva: {
                  minimo: 0,
                  maximo: 0,
                },
                nota: {
                  notaMinima: 0,
                  notaMaxima: 0,
                },
              },
            showSortButtons: true,
          },
          rule: hideIfCategorizada,
        },
      ],
    },

    {
      type: 'Category',
      label: 'Configurações',
      options: {
        stepHandler: {
          handler: handlerFormularioConsultoriaFormula,
        },
      },
      elements: [
        {
          type: 'FormularioNota',
        },
        {
          type: 'Label',
          options: {
            formatOptionLabel: (formData: any) => {
              if (formData.formularioConsultoriaPergunta?.length > 0) {
                const perguntas = formData.formularioConsultoriaPergunta
                  .map((pergunta: PerguntaAvaliacao, index: number) => {
                    if (pergunta.tipoResposta === TipoRespostaPerguntaAvaliacao.nota) {
                      return `P${index + 1}`;
                    } else return null;
                  })
                  .filter((titulo: string) => titulo)
                  .join(', ');
                return `Perguntas: (${perguntas})`;
              } else if (formData.formularioConsultoriaCategorizado?.length > 0) {
                const perguntas = [];

                formData.formularioConsultoriaCategorizado.forEach(
                  (categoria: FormularioConsultoriaCategorizado, categoriaIndex: number) => {
                    const categoriaPerguntas =
                      categoria.formularioConsultoriaCategorizadoPerguntaAvaliacao;

                    if (!categoriaPerguntas) return;

                    categoriaPerguntas.forEach(
                      (pergunta: PerguntaAvaliacao, perguntaIndex: number) => {
                        if (pergunta.tipoResposta === TipoRespostaPerguntaAvaliacao.nota) {
                          perguntas.push(`P${categoriaIndex + 1}.${perguntaIndex + 1}`);
                        }
                      },
                    );
                  },
                );
                return `Perguntas: (${perguntas.join(', ')})`;
              }
            },
          },
        },
        {
          type: 'Control',
          scope: '#/properties/possuiFormulaPersonalizada',
          label: 'Utilizar fórmula personalizada',
        },
        {
          type: 'Control',
          scope: '#/properties/formula',
          rule: {
            effect: 'DISABLE',
            condition: {
              scope: '#/properties/possuiFormulaPersonalizada',
              schema: {
                const: false,
              },
            },
          } as Rule,
        },
        {
          type: 'Label',
          options: {
            formatOptionLabel: ({ totalFormula }: { totalFormula: number }) =>
              `[${totalFormula?.toFixed(2) ?? Number('0.00')}]`,
          },
        },
      ],
      rule: showRuleForPerguntasNotas(),
    },
  ],
  options: {
    variant: 'stepper',
    showNavButtons: true,
    icons: iconsConfig,
  },
};

export default gerenciarFormularioConsultoriaUiSchema;
