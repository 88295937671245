import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { Proposta } from '../../../models/proposta';

export const displayProperties = ['tituloDoProjeto'];

export const moduleLabel = 'Corte Orçamentario';

export const headCells: readonly HeadCell<Proposta>[] = [
  {
    id: 'tituloDoProjeto',
    numeric: false,
    disablePadding: false,
    label: 'Titulo',
  },
];
