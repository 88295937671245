import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ValidityChecker from '../../../components/validity-checker/validity-checker';
import { Edital } from '../../../models/edital';
import {
  ComponentBox,
  EditalHeaderLabel,
  EditalIcon,
  EditalList,
  FooterBox,
  FooterButton,
  Header,
  StyledBackgroundList,
  StyledBackgroundListItem,
  StyledBackgroundListItemButton,
  TodosEditaisAnchor,
} from './style/edital-home';
type EditalHomeProps = {
  apiResponse: {
    data: Edital[];
    count: number;
  };
  splitLength?: number;
};

export const EditalHome = ({ apiResponse, splitLength }: EditalHomeProps) => {
  const navigate = useNavigate();
  const [data, setData] = useState<Edital[]>([]);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    const data = Array.isArray(apiResponse) ? apiResponse : apiResponse.data;
    const count = Array.isArray(apiResponse) ? apiResponse.length : apiResponse.count;
    setData(data);
    setCount(count);
  }, [apiResponse]);

  const editalDetalhes = (id: number) => {
    navigate(`/edital-detalhes/${id}`);
  };

  if (data.length === 0) {
    return null;
  }

  return (
    <ComponentBox>
      <Header>
        <EditalIcon />
        <EditalHeaderLabel>Editais</EditalHeaderLabel>
      </Header>
      <Divider />
      <nav aria-label='main mailbox folders'>
        <StyledBackgroundList>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <StyledBackgroundListItem disablePadding onClick={() => editalDetalhes(item.id)}>
                <StyledBackgroundListItemButton>
                  <Box>
                    <EditalList>
                      {splitLength > 1 && item.nome.length > 80
                        ? `${item.nome.slice(0, 80)}...`
                        : item.nome}
                    </EditalList>
                    <ValidityChecker item={item} />
                  </Box>
                </StyledBackgroundListItemButton>
              </StyledBackgroundListItem>
            </React.Fragment>
          ))}
        </StyledBackgroundList>
        <FooterBox>
          <FooterButton>
            <TodosEditaisAnchor onClick={() => navigate('/todos-editais')}>
              {`Ver Mais (${count})`}
            </TodosEditaisAnchor>
          </FooterButton>
        </FooterBox>
      </nav>
    </ComponentBox>
  );
};
