import { GenericApi } from '../../../api/generic-api';
import { Proposta } from '../../../models/proposta';

export class AvaliacaoEnquadramentoApi extends GenericApi<Proposta> {
  constructor(public readonly editalId: number) {
    super('/proposta');
  }

  async getAllForPagination(options?: {
    [key: string]: any;
  }): Promise<{ data: Proposta[]; count: number }> {
    try {
      return await super.getAllForPagination({ ...options, editalId: this.editalId });
    } catch (e) {
      return { data: [], count: 0 };
    }
  }
}

export const makeAvaliacaoEnquadramento = (editalId: number) => {
  return new AvaliacaoEnquadramentoApi(editalId);
};
