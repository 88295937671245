import AccountBalance from '@mui/icons-material/AccountBalance';
import Apartment from '@mui/icons-material/Apartment';
import Article from '@mui/icons-material/Article';
import Assignment from '@mui/icons-material/Assignment';
import AttachFile from '@mui/icons-material/AttachFile';
import Backpack from '@mui/icons-material/Backpack';
import Badge from '@mui/icons-material/Badge';
import Business from '@mui/icons-material/Business';
import Checklist from '@mui/icons-material/Checklist';
import Class from '@mui/icons-material/Class';
import CurrencyExchange from '@mui/icons-material/CurrencyExchange';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Description from '@mui/icons-material/Description';
import Diversity3 from '@mui/icons-material/Diversity3';
import Draw from '@mui/icons-material/Draw';
import EventNote from '@mui/icons-material/EventNote';
import FileCopy from '@mui/icons-material/FileCopy';
import Folder from '@mui/icons-material/Folder';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import Handshake from '@mui/icons-material/Handshake';
import HistoryEdu from '@mui/icons-material/HistoryEdu';
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import NoteAlt from '@mui/icons-material/NoteAlt';
import Notifications from '@mui/icons-material/Notifications';
import OtherHouses from '@mui/icons-material/OtherHouses';
import Paid from '@mui/icons-material/Paid';
import People from '@mui/icons-material/People';
import Person from '@mui/icons-material/Person';
import School from '@mui/icons-material/School';
import Settings from '@mui/icons-material/Settings';
import SocialDistance from '@mui/icons-material/SocialDistance';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Timeline from '@mui/icons-material/Timeline';
import Today from '@mui/icons-material/Today';
import Wallet from '@mui/icons-material/Wallet';
import { authStringsFor } from '../../../../helpers/auth-strings-for';
import { CrudStatesOptions } from '../../../../jsonforms/uischema';
import {
  MegaphoneFill,
  Production,
  QuestionCircleFill,
  Report,
  StarRate,
} from '../../../styled-icons';
import { Item } from '../protocols/nav';

export const institutionItems: Item[] = [
  {
    route: '/instituicao',
    icon: <Business />,
    title: 'Instituição',
  },
  {
    route: '/unidade',
    icon: <Apartment />,
    title: 'Unidades',
  },
];

export const bagItems: Item[] = [
  {
    route: '/modalidade-bolsa',
    icon: <Backpack />,
    title: 'Modalidade de Bolsa',
  },
];

export const budgetItems: Item[] = [
  {
    route: '/instrumento-juridico',
    icon: <Handshake />,
    title: 'Instrumentos Jurídicos',
  },
  {
    route: '/linha-de-acao',
    icon: <Timeline />,
    title: 'Linhas de Ações',
  },
  {
    route: '/tesouro',
    icon: <Paid />,
    title: 'Tesouro',
  },
  {
    route: '/programa',
    icon: <Description />,
    title: 'Programas',
  },
];

export const noticesItems: Item[] = [
  {
    route: '/termo-aceite',
    icon: <Assignment />,
    title: 'Termo de Aceite',
    hasPermission: (moduleName, permissions) =>
      authStringsFor(`${moduleName ?? ''}.EXIBIR_NO_MENU`).some((x) => permissions.includes(x)),
  },
  {
    route: '/indicador-producao',
    icon: <Production width={25} height={25} />,
    title: 'Indicadores de Produção',
  },
  {
    route: '/edital',
    icon: <NoteAlt />,
    title: 'Publicar Edital',
    hasPermission: (moduleName, permissions) =>
      authStringsFor(`${moduleName ?? ''}.EXIBIR_NO_MENU`).some((x) => permissions.includes(x)),
  },
  {
    route: '/pergunta',
    icon: <Checklist width={25} height={25} />,
    title: 'Perguntas Propostas',
  },
];

export const peopleItems: Item[] = [
  {
    route: '/documento-pessoal',
    icon: <AttachFile />,
    title: 'Documentos Pessoais',
  },
  {
    route: '/usuario',
    icon: <People />,
    title: 'Usuários',
  },
  {
    route: '/perfil',
    icon: <Person />,
    title: 'Perfis',
  },
];

export const proposalItems: Item[] = [
  {
    route: '/proposta',
    icon: <Report />,
    title: 'Ver Propostas',
  },
];

export const evaluationItems: Item[] = [
  {
    route: '/pergunta-avaliacao',
    icon: <QuestionCircleFill width={20} height={20} />,
    title: 'Perguntas de Avaliação',
  },
  {
    route: '/formulario-enquadramento',
    icon: <Description />,
    title: 'Formulário de Enquadramento',
  },
  {
    route: '/formulario-consultoria-edital',
    icon: <ManageSearchIcon />,
    title: 'Formulário de Consultoria',
  },
];

export const initialSettingsItems: Item[] = [
  {
    route: '/notificacao',
    icon: <Notifications />,
    title: 'Notificações',
    state: CrudStatesOptions.VIEW,
  },

  {
    route: '/departamento-fundacao',
    icon: <OtherHouses />,
    title: 'Departamentos',
  },
  {
    route: '/dados-fundacao',
    icon: <Badge />,
    title: 'Perfil Fundação',
    state: CrudStatesOptions.VIEW,
  },
  {
    route: '/valor-diaria',
    icon: <Today />,
    title: 'Diárias',
  },
  {
    route: '/banco',
    icon: <AccountBalance />,
    title: 'Bancos',
  },
  {
    route: '/rubrica',
    icon: <Draw />,
    title: 'Rubricas',
  },
  {
    route: '/tipo-instrumento-juridico',
    icon: <FileCopy />,
    title: 'Tipos de Instrumento Jurídico',
  },
  {
    route: '/moeda-estrangeira',
    icon: <CurrencyExchange />,
    title: 'Moedas Estrangeiras',
  },
  {
    route: '/raca-cor',
    icon: <Diversity3 />,
    title: 'Raça/Cor',
    hasPermission: (moduleName, permissions) =>
      authStringsFor(`${moduleName ?? ''}.EXIBIR_NO_MENU`).some((x) => permissions.includes(x)),
  },
  {
    route: '/nivel-academico',
    icon: <School />,
    title: 'Níveis Acadêmicos',
    hasPermission: (moduleName, permissions) =>
      authStringsFor(`${moduleName ?? ''}.EXIBIR_NO_MENU`).some((x) => permissions.includes(x)),
  },
  {
    route: '/area-de-conhecimento',
    icon: <Class />,
    title: 'Áreas de Conhecimento',
  },
  {
    route: '/natureza-despesa',
    icon: <CurrencyExchange />,
    title: 'Natureza da Despesa',
  },
  {
    route: '/tipo-evento',
    icon: <EventNote />,
    title: 'Tipos de Eventos',
  },
  {
    route: '/funcao-participante',
    icon: <SocialDistance />,
    title: 'Funções Participantes',
  },
  {
    route: '/funcional-programatica',
    icon: <FormatListNumberedIcon />,
    title: 'Funcionais Programáticas',
  },
  {
    route: '/termo-politica-privacidade',
    icon: <Article />,
    title: 'Termo de Política e Privacidade',
    state: CrudStatesOptions.VIEW,
    hasPermission: (moduleName, permissions) =>
      authStringsFor(`${moduleName ?? ''}.EXIBIR_NO_MENU`).some((x) => permissions.includes(x)),
  },
  {
    route: '/aviso',
    icon: <MegaphoneFill width={20} height={20} />,
    title: 'Avisos',
  },
];

export const noticesSubItems: Item[] = [
  {
    route: '/documento-pessoal',
    icon: <AttachFile />,
    title: 'Documentos Pessoais',
  },
];

export const navItems = {
  dashboard: {
    title: 'Painel',
    icon: <DashboardIcon />,
    items: [],
  },
  institution: {
    title: 'Instituição',
    icon: <AccountBalance />,
    items: institutionItems,
  },
  budget: {
    title: 'Orçamento',
    icon: <Wallet />,
    items: budgetItems,
  },
  bag: {
    title: 'Bolsas',
    icon: <Backpack />,
    items: bagItems,
  },
  notices: {
    title: 'Edital',
    icon: <HistoryEdu />,
    items: noticesItems,
  },
  people: {
    title: 'Pessoas',
    icon: <ManageAccounts />,
    items: peopleItems,
  },
  proposal: {
    title: 'Propostas',
    icon: <Folder />,
    items: proposalItems,
  },
  evaluation: {
    title: 'Avaliação',
    icon: <StarRate width={25} height={25} />,
    items: evaluationItems,
  },
  initialSettings: {
    title: 'Configurações Iniciais',
    icon: <Settings />,
    items: initialSettingsItems,
  },
};
