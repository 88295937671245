import { IMathNode } from './i-math-node';

export class ConstNode implements IMathNode {
  readonly value: number;
  constructor(value: number) {
    this.value = value;
  }
  solve(): number {
    return this.value;
  }
  listVars(): string[] {
    return [];
  }
}
