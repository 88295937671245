import { CrudContextData, IFieldsConfig, IStepCallback } from '../../../jsonforms/uischema';
import { Contratacao } from '../../../models/contratacao';
import { stepHandlerErrors } from '../../../utils/step-handler-errors';

export const stepHandler = async (
  fieldsConfig: IFieldsConfig,
  ctx: CrudContextData,
  data: Contratacao,
  url: string,
  validator: (contratacao: Contratacao) => boolean,
  errorMessage: string,
  callback?: IStepCallback,
): Promise<boolean | void> => {
  const { crudStates, formData, showError } = ctx;
  const errorsResult = await stepHandlerErrors(fieldsConfig, ctx, data, url);

  if (errorsResult) {
    const isValid = crudStates.edit || crudStates.add ? validator(formData) : true;

    if (callback && isValid) {
      return callback(ctx, fieldsConfig);
    }

    if (!isValid) {
      showError(errorMessage);
    }
    return isValid;
  }
  return false;
};

export const stepHandlerContratacao = async (
  fieldsConfig: IFieldsConfig,
  ctx: CrudContextData,
  data: Contratacao,
  url: string,
  callback?: IStepCallback,
): Promise<boolean | void> => {
  return stepHandler(
    fieldsConfig,
    ctx,
    data,
    url,
    validarExecucao,
    'Datas de execução devem estar no intervalo de vigência.',
    callback,
  );
};

export const stepHandlerPrestacaoDeConta = async (
  fieldsConfig: IFieldsConfig,
  ctx: CrudContextData,
  data: Contratacao,
  url: string,
  callback?: IStepCallback,
): Promise<boolean | void> => {
  return stepHandler(
    fieldsConfig,
    ctx,
    data,
    url,
    validarPrestacoes,
    'Datas de prestação de contas devem estar no intervalo de vigência.',
    callback,
  );
};

const isDateInRange = (date?: Date, inicioVigencia?: Date, terminoVigencia?: Date): boolean =>
  !date || (date >= inicioVigencia && date <= terminoVigencia);

const validarExecucao = (contratacao: Contratacao): boolean => {
  const { inicioVigencia, terminoVigencia, inicioExecucao, terminoExecucao } = contratacao;
  return (
    isDateInRange(inicioExecucao, inicioVigencia, terminoVigencia) &&
    isDateInRange(terminoExecucao, inicioVigencia, terminoVigencia)
  );
};

const validarPrestacoes = (contratacao: Contratacao): boolean => {
  const { inicioVigencia, terminoVigencia, prestacaoDeConta } = contratacao;
  return (
    prestacaoDeConta?.every((p) => isDateInRange(p.data, inicioVigencia, terminoVigencia)) ?? true
  );
};
