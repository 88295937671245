import { PeopleCommunityAdd } from '../../components/styled-icons';
import { useNavigate } from 'react-router-dom';
import { GenericApi, makeApi } from '../../api/generic-api';
import { Crud } from '../../components/crud/crud';
import { useCrudContext } from '../../components/crud/crud-context';
import { moduleNameFor } from '../../helpers/module-name-for';
import { Edital as EditalModel } from '../../models/edital';
import { makeCustomRequiredIfDefineMonth, makeRangeValidationMonth } from './edital-errors';
import { headCells } from './edital.headcell';
import editalUischema from './edital.uischema';
import { useEffect, useMemo, useState } from 'react';
import { Estado } from '../../models/estado';
import { useAuthContext } from '../login/auth/auth-context';

function Edital() {
  const navigate = useNavigate();
  const { parameterFilters, setParameterFilters } = useCrudContext();
  const customErrors = [
    makeCustomRequiredIfDefineMonth('duracaoProjetoEmMeses'),
    makeRangeValidationMonth('duracaoProjetoEmMeses', 100),
  ];

  const [api, setApi] = useState<GenericApi<EditalModel>>();
  const { configuracoesFundacao } = useAuthContext();
  const sigla = configuracoesFundacao?.instituicao?.endereco?.estado;

  const [estados, setEstados] = useState([]);
  const estadoApi = useMemo(() => makeApi<Estado>('/estado'), []);

  useEffect(() => {
    const getEstado = async () => {
      const response = await estadoApi.getAll();
      const estadoEncontrado = response.find((item: Estado) => item.sigla === sigla);

      if (estadoEncontrado) {
        setEstados([estadoEncontrado]);
      }
    };

    if (sigla) {
      getEstado();
    }
  }, []);

  useEffect(() => {
    setApi(
      makeApi<EditalModel>('/edital', {
        customPaths: { getAll: '/private' },
        orderRelationBy: {
          orderEditalPerguntaBy: (a, b) => a.posicao - b.posicao,
          orderInformacaoComplementarEditalPerguntaBy: (a, b) => a.posicao - b.posicao,
          editalPergunta: {
            orderMultiplaEscolhaBy: (a, b) => a.posicao - b.posicao,
          },
          informacaoComplementarEditalPergunta: {
            orderMultiplaEscolhaBy: (a, b) => a.posicao - b.posicao,
          },
        },
      }),
    );
  }, []);

  const defaultForm = {
    restricaoTipoEvento: false,
    temMoedaEstrangeira: false,
    restricaoParticipante: false,
    tipoEventoEdital: [],
    funcaoParticipanteEdital: [],
    restricaoConviteParticipante: false,
    coordenadorPodeReceberBolsa: false,
    vinculoInstitucionalObrigatorio: false,
    vinculoEmpregaticioObrigatorio: false,
    possuiNivelAcademicoMinimoCoordenador: false,
    coordenadorPodeParticiparOutroProjeto: false,
    obrigatorioLinkLattesCoordenador: false,
    obrigatorioLinkLattesMembros: false,
    definirDuracaoProjetoEmMeses: false,
    editalEstado: estados,
  };

  const conviteParticipantes = {
    hasPermission: ['EXIBIR_NO_MENU', 'BUSCAR_TODOS'],
    moduleName: moduleNameFor('convite-participantes'),
    icon: <PeopleCommunityAdd size='24' color='#009900' />,
    title: 'Convidar Participantes',
    handler: (row: EditalModel) => {
      setParameterFilters({
        ...parameterFilters,
        edital: row,
      });
      navigate(`/edital/${row?.id}/convite-participantes`);
    },
  };

  const showConviteParticipantes = (row: EditalModel) => {
    if (row.deletedAt === null && row.restricaoConviteParticipantes === true)
      return conviteParticipantes;
  };

  return (
    <Crud<EditalModel>
      headCells={headCells}
      titleConfig={{ value: 'Publicar Edital', show: true }}
      uischema={editalUischema}
      defaultForm={defaultForm}
      customErrors={customErrors}
      queryFilters={{ withDeleted: true, orderBy: 'createdAt', orderDirection: 'DESC' }}
      apiUrl={'/edital'}
      customActions={[showConviteParticipantes]}
      apiClient={api}
      customSave={{ show: false }}
    ></Crud>
  );
}

export default Edital;
