import Autocomplete from '@mui/material/Autocomplete';
import Hidden from '@mui/material/Hidden';
import TextField from '@mui/material/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React from 'react';
import { useCrudContext } from '../../../components/crud/crud-context';
import { getFirstError } from '../../../utils/get-first-error';
import { IControlElement } from '../../uischema/uischema.type';
import '../renderer.css';
import { CustomSelectProps, getLabel, handleChange } from './select-utils';

export type OneOfItem = { title: string; const: number | string };
export type OneOf = OneOfItem[];

export function SelectOneOf(props: CustomSelectProps) {
  const {
    path,
    selectedItem,
    setSelectedItem,
    oneOf,
    isVisible,
    isDisabled,
    uischema,
    apiUrl,
    options,
  } = props;

  const ctx = useCrudContext();

  const { validationMode, formData, isRequiredField } = ctx;

  const iconBuilder = uischema.options?.iconBuilder;

  const [filteredOptions, setFilteredOptions] = React.useState(options);

  const handler = uischema.options?.filter?.handler;

  React.useEffect(() => {
    if (handler && Array.isArray(options) && options.length > 0) {
      handler(formData, options).then((resolvedOptions: any[]) => {
        setFilteredOptions(resolvedOptions);
      });
    } else {
      setFilteredOptions(Array.isArray(options) ? options : []);
    }
  }, [options, formData]);

  // Carrega o item selecionado
  React.useEffect(() => {
    if (props.data || Array.isArray(oneOf)) {
      const one = oneOf?.find?.((item: any) => item?.const == props?.data);
      if (one) {
        setSelectedItem({
          label: one.title,
          value: one.const,
        });
      }
    }
  }, [props.data, oneOf]);

  return (
    <Hidden xsUp={!isVisible} key={`${path}-select-one-of`}>
      <div className='custom-input-container'>
        <Autocomplete
          className={`${
            (isDisabled && 'disabled-field') ||
            (props.errors && validationMode === 'ValidateAndShow' && 'has-error')
          }`}
          options={filteredOptions}
          value={selectedItem}
          getOptionLabel={(option: any) => String(option?.label || '')}
          onChange={(_, content) =>
            handleChange(content, uischema as IControlElement, apiUrl, props, ctx, setSelectedItem)
          }
          isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
          disabled={isDisabled}
          noOptionsText={'Sem opções'}
          renderInput={(params) => (
            <TextField
              {...params}
              size='small'
              label={getLabel(uischema as IControlElement, apiUrl)}
              required={isRequiredField(formData, ctx)}
            />
          )}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option.label, inputValue, {
              insideWords: true,
            });
            const parts = parse(option?.label, matches);

            return (
              <li {...props}>
                <div>
                  {iconBuilder && iconBuilder(option)}
                  {parts?.map((part, index) => (
                    <span
                      key={`${part?.text}-${index}`}
                      style={{
                        color: part?.highlight ? '#1E90FF' : '#333333',
                      }}
                    >
                      {part?.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
        />
        {validationMode === 'ValidateAndShow' && props.errors && (
          <span className='error-message'>{getFirstError(props.errors)}</span>
        )}
      </div>
    </Hidden>
  );
}
