import { HeadCell } from '../../components/crud/protocols/head-cell';
import { Proposta } from '../../models/proposta';

export const displayProperties = ['tituloDoProjeto'];

export const moduleLabel = 'Propostas';

export const moduleName = 'PROPOSTA.PROPOSTA';

export const headCells: readonly HeadCell<Proposta>[] = [
  {
    id: 'editalId',
    numeric: true,
    disablePadding: false,
    label: 'Título do Edital',
    resource: {
      name: 'edital',
      target: 'nome',
    },
  },
  {
    id: 'tituloDoProjeto',
    numeric: false,
    disablePadding: false,
    label: 'Título do Projeto',
  },
  {
    id: 'protocolo',
    numeric: true,
    disablePadding: false,
    label: 'Protocolo',
  },
  {
    id: 'criadoPorId',
    numeric: true,
    disablePadding: false,
    label: 'Nome do Coordenador',
    resource: {
      name: 'criadoPor',
      target: 'nome',
    },
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Data de Criação',
    dateFormat: 'DD/MM/YYYY',
  },
];
