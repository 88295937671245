import { HeadCell } from '../../../components/crud/protocols/head-cell';
import { Proposta } from '../../../models/proposta';

export const moduleName = 'AVALIACAO.FORMULARIO_ENQUADRAMENTO.FORMULARIO_ENQUADRAMENTO';

export const moduleLabel = 'Avaliação de Enquadramento';

export const headCells: readonly HeadCell<Proposta>[] = [
  {
    id: 'tituloDoProjeto',
    numeric: false,
    disablePadding: false,
    label: 'Título da Proposta',
  },
  {
    id: 'criadoPorId',
    numeric: false,
    disablePadding: false,
    label: 'Nome do Coordenador',
    computedRowLabel: (row) => row.criadoPor?.nome,
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Data de Criação',
    dateFormat: 'DD/MM/YYYY',
  },
];
