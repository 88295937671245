import { rankWith, uiTypeIs } from '@jsonforms/core';
import Hidden from '@mui/material/Hidden';
import TextField from '@mui/material/TextField';
import { ChangeEvent, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useCrudContext } from '../../components/crud/crud-context';
import { getFirstError } from '../../utils/get-first-error';
import { IControlElement } from '../uischema';
import { withJsonFormsCustomProps } from '../with-jsonforms-custom-props';
import './renderer.css';
import { InputBaseComponentProps } from '@mui/material';

const longTextRenderTester = rankWith(5, uiTypeIs('LongText'));

export const longTextRender = {
  tester: longTextRenderTester,
  renderer: withJsonFormsCustomProps(({ visible = true, path, schema, enabled, ...props }) => {
    const uischema = props.uischema as IControlElement;
    const fieldRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const position = useRef({
      beforeStart: 0,
      beforeEnd: 0,
    });

    const [value, setValue] = useState<string>(props.data ?? '');
    const [maxLength, setMaxLength] = useState<number | null>(null);
    const [minLength, setMinLength] = useState<number | null>(null);
    const ctx = useCrudContext();
    const { validationMode, crudStates, disabledFields } = ctx;
    const visibleHandler = uischema?.options?.visibleHandler;
    const getRequired = () => {
      if (uischema?.options?.required) {
        if (typeof uischema?.options?.required === 'boolean') {
          return uischema?.options?.required;
        } else if (typeof uischema?.options?.required === 'function') {
          return uischema?.options?.required(ctx);
        } else {
          return false;
        }
      } else {
        return props?.required ?? false;
      }
    };

    const getLabel = () => {
      if (uischema?.options?.label) {
        if (typeof uischema?.options?.label === 'string') {
          return uischema?.options?.label;
        } else if (typeof uischema?.options?.label === 'function') {
          return uischema?.options?.label(ctx);
        } else {
          return '';
        }
      } else {
        return props?.label ?? '';
      }
    };

    const label = getLabel();
    const required = getRequired();

    // keep cursor position after setting value
    useLayoutEffect(() => {
      inputRef?.current?.setSelectionRange(
        position.current.beforeStart,
        position.current.beforeEnd,
      );
    }, [value]);

    useEffect(() => {
      // Pega min e max do schema
      schema?.minLength && setMinLength(schema?.minLength);
      schema?.maxLength && setMaxLength(schema?.maxLength);

      // Sobrescreve min e max do options do uischema
      uischema?.options?.minLength && setMinLength(Number(uischema?.options?.minLength));
      uischema?.options?.maxLength && setMaxLength(Number(uischema?.options?.maxLength));
    }, []);

    useEffect(() => {
      fieldRef?.current?.childNodes?.forEach?.((childNode) => {
        const node = childNode as HTMLElement;
        const htmlInput = node.children?.[1]?.children?.[0];
        if (
          childNode.nodeType === 1 &&
          (node.tagName === 'INPUT' || htmlInput?.tagName === 'INPUT')
        ) {
          const inputElement = childNode as HTMLInputElement;
          if (inputElement && props.data !== undefined) {
            inputElement.value = props.data;
          } else if (inputElement) {
            inputElement.value = '';
          }
        }
      });
      setValue(props.data);
    }, [props.data]);

    const isVisible = useMemo(() => {
      const visibility = visibleHandler ? visibleHandler?.(ctx, null, null) : true;
      return visible && visibility;
    }, [visible, crudStates, visibleHandler]);

    const isDisabled =
      !enabled ||
      crudStates.view ||
      (crudStates.edit && uischema?.options?.onlyCreate) ||
      uischema?.options?.disabled ||
      disabledFields.includes(path);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const beforeStart = event.target.selectionStart ?? 0;
      const beforeEnd = event.target.selectionEnd ?? 0;
      position.current = {
        beforeStart,
        beforeEnd,
      };

      const val = event?.target?.value;
      setValue(val);
      props.handleChange(path, val);
    };

    const inputProps: InputBaseComponentProps = {
      disableUnderline: true,
      maxLength: maxLength ?? undefined,
      minLength: minLength ?? undefined,
    };

    return (
      <Hidden xsUp={!isVisible}>
        <div className='custom-input-container' ref={fieldRef}>
          <TextField
            id={`${path}${uischema?.options?.arrayProps?.id}`}
            size='small'
            multiline
            className={`${isDisabled ? 'disabled-field' : ''}
              ${props.errors && validationMode === 'ValidateAndShow' ? 'has-error' : ''}
            `}
            label={label}
            required={required}
            disabled={isDisabled}
            value={value}
            onChange={handleChange}
            minRows={4}
            inputProps={inputProps}
          />
          {validationMode === 'ValidateAndShow' && props.errors && (
            <span className='error-message'>{getFirstError(props.errors)}</span>
          )}
        </div>
      </Hidden>
    );
  }),
};
