import { makeApi } from '../../../api/generic-api';
import { CrudContextData, IFieldsConfig, IStepCallback } from '../../../jsonforms/uischema';

export const stepHandlerAbrangencia = async (
  fieldsConfig: IFieldsConfig,
  ctx: CrudContextData,
  data: any,
  url: string,
  callback?: IStepCallback,
): Promise<boolean | void> => {
  const { formData, showError } = ctx;

  const api = makeApi('/proposta');

  const abrangencia = formData?.abrangencia;

  if (abrangencia?.length) {
    const isInvalid = abrangencia.some((x: any) => !x.estadoId);

    if (isInvalid) {
      showError('Informe um estado.');
      return false;
    }
  }

  try {
    const putResponse = await api?.put?.(formData?.id, { abrangencia });
    if (putResponse.status === 200) {
      return true;
    } else {
      console.error(putResponse);
      return false;
    }
  } catch (error: any) {
    let errorMessage = error?.cause?.response?.data?.message || error?.response?.data?.message;
    showError(errorMessage || 'Ocorreu um erro.');
    console.error(errorMessage);
    return false;
  }
};
