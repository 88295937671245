import { IUiSchema } from '../../../jsonforms/uischema';
import { getOrcamentoPropostaUiSchema } from '../orcamento-proposta.uischema';

const corteOrcamentarioUiSchema: IUiSchema = {
  type: 'CategorizationMenu',
  elements: [getOrcamentoPropostaUiSchema('/proposta', { put: '/corte-orcamentario' })],
  options: {
    variant: 'stepper',
    showNavButtons: true,
    showEditButton: true,
    showProgress: false,
    route: '/proposta',
    customPaths: { put: '/corte-orcamentario' },
  },
};

export default corteOrcamentarioUiSchema;
