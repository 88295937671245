import { Crud } from '../../../components/crud/crud';
import { headCells } from './formulario-enquadramento.headcell';
import { Edital } from '../../../models/edital';
import { GenericApi, makeApi } from '../../../api/generic-api';
import { useCrudContext } from '../../../components/crud/crud-context';
import { useNavigate } from 'react-router-dom';
import { Settings } from '../../../components/styled-icons';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { useEffect, useState } from 'react';

function EditalFormularioEnquadramentoView() {
  const navigate = useNavigate();
  const [api, setApi] = useState<GenericApi<Edital>>(() =>
    makeApi<Edital>('/edital', { customPaths: { getAll: '/private' } }),
  );
  const { parameterFilters, setParameterFilters } = useCrudContext();

  const customActions = [
    (row: Edital) => ({
      hasPermission: ['EXIBIR_NO_MENU', 'BUSCAR_TODOS'],
      moduleName: moduleNameFor('formulario-enquadramento'),
      icon: <Settings size='24' color='#009900' />,
      title: 'Gerenciar Formulários',
      handler: () => {
        setParameterFilters({
          ...parameterFilters,
          edital: row,
        });
        navigate(`/formulario-enquadramento/${row?.id}/gerenciar-formularios`);
      },
    }),
  ];

  useEffect(() => {
    setApi(
      makeApi<Edital>('/edital', {
        customPaths: { getAll: '/private' },
      }),
    );
  }, []);

  return (
    <Crud<Edital>
      headCells={headCells}
      titleConfig={{ value: 'Formulário de Enquadramento', show: true }}
      customActions={customActions}
      apiUrl={'/formulario-enquadramento'}
      apiClient={api}
      queryFilters={{
        withDeleted: true,
        orderBy: 'createdAt',
        orderDirection: 'DESC',
      }}
      hideView
      hideDelete
      hideCreate
      hideUpdate
    ></Crud>
  );
}

export default EditalFormularioEnquadramentoView;
