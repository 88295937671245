import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { makeApi } from '../../../../../api/generic-api';
import JsonForm from '../../../../../components/crud/json-form';
import { useCrudContext } from '../../../../../components/crud/crud-context';
import { CrudStatesOptions } from '../../../../../jsonforms/uischema';
import { FormularioConsultoria } from '../../../../../models/formulario-consultoria';
import {
  buildFormularioUiSchema,
  buildSchemaFormularioConsultoria,
} from '../../../../../utils/gerenciar-formulario.utils';

const PerguntaAvaliacaoDetalhes = () => {
  const { formularioConsultoriaGerenciarDetalhesId } = useParams();
  const [isCategorized, setIsCategorized] = useState(false);
  const [formularioConsultoria, setFormularioConsultoria] = useState<any>(null);
  const formularioConsultoriaId = Number(formularioConsultoriaGerenciarDetalhesId ?? null) ?? null;

  const { updateCrudStates } = useCrudContext();
  const location = useLocation();

  useEffect(() => {
    updateCrudStates(location.state?.crudState ?? CrudStatesOptions.VIEW);
    makeApi('/formulario-consultoria')
      .get(formularioConsultoriaId)
      .then((x: FormularioConsultoria) => {
        setFormularioConsultoria(x);
        setIsCategorized(x?.formularioConsultoriaCategorizado?.length > 0);
      });
  }, []);

  const uischema = buildFormularioUiSchema(
    isCategorized,
    formularioConsultoria?.formularioConsultoriaCategorizado,
  );
  const schema = buildSchemaFormularioConsultoria(formularioConsultoria, isCategorized);

  return <JsonForm uischema={uischema} schema={schema} data={{}} />;
};

export default PerguntaAvaliacaoDetalhes;
