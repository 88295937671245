import {
  bootstrap,
  boxiconsRegular,
  boxiconsSolid,
  entypo,
  evaiconsOutline,
  evaiconsSolid,
  evil,
  fluentuiSystemFilled,
  fluentuiSystemRegular,
  foundation,
  heroiconsSolid,
  icomoon,
  ioniconsOutline,
  ioniconsSharp,
  material,
  materialOutlined,
  remixLine,
  simpleIcons,
  typicons,
  zondicons,
  feather,
  faSolid,
} from 'styled-icons';

export const {
  CardChecklist,
  Check2,
  CloudUpload,
  EyeFill,
  FiletypeDoc,
  FiletypeJpg,
  FiletypePdf,
  FiletypeXls,
  Gear,
  BriefcaseFill,
  BagPlusFill,
  Calendar2Week,
  ListCheck,
  Receipt,
  Trash3,
  MegaphoneFill,
  QuestionCircleFill,
  PersonXFill,
} = bootstrap;
export const { Money, Calendar } = boxiconsRegular;
export const { Report, User, CheckSquare, UserDetail, PlaneTakeOff, DownArrow } = boxiconsSolid;
export const { ClipboardPencil, Paperclip } = foundation;
export const {
  MoneyCalculator,
  Home,
  BuildingMultiple,
  Person,
  PeopleCommunityAdd,
  Add,
  MailOpenPerson,
} = fluentuiSystemFilled;
export const { BuildingBankToolbox, MoneyHand, AttachText, Production, NoteEdit } =
  fluentuiSystemRegular;
export const { Business } = materialOutlined;
export const { Attachment } = typicons;
export const { Star, Info, Settings } = evaiconsSolid;
export const { Profile, Survey } = remixLine;
export const { Blocked } = icomoon;
export const { AcademicCap } = heroiconsSolid;
export const { DocumentText, Close } = ioniconsOutline;
export const { DocumentAttach } = ioniconsSharp;
export const { AddLocationAlt, StarRate, Reviews } = material;
export const { Justeat } = simpleIcons;
export const { Eye } = entypo;
export const { Search } = evil;
export const { EyeOffOutline, EyeOutline } = evaiconsOutline;
export const { Download } = feather;
export const { ViewShow } = zondicons;
export const { FileSignature } = faSolid;
