import { JsonSchema } from '@jsonforms/core';
import { GenericApi } from '../../../api/generic-api';
import { AvaliacaoProposta } from '../../../models/avaliacao-proposta';
import client from '../../../config/axios-config';

export class AvaliacaoEnquadramentoApi extends GenericApi<AvaliacaoProposta> {
  constructor(
    public readonly formularioEnquadramentoId: number,
    public readonly propostaId: number,
  ) {
    super('/avaliacao-proposta');
  }

  async getAllForPagination(options?: {
    [key: string]: any;
  }): Promise<{ data: AvaliacaoProposta[]; count: number }> {
    try {
      return await super.getAllForPagination({
        ...options,
        propostaId: this.propostaId,
      });
    } catch (e) {
      return { data: [], count: 0 };
    }
  }

  async getSchema(): Promise<JsonSchema | undefined> {
    try {
      const response = await client.get<JsonSchema>(
        `${this.url}/schema?formularioEnquadramentoId=${this.formularioEnquadramentoId}`,
      );
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      throw new Error('GetSchema error: ', { cause: error });
    }
  }
}

export const makeAvaliacaoEnquadramento = (
  formularioEnquadramentoId: number,
  propostaId: number,
) => {
  return new AvaliacaoEnquadramentoApi(formularioEnquadramentoId, propostaId);
};
