import { AbrangenciaMunicipio } from './abrangencia-municipio';
import { AreaDeConhecimento } from './area-de-conhecimento';
import { BolsaProposta } from './bolsa-proposta';
import { DiariaProposta } from './diaria-proposta';
import { Edital } from './edital';
import { Estado } from './estado';
import { FormularioConsultoria } from './formulario-consultoria';
import { FuncaoParticipante } from './funcao-participante';
import { MoedaEstrangeira } from './moeda-estrangeira';
import { Pais } from './pais';
import { Pergunta } from './pergunta';
import { PessoalProposta } from './pessoal-proposta';
import { Usuario, UsuarioPersistido } from './usuario';

export enum StatusProposta {
  emEdicao = 'EM_EDICAO',
  submetido = 'SUBMETIDO',
}

export type StatusPropostaI18N = {
  [key in StatusProposta]: string;
};

export const statusPropostaI18N: StatusPropostaI18N = {
  [StatusProposta.emEdicao]: 'Em Edição',
  [StatusProposta.submetido]: 'Submetido',
};

export interface Proposta {
  id: number;
  editalId: number;
  edital: Partial<Edital>;
  tituloDoProjeto: string;
  statusProposta: string;
  protocolo: string;
  criadoPorId: number;
  criadoPor: UsuarioPersistido;
  createdAt: Date;
  deletedAt: Date;
  areaDeConhecimento?: AreaDeConhecimento[];
  abrangencia?: Abrangencia[];
  instituicaoExecutoraId: number;
  unidadeExecutoraId: number;
  duracao: number;
  termoDeAceiteAceito: boolean;
  faixaFinanciamentoEditalId: number;
  passagemProposta?: PassagemProposta[];
  propostaAtividade: PropostaAtividade[];
  diariaProposta?: DiariaProposta[];
  hospedagemAlimentacaoProposta?: HospedagemAlimentacaoProposta[];
  materialPermanenteProposta?: MaterialPermanenteProposta[];
  materialConsumoProposta?: MaterialConsumoProposta[];
  producaoProposta?: ProducaoProposta[];
  servicoTerceiroProposta?: ServicoTerceiroProposta[];
  encargoProposta?: EncargoProposta[];
  bolsaProposta?: BolsaProposta[];
  pessoalProposta?: PessoalProposta[];
  propostaCotacaoMoedaEstrangeira: PropostaCotacaoMoedaEstrangeira[];
  propostaConviteMembro: PropostaConviteMembro[];
  formularioPropostaDescritiva: Pergunta[];
  revisaoTecnica: string;
}

export interface Abrangencia {
  id?: number;
  propostaId: number;
  proposta: Proposta;
  estadoId: number;
  estado: Estado;
  abrangenciaMunicipio?: AbrangenciaMunicipio[];
}

export interface ServicoTerceiroProposta {
  id?: number;
  tipoPessoa: TipoPessoa;
  tipo: TipoPessoa;
  custoUnitario: number;
  mesPrevisto: number;
  especificacao?: string;
  entidade: string;
  contrapartida?: boolean;
  temMoedaEstrangeira?: boolean;
  moedaEstrangeiraId: number;
  moedaEstrangeira?: MoedaEstrangeira;
  propostaId: number;
  proposta?: Proposta;
}

export interface EncargoProposta {
  id?: number;
  tipoPessoa: TipoPessoa;
  custoUnitario: number;
  especificacao?: string;
  entidade: string;
  contrapartida?: boolean;
  mesPrevisto: number;
  temMoedaEstrangeira?: boolean;
  moedaEstrangeiraId: number;
  moedaEstrangeira?: MoedaEstrangeira;
  propostaId: number;
  proposta?: Proposta;
}

export interface HospedagemAlimentacaoProposta {
  id?: number;
  tipoPessoa: TipoPessoa;
  custoUnitario: number;
  valorTotal?: number;
  quantidade: number;
  mesPrevisto: number;
  especificacao?: string;
  entidade: string;
  contrapartida?: boolean;
  temMoedaEstrangeira?: boolean;
  estadoRegiao?: string;
  municipio?: string;
  moedaEstrangeiraId: number;
  moedaEstrangeira?: MoedaEstrangeira;
  propostaId: number;
  proposta?: Proposta;
  paisId: number;
  pais?: Pais;
  estadoId: number;
  estado: Estado;
}

enum TipoPassagemProposta {
  aerea = 'AEREA',
  terrestre = 'TERRESTRE',
  fluvial = 'FLUVIAL',
  transFluvial = 'TRANS_FLUVIAL',
}

export type TipoPassagemPropostaI18N = {
  [key in keyof typeof TipoPassagemProposta]: string;
};

export const tipoPassagemPropostaI18N: TipoPassagemPropostaI18N = {
  aerea: 'Aérea',
  terrestre: 'Terrestre',
  fluvial: 'Fluvial',
  transFluvial: 'Trans Fluvial',
};

export enum TipoPessoa {
  pessoaJuridica = 'PESSOA_JURIDICA',
  pessoaFisica = 'PESSOA_FISICA',
}

export type TipoPessoaI18N = {
  [key in TipoPessoa]: string;
};

export const tipoPessoaI18N: TipoPessoaI18N = {
  PESSOA_JURIDICA: 'Pessoa Jurídica',
  PESSOA_FISICA: 'Pessoa Física',
};

export enum TrechoPassagemProposta {
  nacional = 'NACIONAL',
  internacional = 'INTERNACIONAL',
}

export type TrechoPassagemPropostaI18N = {
  [key in TrechoPassagemProposta]: string;
};

export const trechoPassagemPropostaI18N: TrechoPassagemPropostaI18N = {
  NACIONAL: 'Nacional',
  INTERNACIONAL: 'Internacional',
};

export interface PassagemProposta {
  id: number;
  trecho?: TrechoPassagemProposta;
  idaVolta?: boolean;
  tipo?: TipoPassagemProposta;
  estadoOrigemId?: number;
  estadoOrigem?: Estado;
  municipioOrigem?: string;
  paisOrigemId?: number;
  paisOrigem?: Pais;
  estadoRegiaoOrigem?: string;
  estadoDestinoId?: number;
  estadoDestino?: Estado;
  paisDestinoId?: number;
  paisDestino?: Pais;
  municipioDestino?: string;
  estadoRegiaoDestino?: string;
  custoUnitario: number;
  quantidade: number;
  temMoedaEstrangeira?: boolean;
  moedaEstrangeiraId?: number;
  moedaEstrangeira?: MoedaEstrangeira;
  valorTotal?: number;
  mesPrevisto?: number;
  contrapartida?: boolean;
  entidade?: string;
  tipoEntidade?: TipoPessoa;
  propostaId?: number;
  proposta?: Proposta;
  propostaCotacaoMoedaEstrangeira: PropostaCotacaoMoedaEstrangeira[];
}

export enum TipoUnidadeMedida {
  unidade = 'UNIDADE',
  quilograma = 'QUILOGRAMA',
  litro = 'LITRO',
  metro = 'METRO',
  outros = 'OUTROS',
}

export interface MaterialConsumoProposta {
  id?: number;
  tipoPessoa: TipoPessoa;
  tipoUnidadeMedida: TipoUnidadeMedida;
  custoUnitario: number;
  quantidade: number;
  valorTotal: number;
  mesPrevisto: number;
  especificacao: string;
  entidade: string;
  contrapartida?: boolean;
  temMoedaEstrangeira?: boolean;
  moedaEstrangeiraId: number;
  moedaEstrangeira?: MoedaEstrangeira;
  propostaId: number;
  proposta?: Proposta;
}

export enum TipoOrigem {
  nacional = 'NACIONAL',
  importado = 'IMPORTADO',
}

export type TipoOrigemI18N = {
  [key in TipoOrigem]: string;
};

export const tipoOrigemI18N: TipoOrigemI18N = {
  NACIONAL: 'Nacional',
  IMPORTADO: 'Importado',
};

export interface MaterialPermanenteProposta {
  id?: number;
  tipoPessoa: TipoPessoa;
  tipoOrigem: TipoOrigem;
  custoUnitario: number;
  quantidade: number;
  valorTotal?: number;
  mesPrevisto: number;
  especificacao?: string;
  entidade: string;
  contrapartida?: boolean;
  temMoedaEstrangeira?: boolean;
  moedaEstrangeiraId: number;
  moedaEstrangeira?: MoedaEstrangeira;
  propostaId: number;
  proposta?: Proposta;
}

export interface PropostaAtividade {
  id: number;
  propostaId: number;
  proposta: Proposta;
  duracao: number;
  mesInicio: number;
  cargaHorariaSemanal: number;
  titulo: string;
  membroResponsavelId: number;
  membroResponsavel: PropostaConviteMembro;
  propostaAtividadeMembro: PropostaAtividadeMembro[];
}

export interface PropostaAtividadeMembro {
  id: number;
  propostaAtividadeId: number;
  propostaAtividade: PropostaAtividade;
  membroId: number;
  membro: PropostaConviteMembro;
}

export enum TipoSituacao {
  pendente = 'PENDENTE',
  aceito = 'ACEITO',
  recusado = 'RECUSADO',
  removido = 'REMOVIDO',
  avaliado = 'AVALIADO',
}

export type TipoSituacaoI18N = {
  [key in TipoSituacao]: string;
};

export const tipoSituacaoI18N: TipoSituacaoI18N = {
  PENDENTE: 'Pendente',
  ACEITO: 'Aceito',
  RECUSADO: 'Recusado',
  REMOVIDO: 'Removido',
  AVALIADO: 'Avaliado',
};

export interface PropostaConviteMembro {
  id: number;
  propostaId: number;
  proposta: Proposta;
  usuarioPersistidoId: number;
  usuarioPersistido: UsuarioPersistido;
  situacao: TipoSituacao;
  funcaoParticipanteId?: number;
  funcaoParticipante?: FuncaoParticipante;
  createdAt: Date;
  dataAceite: Date;
}

export interface PropostaConviteConsultor {
  id: number;
  propostaId: number;
  proposta: Proposta;
  formularioConsultoriaId: number;
  formularioConsultoria: FormularioConsultoria;
  usuarioId: number;
  usuario: Usuario;
  situacao: TipoSituacao;
  createdAt: Date;
  dataAceite: Date;
}

export interface ProducaoProposta {
  id?: number;
  tipoProducao?: TipoProducao;
  producaoId: number;
  propostaId: number;
  quantidade: number;
}

export enum TipoProducao {
  nacional = 'NACIONAL',
  internacional = 'INTERNACIONAL',
}

export type TipoProducaoI18N = {
  [key in TipoProducao]: string;
};

export const tipoProducaoI18N: TipoProducaoI18N = {
  [TipoProducao.nacional]: 'Nacional',
  [TipoProducao.internacional]: 'Internacional',
};

export interface PropostaCotacaoMoedaEstrangeira {
  id?: number;
  propostaId?: number;
  proposta?: Proposta;
  moedaEstrangeiraId: number;
  moedaEstrangeira?: MoedaEstrangeira;
  valor: number;
}
