import styled from '@emotion/styled';
import Chip from '@mui/material/Chip';

export const StyledEdicaoChip = styled(Chip)({
  color: '#EF6C00',
  borderColor: '#EF6C00',
  fontSize: '10px',
  fontWeight: 500,
  fontFamily: 'Raleway',
  lineHeight: '11.74px',
  letterSpacing: '0.5px',
  width: '85px',
  height: '20px',
});

export const StyledSubmetidoChip = styled(Chip)({
  color: '#2E7D32',
  borderColor: '#2E7D32',
  fontSize: '10px',
  fontWeight: 500,
  fontFamily: 'Raleway',
  lineHeight: '11.74px',
  letterSpacing: '0.5px',
  width: '85px',
  height: '20px',
});

export const StyledDefaultChip = styled(Chip)({
  color: '#757780',
  borderColor: '#757780',
  fontSize: '10px',
  fontWeight: 500,
  fontFamily: 'Raleway',
  lineHeight: '11.74px',
  letterSpacing: '0.5px',
  width: '85px',
  height: '20px',
});
