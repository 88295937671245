import { pascalCase } from 'change-case';
import { makeApi } from '../api/generic-api';
import { CrudContextData, IFieldsConfig } from '../jsonforms/uischema';

export const createResource = async (
  ctx: CrudContextData,
  apiUrl: string,
  fieldsConfig: IFieldsConfig = null,
  customPaths?: {
    [key: string]: string;
  },
) => {
  const { setFormData, crudStates, parameterFilters, setParameterFilters, showError } = ctx;

  const rawData = ctx.formData;
  const formId = ctx.formData?.id;
  const api = makeApi<any>(apiUrl, { customPaths: customPaths });
  const urlVar = `step${pascalCase(apiUrl?.replace?.('/', ''))}Id`;

  const formData = fieldsConfig
    ? Object.fromEntries(
        Object.entries(rawData).filter(([path]) => {
          const field = path.split('.').at(0);
          return fieldsConfig.paths.some((configPath) => configPath?.startsWith(field));
        }),
      )
    : rawData;

  const isCreated = !!(
    formId &&
    parameterFilters?.[urlVar] &&
    Number(parameterFilters?.[urlVar]) === formId
  );

  if (crudStates.add && !isCreated) {
    try {
      const postResponse = await api?.post?.(formData);
      if (postResponse.status === 201) {
        const newId = postResponse?.data?.id;
        setParameterFilters({ ...parameterFilters, [urlVar]: newId });
        setFormData({ ...rawData, ...postResponse.data });
        return newId;
      }
    } catch (error: any) {
      let errorMessage = error?.cause?.response?.data?.message || error?.response?.data?.message;
      if (Array.isArray(errorMessage)) errorMessage = errorMessage?.[0];
      showError(errorMessage || 'Ocorreu um erro.');
      return false;
    }
  }

  if (crudStates.edit || formId) {
    try {
      const putResponse = await api?.put?.(formId, formData);
      if (putResponse.status === 200) {
        return true;
      } else {
        console.error(putResponse);
        return false;
      }
    } catch (error: any) {
      let errorMessage = error?.cause?.response?.data?.message || error?.response?.data?.message;
      showError(errorMessage || 'Ocorreu um erro.');
      console.error(errorMessage);
      return false;
    }
  }
  return true;
};
