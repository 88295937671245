import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IPropostaApi, makePropostaApi } from '../../api/proposta-api';
import { Crud } from '../../components/crud/crud';
import { useCrudContext } from '../../components/crud/crud-context';
import { CardChecklist, EyeFill, FileSignature, Reviews } from '../../components/styled-icons';
import { moduleNameFor } from '../../helpers/module-name-for';
import { Proposta, Proposta as PropostaModel } from '../../models/proposta';
import { useAuthContext } from '../login/auth/auth-context';
import { headCells } from './proposta.headcell';
import propostaUiSchema from './proposta.uischema';
import { MoneyDismiss } from 'styled-icons/fluentui-system-filled';

export enum TrechoPassagemProposta {
  nacional = 'nacional',
  internacional = 'internacional',
}

function PropostaView() {
  const navigate = useNavigate();
  const { formData } = useCrudContext();
  const [propostaApi, setPropostaApi] = useState<IPropostaApi | undefined>();
  const { parameterFilters, setParameterFilters } = useCrudContext();
  const { loggedInUser } = useAuthContext();

  const location = useLocation();

  const customActions = [
    (row: Proposta) => ({
      hasPermission: ['EXIBIR_NO_MENU', 'BUSCAR_TODOS', 'BUSCAR_POR_ID'],
      moduleName: moduleNameFor('proposta'),
      icon: <EyeFill size='24' />,
      title: 'Visualizar',
      handler: () => {
        setParameterFilters({
          ...parameterFilters,
          proposta: row,
        });
        navigate(`/submissao-proposta-detalhes/${row?.id}`, {
          state: {
            editalId: row?.edital?.id,
          },
        });
      },
    }),
    (row: Proposta) => ({
      hasPermission: ['EXIBIR_NO_MENU', 'CRIAR'],
      moduleName: moduleNameFor('contratacao'),
      icon: <FileSignature size='20' />,
      title: 'Contratação',
      handler: () => {
        setParameterFilters({
          ...parameterFilters,
          proposta: row,
        });
        navigate(`/proposta/${row?.id}/contratacao`);
      },
    }),
    (row: Proposta) => {
      if (row.edital?.editalRubrica?.length > 0 || row.edital?.faixaFinanciamento.length > 0) {
        return {
          hasPermission: ['LISTAR_ORCAMENTO', 'CORTE_ORCAMENTARIO'],
          moduleName: moduleNameFor('proposta'),
          icon: <MoneyDismiss size='24' color='#FF0000' />,
          title: 'Corte Orçamentário',
          handler: () => {
            setParameterFilters({
              ...parameterFilters,
              proposta: row,
            });
            navigate(`/corte-orcamentario/${row?.id}`, {
              state: {
                editalId: row?.edital?.id,
              },
            });
          },
        };
      }
      return null;
    },
    (row: Proposta) => ({
      hasPermission: ['EXIBIR_NO_MENU', 'BUSCAR_TODOS', 'BUSCAR_POR_ID'],
      moduleName: moduleNameFor('proposta'),
      icon: <CardChecklist size='24' />,
      title: 'Revisão Técnica',
      handler: () => {
        setParameterFilters({
          ...parameterFilters,
          proposta: row,
        });
        navigate(`/submissao-proposta-revisao/${row?.id}`, {
          state: {
            editalId: row?.edital?.id,
          },
        });
      },
    }),
    (row: Proposta) => ({
      hasPermission: ['EXIBIR_NO_MENU', 'BUSCAR_TODOS', 'BUSCAR_POR_ID'],
      moduleName: moduleNameFor('proposta'),
      icon: <Reviews size='24' />,
      title: 'Resultado de Consultoria',
      handler: () => {
        setParameterFilters({
          ...parameterFilters,
          proposta: row,
        });
        navigate(`/proposta/${row?.id}/resultado-consultoria`);
      },
    }),
  ];

  useEffect(() => {
    if (formData?.editalId || location?.state?.editalId)
      setPropostaApi(makePropostaApi(formData.editalId ?? location.state.editalId));
  }, [formData?.editalId, location?.state?.editalId]);

  useEffect(() => {
    if (!formData.instituicaoExecutoraId) {
      if (loggedInUser?.instituicaoId) formData.instituicaoExecutoraId = loggedInUser.instituicaoId;
    }

    if (!formData.unidadeExecutoraId) {
      if (loggedInUser?.unidadeId) formData.unidadeExecutoraId = loggedInUser.unidadeId;
    }

    if (!formData.areaDeConhecimento) {
      if (loggedInUser?.areaDeConhecimento)
        formData.areaDeConhecimento = loggedInUser.areaDeConhecimento;
    }
  }, [formData, loggedInUser]);

  return (
    <Crud<PropostaModel>
      headCells={headCells}
      titleConfig={{ value: 'Submissão de Propostas', show: true }}
      uischema={propostaUiSchema}
      queryFilters={{ withDeleted: true, orderBy: 'tituloDoProjeto', orderDirection: 'ASC' }}
      apiUrl={'/proposta'}
      apiClient={propostaApi}
      customActions={customActions}
      hideDelete
      hideCreate
      hideUpdate
      hideView
    ></Crud>
  );
}

export default PropostaView;
