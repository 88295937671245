import React, { useState, useEffect } from 'react';
import Footer from '../../components/layout/footer';
import { AvisosHome } from '../avisos/avisos-home';
import { ConvitesHome } from '../convites/convites-home';
import { EditalHome } from '../edital/lista-editais/edital-home';
import { StyledContainer, StyledItem, BoxCustom100pxHeight } from './style/home';
import { PropostaHome } from '../proposta/lista-propostas/proposta-home';
import { AvaliacoesHome } from '../avaliacao/lista-avaliacoes/avaliacoes-home';
import { makePropostaConviteConsultorApi } from '../../api/proposta-convite-consultor-api';
import { makePropostaConviteMembroApi } from '../../api/proposta-convite-membro-api';
import { Proposta, TipoSituacao } from '../../models/proposta';
import { makeApi } from '../../api/generic-api';
import { Edital } from '../../models/edital';

function Home() {
  const [refreshAvaliacoes, setRefreshAvaliacoes] = useState(0);
  const [validItems, setValidItems] = useState<JSX.Element[]>([]);
  const propostaConviteMembroApi = makePropostaConviteMembroApi();
  const propostaConviteConsultorApi = makePropostaConviteConsultorApi();
  const editalApi = makeApi<Edital>('/edital', {
    orderRelationBy: {
      orderChamadaEditalBy: (a, b) => new Date(a.termino).getTime() - new Date(b.termino).getTime(),
    },
  });
  const propostaApi = makeApi<Proposta>('/proposta', {
    orderRelationBy: {
      orderCreatedAtBy: (a, b) => b.getTime() - a.getTime(),
    },
    customPaths: { getAll: '/minhas-propostas' },
  });

  const handleRefreshAvaliacoes = (): void => {
    setRefreshAvaliacoes((prev) => prev + 1);
  };

  useEffect(() => {
    const initializeHome = async () => {
      const items: JSX.Element[] = [];

      const [consultorResult, membroResult] = await Promise.all([
        propostaConviteConsultorApi.getAllReceived({
          situacao: TipoSituacao.pendente,
          take: 2,
        }),
        propostaConviteMembroApi.getAllReceived({
          situacao: TipoSituacao.pendente,
          take: 2,
        }),
      ]);

      if (
        (consultorResult && consultorResult.data.length > 0) ||
        (membroResult && membroResult.data.length > 0)
      ) {
        items.push(
          <ConvitesHome
            key='convites'
            conviteConsultorResult={consultorResult}
            conviteMembroResult={membroResult}
            onConviteAceito={handleRefreshAvaliacoes}
          />,
        );
      }

      const editalResponse = await editalApi.getAllForPagination({
        vigentes: 'true',
        take: 6,
        orderBy: 'chamadaEdital.termino',
        orderDirection: 'ASC',
      });

      if (editalResponse && editalResponse.data.length > 0) {
        items.push(<EditalHome key='edital' apiResponse={editalResponse} />);
      }

      const propostaResponse = await propostaApi.getAllForPagination({
        take: 6,
        orderBy: 'createdAt',
        orderDirection: 'DESC',
      });

      if (propostaResponse && propostaResponse.data.length > 0) {
        items.push(<PropostaHome key='proposta' apiResponse={propostaResponse} />);
      }

      setValidItems(items);
    };

    initializeHome();
  }, []);

  return (
    <>
      <AvisosHome />
      <AvaliacoesHome refreshAvaliacoes={refreshAvaliacoes} />
      <StyledContainer>
        {validItems.map((item, index) => {
          const splitLength = validItems.length;
          const isFullWidth = index === 0 && splitLength % 2 !== 0;
          return (
            <StyledItem key={index} isFullWidth={isFullWidth}>
              {React.cloneElement(item, { splitLength })}
            </StyledItem>
          );
        })}
      </StyledContainer>
      <BoxCustom100pxHeight />
      <Footer />
    </>
  );
}

export default Home;
