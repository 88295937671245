import { useState } from 'react';
import { Crud } from '../../../components/crud/crud';
import { useNavigate, useParams } from 'react-router-dom';
import { AvaliacaoConsultor } from '../../../models/avaliacao-consultor';
import { headCells } from './resultado-consultoria.headcell';
import { GenericApi, makeApi } from '../../../api/generic-api';
import AvaliacaoConsultorUiSchema from '../../avaliacao/avaliacao-consultor/avaliacao-consultor.uischema';
import { moduleNameFor } from '../../../helpers/module-name-for';
import { EyeFill } from '../../../components/styled-icons';
import { CrudStatesOptions } from '../../../jsonforms/uischema';

function AvaliacaoConsultorView() {
  const navigate = useNavigate();
  const { propostaId } = useParams<{ propostaId: string }>();

  const [api, setApi] = useState<GenericApi<AvaliacaoConsultor>>(() =>
    makeApi<AvaliacaoConsultor>('/avaliacao-consultor'),
  );

  const customActions = [
    (row: AvaliacaoConsultor) => ({
      hasPermission: ['EXIBIR_NO_MENU', 'BUSCAR_TODOS', 'BUSCAR_POR_ID'],
      moduleName: moduleNameFor('proposta'),
      icon: <EyeFill size='24' />,
      title: 'Visualizar',
      handler: () => {
        navigate(`/proposta/${propostaId}/resultado-consultoria-view/${row?.id}`, {
          state: {
            conviteId: row?.convite.id,
            crudState: CrudStatesOptions.VIEW,
          },
        });
      },
    }),
  ];

  return (
    <Crud<AvaliacaoConsultor>
      headCells={headCells}
      titleConfig={{ value: 'Resultado Consultoria', show: true }}
      apiUrl='/resultado-consultoria'
      apiClient={api}
      queryFilters={{ propostaId }}
      uischema={AvaliacaoConsultorUiSchema}
      hideDelete
      hideUpdate
      hideView
      hideCreate
      customActions={customActions}
    />
  );
}

export const rootViews = ['proposta'];

export default AvaliacaoConsultorView;
