import { makeApi } from '../../../../../api/generic-api';
import {
  CrudContextData,
  IStepCallback,
  CrudStatesOptions,
} from '../../../../../jsonforms/uischema';
import { FormularioConsultoria } from '../../../../../models/formulario-consultoria';
import { FormularioConsultoriaCategorizado } from '../../../../../models/formulario-consultoria-categorizado';
import {
  PerguntaAvaliacao,
  TipoRespostaPerguntaAvaliacao,
} from '../../../../../models/pergunta-avaliacao';

const getErrorMessage = (error: any) => {
  let errorMessage = error?.cause?.response?.data?.message || error?.response?.data?.message;
  if (Array.isArray(errorMessage)) errorMessage = errorMessage?.[0];
  return errorMessage;
};

const handleApiResponse = async (apiCall: Promise<any>, showError: (message: string) => void) => {
  try {
    const response = await apiCall;
    if (![200, 201].includes(response.status)) return false;
  } catch (error: any) {
    showError(getErrorMessage(error) || 'Ocorreu um erro.');
    return false;
  }
  return true;
};

export const handlerFormularioConsultoria = async (
  fieldsConfig: { paths: string[]; instancePaths: string[]; requiredScopes: string[] },
  ctx: CrudContextData,
  data: FormularioConsultoria,
  url: string,
  callback?: IStepCallback,
): Promise<boolean> => {
  const { formData, crudStates, showError, updateCrudStates } = ctx;

  const hasThirdStep = formData.perguntasCategorizadas
    ? formData.formularioConsultoriaCategorizado.some((c: FormularioConsultoriaCategorizado) =>
        c.formularioConsultoriaCategorizadoPerguntaAvaliacao.some(
          (p: PerguntaAvaliacao) => p.tipoResposta === TipoRespostaPerguntaAvaliacao.nota,
        ),
      )
    : formData.formularioConsultoriaPergunta.some(
        (p: PerguntaAvaliacao) => p.tipoResposta === TipoRespostaPerguntaAvaliacao.nota,
      );

  if (hasThirdStep) {
    return true;
  }

  const api = makeApi('formulario-consultoria');
  let result = true;

  if (crudStates.add) {
    result = await handleApiResponse(api?.post?.(formData), showError);
  } else if (crudStates.edit) {
    result = await handleApiResponse(api?.put?.(formData?.id, formData), showError);
  }

  if (result) {
    updateCrudStates(CrudStatesOptions.LIST);
  }

  return result;
};
