import { useLocation, useParams } from 'react-router-dom';
import { makePropostaCorteOrcamentoApi } from '../../../api/proposta-api';
import { Crud } from '../../../components/crud/crud';
import { Proposta } from '../../../models/proposta';
import { headCells } from './corte-orcamentario.headcell';
import corteOrcamentarioUiSchema from './corte-orcamentario.uischema';

function CorteOrcamentarioProposta() {
  const location = useLocation();
  const routeParams = useParams();
  const apiClient = makePropostaCorteOrcamentoApi(
    location?.state?.editalId,
    parseInt(routeParams['corteOrcamentarioId']),
    { customPaths: { put: '/corte-orcamentario' } },
  );

  return (
    <Crud<Proposta>
      headCells={headCells}
      apiClient={apiClient}
      apiUrl={'/corte-orcamentario'}
      titleConfig={{ value: 'Corte orçamentário', show: true }}
      uischema={corteOrcamentarioUiSchema}
      customSave={{ show: false }}
      initView
    ></Crud>
  );
}

export default CorteOrcamentarioProposta;
