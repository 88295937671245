import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import { Divider, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeEditalApi } from '../../../api/edital-api';
import { FileApi } from '../../../api/file-api';
import { makePropostaApi } from '../../../api/proposta-api';
import { useCrudContext } from '../../../components/crud/crud-context';
import Editor from '../../../components/layout/ckeditor';
import CustomModal from '../../../components/layout/custom-modal';
import { truncateByCharacters } from '../../../components/layout/truncate-by-characters';
import { AttachText, DocumentText } from '../../../components/styled-icons';
import ValidityChecker from '../../../components/validity-checker/validity-checker';
import { tagsEntitiesHtmlRemoval } from '../../../helpers/tags-entities-html-removal';
import { urlFor } from '../../../helpers/url-for';
import { CrudStatesOptions } from '../../../jsonforms/uischema';
import { Edital } from '../../../models/edital';
import { Proposta } from '../../../models/proposta';
import { UsuarioApi, makeUsuarioApi } from '../../editar-perfil/usuario-api';
import { useAuthContext } from '../../login/auth/auth-context';
import {
  BoxAnexoContent,
  BoxAnexoContentItem,
  BoxAnexoName,
  BoxAnexoNameItem,
  BoxContent,
  ButtomLabel,
  ButtonContent,
  ComponentBox,
  CustomButton,
  EmptyAnexoMsg,
  Header,
  PdfContent,
  ShowAnexoLabel,
  TextoEdital,
} from './style/edital-detalhes';

const StyledCustomModal = styled(CustomModal)({
  border: 'none',
  borderRadius: '4px',
  '.modal_modal_description': {
    backgroundColor: 'red',
  },
});

function EditalDetalhes() {
  const navigate = useNavigate();
  const routeParams = useParams();
  const [edital, setEdital] = useState<Edital>(null);
  const [editalTexto, setEditalTexto] = useState<string | JSX.Element>('');
  const [usuarioApi, setUsuarioApi] = useState<UsuarioApi>(null);
  const [anexos, setAnexos] = useState([]);
  const [anexoNome, setAnexoNome] = useState('');
  const [selectedAnexo, setSelectedAnexo] = useState(null);
  const [url, setUrl] = useState('');
  const { setCurrentTitle, setFormData, updateCrudStates, showError } = useCrudContext();
  const editalId = Number(routeParams['editalDetalhesId'] ?? null) ?? null;
  const fileApi = new FileApi();
  const title = 'Detalhes do edital';
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = React.useState(false);
  const { loggedInUser } = useAuthContext();
  const [filteredData, setFilteredData] = useState<Proposta[]>([]);

  const defaultForm: Partial<Proposta> = {
    editalId,
    duracao: edital?.duracaoProjetoEmMeses,
    edital: {
      nome: edital?.nome,
      termoDeAceite: edital?.termoDeAceite,
      editalPergunta: edital?.editalPergunta,
      informacaoComplementarEditalPergunta: edital?.informacaoComplementarEditalPergunta,
      faixaFinanciamento: edital?.faixaFinanciamento,
      editalRubrica: edital?.editalRubrica,
      vinculoInstitucionalObrigatorio: edital?.vinculoInstitucionalObrigatorio,
      vinculoEmpregaticioObrigatorio: edital?.vinculoEmpregaticioObrigatorio,
      indicadorProducaoEdital: edital?.indicadorProducaoEdital,
      bolsaEdital: edital?.bolsaEdital,
      documentoPropostaEdital: edital?.documentoPropostaEdital,
      documentoPessoalEdital: edital?.documentoPessoalEdital,
    },
  };

  const editalApi = makeEditalApi();

  useEffect(() => {
    const api = makeUsuarioApi();
    setUsuarioApi(api);
  }, []);

  useEffect(() => {
    setCurrentTitle(title);
    editalApi.get(editalId).then((x: Edital) => {
      setEdital(x);
      if (x.texto) {
        const truncatedText = truncateByCharacters(tagsEntitiesHtmlRemoval(x.texto), 200, {
          linkText: ' Ver Mais...',
          callback: () => {
            handleOpen();
          },
        });
        setEditalTexto(truncatedText);
      }
    });
  }, []);

  useEffect(() => {
    if (edital) {
      fileApi.getFile('edital', 'edital-anexo', edital.id).then(({ data }) => {
        setAnexos(data);
      });
    }
  }, [edital]);

  const AnexoItem = ({ label, isSelected, onClick }) => (
    <ShowAnexoLabel colorValue={isSelected ? 'lightgray' : 'transparent'} onClick={onClick}>
      {truncateFileName(label)}
    </ShowAnexoLabel>
  );

  useEffect(() => {
    if (loggedInUser) {
      makePropostaApi(editalId)
        .buscarMinhasPropostas()
        .then((response) => {
          const data = Array.isArray(response) ? response : response.data;
          const filteredData = data.filter((item: Proposta) => item.edital.id === editalId);
          setFilteredData(filteredData);
        });
    }
  }, [loggedInUser, editalId]);

  const handleProposalCreatePermission = () => {
    if (filteredData.length >= 1 && !edital?.pesquisadorSubmeterVariasPropostas) {
      return alert('Você já possui uma proposta para este edital.');
    } else {
      goToPropostas();
    }
  };

  const getUsuario = async () => {
    let usuario = null;
    try {
      usuario = await usuarioApi.get(0);
    } catch (e) {
      showError('Erro ao buscar usuário');
    }
    return usuario;
  };

  const goToPropostas = async () => {
    const usuario = await getUsuario();
    updateCrudStates(CrudStatesOptions.ADD);
    setFormData({
      ...defaultForm,
      criadoPor: usuario,
    });
    navigate(`/proposta`);
  };

  const truncateFileName = (fileName: string, maxLenght = 41) => {
    if (fileName.length > maxLenght) {
      return `${fileName.substring(0, maxLenght)}...`;
    }
    return fileName;
  };

  const AnexoList = ({ items }) => {
    const handleAnexoClick = (index, anexo) => {
      setAnexoNome(anexo.nome);
      setSelectedAnexo(index);
      makeEditalApi()
        .getEditalFileToken()
        .then(({ fileToken }) => {
          setUrl(
            urlFor(
              `edital/${edital?.id}/edital-anexo/${anexo.id}?preview=true&api-key=${fileToken}&#zoom=100`,
            ),
          );
        });
    };

    return (
      <BoxAnexoName>
        {items.map((item, index) => (
          <AnexoItem
            key={index}
            label={item.nome}
            isSelected={selectedAnexo === index}
            onClick={() => handleAnexoClick(index, item)}
          />
        ))}
      </BoxAnexoName>
    );
  };

  return (
    <>
      <StyledCustomModal
        content={
          <Editor
            isDisabled={true}
            initialValue={edital?.texto}
            onChange={function (editorData: string): void {}}
            toolbar={[]}
            size={{ height: 450 }}
          />
        }
        openModalState={open}
        hasWindowCloseBtn={true}
        handleCloseModal={handleClose}
      />
      <ComponentBox>
        <Header>
          {edital ? edital.nome : ''}
          <ValidityChecker item={edital ?? {}} />
          <TextoEdital>{editalTexto}</TextoEdital>
        </Header>
        <Divider />
        <BoxContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <BoxAnexoNameItem>
                <AttachText size='24' color='#003D92' /> Arquivos
                <AnexoList items={anexos} />
              </BoxAnexoNameItem>
            </Grid>
            <Grid item xs={8}>
              <BoxAnexoContentItem>
                <DocumentText size='24' color='#003D92' /> Pré-Visualização: {anexoNome}
                <BoxAnexoContent>
                  {url ? (
                    <PdfContent src={url}></PdfContent>
                  ) : (
                    <EmptyAnexoMsg>Nenhum documento selecionado!</EmptyAnexoMsg>
                  )}
                </BoxAnexoContent>
              </BoxAnexoContentItem>
            </Grid>
          </Grid>
        </BoxContent>
        <ButtonContent>
          <CustomButton onClick={handleProposalCreatePermission}>
            <AddIcon></AddIcon>
            <ButtomLabel>Criar Proposta</ButtomLabel>
          </CustomButton>
        </ButtonContent>
      </ComponentBox>
    </>
  );
}

export default EditalDetalhes;
