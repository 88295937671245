import dayjs from 'dayjs';
import { TipoSituacao, tipoSituacaoI18N } from '../../../../models/proposta';

export const getStatusProps = (
  situacao: string,
  dataTermino: string,
): { label: string; status: 'danger' | 'success' } => {
  let status: 'danger' | 'success' = 'danger';
  let label = tipoSituacaoI18N[situacao] ?? '';

  const data = dayjs.utc(dataTermino).endOf('day');
  const hoje = dayjs.utc();

  if (data.isBefore(hoje)) {
    label = 'Expirado';
    status = 'danger';
  } else if (situacao === TipoSituacao.aceito || situacao === TipoSituacao.avaliado) {
    status = 'success';
  }

  return { label, status };
};
