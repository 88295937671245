import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import { ViewShow } from 'styled-icons/zondicons';
import { Download } from 'styled-icons/feather';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { makeApi } from '../../../api/generic-api';
import { useCrudContext } from '../../../components/crud/crud-context';
import { CrudStatesOptions } from '../../../jsonforms/uischema';
import { AreaDeConhecimento } from '../../../models/area-de-conhecimento';
import { Endereco } from '../../../models/endereco';
import { Usuario, VinculoInstitucional } from '../../../models/usuario';
import {
  BasicListSpanIn,
  BasicListSpanOut,
  BasicListSpanOutFlex,
  DocumentosDocsDaPropostaDefaut,
  Frame26085664,
  Frame26085668,
  Frame26085750,
  IconSpan,
  SPDDivider,
  SPDTable,
  SPDTableBodyCell,
  SPDTableBodyRow,
  SPDTableHeaderCell,
  SPDTableRow,
  SPDTitleDiv,
  SPDTitleSpan,
  SPDValueDiv,
  SPDValueSpan,
  Substepper,
} from './usuario-detalhes';

const UsuarioDetalhes = () => {
  const { usuarioDetalhesId } = useParams();
  const [usuario, setUsuario] = useState<any>(null);
  const usuarioId = Number(usuarioDetalhesId ?? null) ?? null;
  const { updateCrudStates } = useCrudContext();
  const location = useLocation();

  useEffect(() => {
    updateCrudStates(location.state?.crudState ?? CrudStatesOptions.VIEW);

    makeApi<Usuario>('/usuario')
      .get(usuarioId)
      .then((x) => {
        setUsuario(x);
      });
  }, []);

  const usuarioAnexos = usuario?.usuarioAnexo;

  const makeGroupInline = (obj) => {
    return obj.map((item: { title: string; value }) => {
      return (
        <>
          <BasicListSpanOut>
            <BasicListSpanIn>{item.title}</BasicListSpanIn>: {item.value}
          </BasicListSpanOut>
        </>
      );
    });
  };

  const makeBasicList = (obj) => {
    return obj.map((item: { type?: string; title?: string; value }) => {
      if (item.type === 'inline') {
        return (
          <>
            {item.value}
            <br />
          </>
        );
      } else if (item.type === 'multiple-line') {
        return (
          <>
            <BasicListSpanOutFlex>
              <BasicListSpanIn>{item.title}</BasicListSpanIn>: <div>{item.value}</div>
            </BasicListSpanOutFlex>
            <br />
          </>
        );
      }

      return (
        <>
          <BasicListSpanOut>
            <BasicListSpanIn>{item.title}</BasicListSpanIn>: {item.value}
          </BasicListSpanOut>
          <br />
        </>
      );
    });
  };
  const makeNestedString = (obj: string[][]) => {
    return obj?.map((item: string[], index: number) => {
      return (
        <>
          {item.join(' > ')}
          <br />
        </>
      );
    });
  };

  const makeTable = (data: string[], titles: string[]) => {
    return (
      <div>
        <SPDTable>
          <TableHead>
            <SPDTableRow>
              {titles.map((item, index) => {
                const borderRadiusTL = index === 0 ? '2px' : '0px';
                const borderRadiusTR = index === titles.length - 1 ? '2px' : '0px';
                return (
                  <>
                    <SPDTableHeaderCell
                      borderTopLeftRadius={borderRadiusTL}
                      borderTopRightRadius={borderRadiusTR}
                    >
                      {item}
                    </SPDTableHeaderCell>
                  </>
                );
              })}
            </SPDTableRow>
          </TableHead>
          <TableBody>
            {data.map((item, i) => (
              <>
                <SPDTableBodyRow>
                  {titles.map((_, j) => (
                    <>
                      <SPDTableBodyCell>{data[i][j]}</SPDTableBodyCell>
                    </>
                  ))}
                </SPDTableBodyRow>
              </>
            ))}
          </TableBody>
        </SPDTable>
      </div>
    );
  };

  const handleClickDonwload = (element) => {
    alert(`${element} download...`);
  };

  const handleClickView = (element) => {
    alert(`${element} view...`);
  };

  const buildUsuarioAnexos = (usuarioAnexos) => {
    const documentoUsuarioObj = [];

    for (let i = 0; i < usuarioAnexos?.length; i++) {
      const documento = usuarioAnexos.filter((item) => item.id);

      if (documento) {
        documentoUsuarioObj.push([
          [usuarioAnexos[i].nome],
          [dayjs(usuarioAnexos[i].createdAt).format('DD/MM/YYYY')],
          [
            <>
              <IconSpan>
                <a onClick={() => handleClickDonwload(usuarioAnexos[i].nome)}>
                  <Download size={20} />
                </a>
              </IconSpan>
              <IconSpan>
                <a onClick={() => handleClickView(usuarioAnexos[i].nome)}>
                  <ViewShow size={20} />
                </a>
              </IconSpan>
            </>,
          ],
        ]);
      }
    }
    return documentoUsuarioObj;
  };

  const buildUsuarioEnderecoNacional = (endereco: Endereco) => {
    const enderecoGroup = [
      endereco?.logradouro && { title: 'Logradouro', value: endereco.logradouro },
      endereco?.numero && { title: 'Número', value: endereco.numero },
      endereco?.complemento && { title: 'Complemento', value: endereco.complemento },
      endereco?.bairro && { title: 'Bairro', value: endereco.bairro },
      endereco?.cep && { title: 'CEP', value: endereco.cep },
      endereco?.municipio && { title: 'Município', value: endereco.municipio },
      endereco?.estado && { title: 'Estado', value: endereco.estado },
    ].filter(Boolean);

    return [
      ...(enderecoGroup.length > 0
        ? [
            {
              type: 'inline',
              value: makeGroupInline(enderecoGroup),
            },
          ]
        : []),
    ];
  };

  const buildUsuarioEnderecoInternacional = (endereco: Endereco) => {
    const enderecoGroup = [
      endereco?.logradouro && { title: 'Logradouro', value: endereco.logradouro },
      endereco?.complemento && { title: 'Complemento', value: endereco.complemento },
      endereco?.cep && { title: 'ZipCode', value: endereco.cep },
      endereco?.municipio && { title: 'Município', value: endereco.municipio },
      endereco?.estado && { title: 'Estado/Região', value: endereco.estado },
    ].filter(Boolean);

    return [
      ...(enderecoGroup.length > 0
        ? [
            {
              type: 'inline',
              value: makeGroupInline(enderecoGroup),
            },
          ]
        : []),
    ];
  };

  const buildUsuarioDadosAcademicos = (usuario: Usuario) => {
    const instituicaoUnidadeNivelAcademico = [
      usuario?.instituicaoId && {
        title: 'Instituição',
        value: usuario.instituicao.nome,
      },
      usuario?.unidadeId && {
        title: 'Unidade',
        value: usuario.unidade.nome,
      },
      usuario?.nivelAcademicoId && {
        title: 'Nível Acadêmico',
        value: usuario.nivelAcademico.tipo,
      },
    ].filter(Boolean);

    const lattesLinkedin = [
      usuario?.lattes && {
        title: 'Currículo Lattes',
        value: usuario.lattes,
      },
      usuario?.linkedin && {
        title: 'LinkedIn',
        value: usuario.linkedin,
      },
    ].filter(Boolean);

    return [
      ...(instituicaoUnidadeNivelAcademico.length > 0
        ? [
            {
              type: 'inline',
              value: makeGroupInline(instituicaoUnidadeNivelAcademico),
            },
          ]
        : []),
      ...(lattesLinkedin.length > 0
        ? [
            {
              type: 'inline',
              value: makeGroupInline(lattesLinkedin),
            },
          ]
        : []),
      {
        title: 'Áreas de Conhecimentos',
        type: 'multiple-line',
        value: makeNestedString(buildAreaDeConhecimento(usuario?.areaDeConhecimento)),
      },
    ];
  };

  const buildUsuarioDadosProfissionais = (vinculoInstitucional: VinculoInstitucional) => {
    const vinculoInicioServicoRegimeTrabalho = [
      ...(vinculoInstitucional?.tipoVinculoInstitucionalId
        ? [
            {
              title: 'Tipo de Vínculo',
              value: vinculoInstitucional.tipoVinculoInstitucional.nome,
            },
          ]
        : []),
      ...(vinculoInstitucional?.inicioServico
        ? [
            {
              title: 'Início de Serviço',
              value: dayjs(vinculoInstitucional.inicioServico).format('DD/MM/YYYY'),
            },
          ]
        : []),
      ...(vinculoInstitucional?.regimeTrabalhoId
        ? [
            {
              title: 'Regime de Trabalho',
              value: vinculoInstitucional.regimeTrabalho.nome,
            },
          ]
        : []),
    ];

    const fCInicioFC = [
      ...(vinculoInstitucional?.funcao
        ? [
            {
              title: 'Função/Cargo',
              value: vinculoInstitucional.funcao,
            },
          ]
        : []),
      ...(vinculoInstitucional?.inicioFuncao
        ? [
            {
              title: 'Início da Função/Cargo',
              value: dayjs(vinculoInstitucional.inicioFuncao).format('DD/MM/YYYY'),
            },
          ]
        : []),
    ];

    return [
      ...(vinculoInicioServicoRegimeTrabalho.length > 0
        ? [
            {
              type: 'inline',
              value: makeGroupInline(vinculoInicioServicoRegimeTrabalho),
            },
          ]
        : []),
      ...(fCInicioFC.length > 0
        ? [
            {
              type: 'inline',
              value: makeGroupInline(fCInicioFC),
            },
          ]
        : []),
    ];
  };

  const buildUsuarioDadosPessoais = (usuario: Usuario) => {
    const paisCpfEmail = [
      usuario?.paisId && {
        title: 'País de Nacionalidade',
        value: usuario.pais.nomePt,
      },
      usuario?.documento && {
        title: 'CPF',
        value: usuario.documento,
      },
      usuario?.email && {
        title: 'E-mail',
        value: usuario.email,
      },
      usuario?.dataNascimento && {
        title: 'Data de Nascimento',
        value: dayjs(usuario.dataNascimento).format('DD/MM/YYYY'),
      },
      usuario?.racaCor && {
        title: 'Raça/Cor',
        value: usuario.racaCor.nome,
      },
    ].filter(Boolean);

    return [
      {
        title: 'Nome Completo',
        value: usuario?.nome,
      },
      ...(usuario?.nomeSocial
        ? [
            {
              title: 'Nome Social',
              value: usuario.nomeSocial,
            },
          ]
        : []),
      ...(paisCpfEmail.length > 0
        ? [
            {
              type: 'inline',
              value: makeGroupInline(paisCpfEmail),
            },
          ]
        : []),
    ];
  };

  const getUsuarioDadosPessoais = () => {
    return makeBasicList(buildUsuarioDadosPessoais(usuario));
  };

  const getUsuarioEnderecoNacional = () => {
    return makeBasicList(buildUsuarioEnderecoNacional(usuario?.endereco));
  };

  const getUsuarioEnderecoInternacional = () => {
    return makeBasicList(buildUsuarioEnderecoInternacional(usuario?.endereco));
  };

  const getUsuarioDadosAcademicos = () => {
    return makeBasicList(buildUsuarioDadosAcademicos(usuario));
  };

  const getUsuarioDadosProfissionais = () => {
    return makeBasicList(buildUsuarioDadosProfissionais(usuario?.vinculoInstitucional));
  };

  const getUsuarioAnexos = () => {
    return [makeTable(buildUsuarioAnexos(usuarioAnexos), ['Nome', 'Data de Envio', 'Anexo'])];
  };

  const buildAreaDeConhecimento = (areaDeConhecimento: AreaDeConhecimento[]) => {
    const areDeConhecimentoObj = [];

    for (let i = 0; i < areaDeConhecimento?.length; i++) {
      const entry = [areaDeConhecimento[i].grandeAreaNome, areaDeConhecimento[i].areaNome];

      if (areaDeConhecimento[i].subAreaNome) {
        entry.push(areaDeConhecimento[i].subAreaNome);
      }

      if (areaDeConhecimento[i].especialidadeNome) {
        entry.push(areaDeConhecimento[i].especialidadeNome);
      }

      areDeConhecimentoObj.push(entry);
    }

    return areDeConhecimentoObj;
  };

  const usuarioDadosObj = [
    {
      title: 'DADOS DO USUÁRIO',
      value: [
        {
          title: 'Dados Pessoais',
          value: getUsuarioDadosPessoais(),
        },
        {
          title: 'Endereço',
          value: (function () {
            if (usuario?.pais?.nomePt === 'Brasil') {
              return getUsuarioEnderecoNacional();
            } else {
              return getUsuarioEnderecoInternacional();
            }
          })(),
        },
        {
          title: 'Dados Acadêmicos',
          value: getUsuarioDadosAcademicos(),
        },
        {
          title: 'Dados Profissionais',
          value: getUsuarioDadosProfissionais(),
        },
      ],
    },
    {
      title: 'Documentos Pessoais',
      value: [
        ...(usuarioAnexos?.length
          ? [
              {
                title: '',
                value: getUsuarioAnexos(),
              },
            ]
          : []),
      ],
    },
  ];

  return (
    <>
      <DocumentosDocsDaPropostaDefaut>
        <Substepper></Substepper>
        <Frame26085664>
          <Frame26085750>
            <Frame26085668>
              {usuarioDadosObj.map((x) => {
                return (
                  <>
                    <SPDTitleDiv>
                      <SPDTitleSpan>{x.title}</SPDTitleSpan>
                      <SPDDivider></SPDDivider>
                    </SPDTitleDiv>
                    {x?.value?.map((y) => {
                      if (y) {
                        return (
                          <>
                            <SPDValueDiv>
                              <SPDValueSpan>{y.title}</SPDValueSpan>
                              <div>{y.value}</div>
                            </SPDValueDiv>
                          </>
                        );
                      } else {
                        return '';
                      }
                    })}
                  </>
                );
              })}
            </Frame26085668>
          </Frame26085750>
        </Frame26085664>
      </DocumentosDocsDaPropostaDefaut>
    </>
  );
};

export default UsuarioDetalhes;
