import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeEditalApi } from '../../../../api/edital-api';
import { makeApi } from '../../../../api/generic-api';
import { Crud } from '../../../../components/crud/crud';
import { Eye, Settings } from '../../../../components/styled-icons';
import { moduleNameFor } from '../../../../helpers/module-name-for';
import { CrudStatesOptions } from '../../../../jsonforms/uischema';
import { Edital } from '../../../../models/edital';
import { FormularioConsultoria } from '../../../../models/formulario-consultoria';
import { headCells } from './formulario-consultoria-gerenciar.headcell';
import formularioConsultoriaUiSchema from './formulario-consultoria-gerenciar.uischema';

function FormularioConsultoriaView() {
  const navigate = useNavigate();
  const [edital, setEdital] = useState<Edital>(null);

  const { formularioConsultoriaEditalId: editalId } = useParams();
  const defaultForm = {
    perguntasCategorizadas: false,
    editalId: Number(editalId),
    possuiFormulaPersonalizada: false,
    edital: { documentoPropostaEdital: edital?.documentoPropostaEdital },
  };

  const api = useMemo(() => makeApi<FormularioConsultoria>('/formulario-consultoria'), [makeApi]);
  const editalApi = makeEditalApi();

  const gerenciarFormularioConsultoriaDetalhes = {
    hasPermission: ['BUSCAR_POR_ID'],
    moduleName: moduleNameFor('formulario-consultoria-gerenciar'),
    icon: <Eye size='24' />,
    title: 'Visualizar',
    handler: (row: FormularioConsultoria) => {
      navigate(`/formulario-consultoria-gerenciar-detalhes/${row?.id}`, {
        state: { crudState: CrudStatesOptions.EDIT },
      });
    },
  };

  const formularioConsultoriaProposta = {
    hasPermission: ['BUSCAR_POR_ID'],
    moduleName: moduleNameFor('formulario-consultoria-gerenciar'),
    icon: <Settings size='24' />,
    title: 'Gerenciar Avaliações',
    handler: (row: FormularioConsultoria) => {
      navigate(`${row.id}/formulario-consultoria-proposta`);
    },
  };

  useEffect(() => {
    editalApi.get(Number(editalId)).then((x: Edital) => {
      setEdital(x);
    });
  }, []);

  return (
    <Crud<FormularioConsultoria>
      headCells={headCells}
      titleConfig={{ value: 'Gerenciar Formulários', show: true }}
      queryFilters={{ withDeleted: true, editalId: Number(editalId) }}
      apiUrl={'/formulario-consultoria-gerenciar'}
      uischema={formularioConsultoriaUiSchema}
      apiClient={api}
      defaultForm={defaultForm}
      customSave={{ show: false }}
      hideView
      customActions={[
        () => gerenciarFormularioConsultoriaDetalhes,
        () => formularioConsultoriaProposta,
      ]}
    ></Crud>
  );
}

export default FormularioConsultoriaView;

export const rootViews = ['formulario-consultoria-edital'];
