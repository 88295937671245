import merge from 'lodash/merge';
import { makeErrorsManager } from '../components/crud/errors-manager';
import { customRequired } from '../helpers/custom-errors';
import { getNestedValues } from '../helpers/get-nested-values';
import { scopeToPath } from '../helpers/scope-to-path';
import { CrudContextData, IFieldsConfig, IStepCallback } from '../jsonforms/uischema';

export const stepHandlerErrors = async (
  fieldsConfig: IFieldsConfig,
  ctx: CrudContextData,
  data: any,
  url: string,
  callback?: IStepCallback,
): Promise<boolean> => {
  const { errorsJsonForms, setValidationMode, errorsCustom, setErrorsCustom } = ctx;
  const { paths, instancePaths, requiredScopes } = fieldsConfig;
  const requiredFields = requiredScopes?.map?.((scope) => scopeToPath(scope));

  const errorsManager = makeErrorsManager(ctx);
  const { addCustomErrors } = errorsManager;

  const requiredErrors = [];
  for (const requiredField of requiredFields) {
    const value = getNestedValues(data, requiredField);
    if (!value) {
      const requiredError = customRequired(requiredField);
      if (!errorsCustom.find((e) => e === requiredError)) requiredErrors.push(requiredError);
    }
  }

  const newErrorsCustom = merge(errorsCustom, requiredErrors);
  setErrorsCustom(newErrorsCustom);
  const additionalErrorsLocal = addCustomErrors();

  const customError = additionalErrorsLocal?.find?.((error: any) =>
    paths?.includes?.(error?.propertyName),
  );

  const jsonFormErrors = errorsJsonForms
    ?.map((error: any) => {
      if (requiredFields?.includes?.(error?.params?.missingProperty)) {
        return error;
      }
      if (instancePaths?.includes?.(error?.instancePath)) {
        return error;
      }
    })
    .filter((error: any) => error !== undefined);

  if (jsonFormErrors.length || !!customError) {
    console.error('Errors', jsonFormErrors, customError);
    setValidationMode('ValidateAndShow');
    return false;
  }

  if (callback) {
    const cbValue = !!(await callback?.(ctx, fieldsConfig));
    if (cbValue) setValidationMode('ValidateAndHide');
    return cbValue;
  }

  setValidationMode('ValidateAndHide');
  return true;
};
